// Define the interface for dendrogram nodes.
export interface DendrogramNode {
    /** The merge distance for this node (0 for leaves). */
    dist: number;
    /** The medoid for this cluster (a signal ID). */
    medoid: number;
    /** The list of signal IDs contained in this node. */
    leaves: number[];
    /** Child nodes (undefined or empty for leaves). */
    children?: DendrogramNode[];
    /** Optional name for leaf nodes. */
    name?: string;
    /** id */
    id: number
  }
  
  /**
   * Recursively slices the dendrogram tree at a given threshold.
   * A node is considered a final cluster if it is a leaf or if its merge distance
   * is below the threshold.
   *
   * @param node - A dendrogram node to start traversing at
   * @param threshold - The slicing threshold.
   * @returns An array of dendrogram nodes representing final clusters.
   */
  export function sliceDendrogram(node: DendrogramNode, threshold: number): DendrogramNode[] {
    const clusters: DendrogramNode[] = [];
  
    function traverse(node: DendrogramNode) {
      // If node meets the threshold, add it and skip its children.
      if (!node.children || node.children.length === 0 || node.dist <= threshold) {
        clusters.push(node);
      } else if (node.children && node.children.length > 0) {
        // Otherwise, check each child.
        node.children.forEach(child => traverse(child));
      }
    }
  
    traverse(node);
    return clusters;
  }
  
  /**
   * Returns clusters (with medoid info) from the dendrogram given a threshold.
   *
   * @param dendroTree - The full dendrogram tree.
   * @param threshold - The slicing threshold.
   * @returns An array of clusters (each with medoid, leaves, and dist properties).
   */
  export function getClustersAtThreshold(dendroTree: DendrogramNode, threshold: number): DendrogramNode[] {
    return sliceDendrogram(dendroTree, threshold);
  }
  
  /**
   * Returns the height of the dendrogram (i.e. the merge distance at the top node).
   * For a dendrogram with more than one leaf, slicing at a threshold equal or greater than this
   * height would yield a single cluster.
   *
   * @param dendroTree - The full dendrogram tree.
   * @returns The height (maximum merge distance) of the dendrogram.
   */
  export function getDendrogramHeight(dendroTree: DendrogramNode): number {
    // For a non-leaf tree, the root node's merge distance is the height.
    return dendroTree.children && dendroTree.children.length > 0 ? dendroTree.dist : 0;
  }
  
  /**
   * Parses the JSON output (as string or object) from the Python backend
   * and returns a fully typed dendrogram tree.
   *
   * @param data - The dendrogram JSON (string or object).
   * @returns The typed dendrogram tree.
   */
  export function parseDendrogramTree(data: string | object): DendrogramNode {
    let jsonData: any;
    let nodeId = 0;

    if (typeof data === "string") {
        jsonData = JSON.parse(data);
    } else {
        jsonData = data;
    }

    // Recursive parser that ensures the tree conforms to DendrogramNode.
    function parseNode(nodeData: any): DendrogramNode {
        return {
            id: nodeId++, // Assign a unique ID
            dist: nodeData.dist,
            medoid: nodeData.medoid,
            leaves: nodeData.leaves,
            children: nodeData.children ? nodeData.children.map(parseNode) : undefined,
            name: nodeData.name,
        };
    }

    return parseNode(jsonData);
}

  // map of cluster id to the cluter's number of descendants
  export type DendrogramDescendantCounts = Map<number, number>;

  export function computeDescendantCounts(root: DendrogramNode): DendrogramDescendantCounts { 
    const nodeToCount = new Map<number, number>();

     /** 
      * Helper function to recursively traverse the tree. 
      * @param node - Current dendrogram node. 
      * @returns the number of descendant nodes under the current node. 
      */
    function traverse(node: DendrogramNode): number { 
        if (!node.children || node.children.length === 0) { 
            nodeToCount.set(node.id, 0);
            return 0; 
        } 
        let totalDescendants = 0; 
        for (const child of node.children) { // Each child itself counts as one plus its own descendants.
            const childDescendants = traverse(child);
            totalDescendants += 1 + childDescendants;
        } 
        nodeToCount.set(node.id, totalDescendants); 
        return totalDescendants; 
    } 
    
    traverse(root);
    return nodeToCount;
 }

/** returns a list of all clusters in the tree */
 export function getAllClusters(root: DendrogramNode): DendrogramNode[] {
  const clusters: DendrogramNode[] = [];
  
  function traverse(node: DendrogramNode) {
      clusters.push(node);
      if (node.children) {
          for (const child of node.children) {
              traverse(child);
          }
      }
  }
  
  traverse(root);
  return clusters;
}
  
  /*
  // ------------------
  // Example Usage
  // ------------------
  
  // Suppose this JSON is returned from the Python backend.
  const dendroJson = // e.g., fetched via an API call { 
    "dist": 120.5,
    "medoid": 55,
    "leaves": [55, 156, 99, 175],
    "children": [
      {
        "dist": 80.0,
        "medoid": 156,
        "leaves": [55, 156],
        "children": [
          {
            "dist": 0,
            "medoid": 55,
            "leaves": [55],
            "name": "55"
          },
          {
            "dist": 0,
            "medoid": 156,
            "leaves": [156],
            "name": "156"
          }
        ]
      },
      {
        "dist": 90.0,
        "medoid": 99,
        "leaves": [99, 175],
        "children": [
          {
            "dist": 0,
            "medoid": 99,
            "leaves": [99],
            "name": "99"
          },
          {
            "dist": 0,
            "medoid": 175,
            "leaves": [175],
            "name": "175"
          }
        ]
      }
    ]
  };
  
  // Parse the JSON into a typed dendrogram tree.
  const dendroTree = parseDendrogramTree(dendroJson);
  
  // Example: Get clusters at a threshold of 50.
  const threshold = 50;
  const clustersAtThreshold = getClustersAtThreshold(dendroTree, threshold);
  console.log("Clusters at threshold", threshold, clustersAtThreshold);
  
  // Example: Get the overall height of the dendrogram.
  const height = getDendrogramHeight(dendroTree);
  console.log("Dendrogram height:", height);
  */