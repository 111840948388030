import React, { ChangeEvent, useContext, useState, useEffect } from "react";
import { AppContext } from "./AppContext";
import { throwIfNull } from "./utils";

const AdvancedMenu: React.FC = () => {
  const { getCurrentPenalties, setCurrentPenalties } = throwIfNull(useContext(AppContext));
  const values = getCurrentPenalties();

  // Local state to hold text input values
  const [localValues, setLocalValues] = useState<string[]>(values.map(v => v.toString()));

  // Update local state when context changes
  useEffect(() => {
    setLocalValues(values.map(v => v.toString()));
  }, [values]);

  const labels = [
    "Translation",
    "Scale",
    "Velocity",
    "Time",
    "Skipped Segments",
    "Segment Count",
    "Stretch"
  ];

  const handleChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const textValue = e.target.value;
    // Always update the local state so user can see what they're typing.
    setLocalValues(prev => {
      const updated = [...prev];
      updated[index] = textValue;
      return updated;
    });
    // If the text parses correctly, update the context.
    const parsed = parseFloat(textValue);
    if (!isNaN(parsed)) {
      const updatedValues = [...values];
      updatedValues[index] = parsed;
      setCurrentPenalties(updatedValues);
    }
  };

  return (
    <div
      style={{
        marginTop: "20px",
        padding: "0 20px", // adds breathing room on left/right sides
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        maxWidth: "300px"
      }}
    >
      {localValues.map((value, index) => (
        <div key={index} style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <label style={{ fontWeight: 600, fontSize: "0.9rem", color: "#333" }}>
            {labels[index]}
          </label>
          <input
            type="text"
            value={value}
            onChange={handleChange(index)}
            style={{
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              fontSize: "1rem"
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default AdvancedMenu;
