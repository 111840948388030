import React, { createContext, useState, ReactNode, useEffect, useRef } from 'react';
import { ShapeFilterDefinition } from './ShapeFilterDefinition';
import { ShapeSearchReturnStruct } from './utils';
import {
  DataTable,
} from "@tableau/embedding-api";
import { cloneBlob, cloneLibrary, cloneMapObject, cloneObjectArray, cloneShapeFilterDefinition, cloneShapeType } from './CopyUtils';
import mapboxgl from 'mapbox-gl';
import { BabyNamesQueryResults, SignalIdToNameMap, StormTracksQueryResults } from './ApiServices/DBApiService';
import { DendrogramDescendantCounts, DendrogramNode } from './DendrogramUtils';
import { calculateOverlayCoordinates, renderGraticules } from './CustomMap';


export const activeCanvasId = "active-canvas-id";

export type MeasureType = { measureName: Map<Mode, string>, color: string };

export enum Mode {
  Storm_Paths = "Storm Paths",
  Baby_Trends = "Baby Trends"
}

export type TextBox = {
  x: number,
  y: number,
  text: string,
  isActive: boolean;
}

export type MapShapeType = {
  coordinates: [[number, number], [number, number], [number, number], [number, number]] | undefined,
  center: number[],
  zoom: number
}

export type ShapeType = {
  annotationShapes: Map<string, ShapeFilterDefinition>,
  measureShapes: Map<string, ShapeFilterDefinition>,
  textBoxes: TextBox[],
  map: {
    isMapMode: boolean
    mapInfo: MapShapeType,
    mapBlob?: Blob,
  },
  sketchBlob?: Blob,
  annotationParsingFilteredNames: string[]
};

interface AppContextProps {

  isLoadingBabyNameResults: boolean;
  setIsLoadingBabyNameResults: (value: boolean) => void;

  isLoadingStormResults: boolean;
  setIsLoadingStormResults: (value: boolean) => void;

  forecastingEnabled: boolean;
  setForecastingEnabled: (value: boolean) => void;

  searchByPercentage: boolean;
  setSearchByPercentage: (value: boolean) => void;

  epsilons: number[];
  epsilonIndex: number;
  setEpsilonIndex: (value: number) => void;

  valueRange: { min: number, max: number };
  dateRange: { startDate: Date, currentDate: Date, endDate: Date };

  canvasWidth: number;
  canvasHeight: number;
  setCanvasWidth: (value: number) => void;
  setCanvasHeight: (value: number) => void;

  resultBlob: Blob | undefined;
  setResultBlob: (blob: Blob) => void;

  tableauVizDataTable: DataTable | undefined;
  setTableauVizDataTable: (dataTable: DataTable | undefined) => void;

  angleError: number;
  setAngleError: (value: number) => void;
  lineError: number;
  setLineError: (value: number) => void;

  shapeSearchResults: Map<string, ShapeSearchReturnStruct[]>;
  setShapeSearchResults: (value: Map<string, ShapeSearchReturnStruct[]>) => void;

  shapeFilterLibrary: ShapeType[];
  addShapeFilterToLibrary: (newSketch?: ShapeType) => number; // returns index where it was stored
  removeShapeFilterFromLibrary: (shapeFilterIndex: number) => void;
  updateShapeInLibrary:(
    index: number,
    measure?: { 
      measureName: string,
      shape: ShapeFilterDefinition
    }, 
    annotation?: {
      color: string,
      shape: ShapeFilterDefinition
    }, 
    textBoxes?: TextBox[]
  ) => void;
  updateLibraryForClearButton:(index: number) => void;
  updateTextBoxesInLibrary:(textBoxes:TextBox[], index: number) => void;
  deactivateAllTextBoxesInLibrary:() => void;
  updateMapInLibrary:(updatedMap: MapShapeType, index:number) => void;
  updateSketchInLibrary:(updatedSketch:ShapeType, index: number) => void;
  updateSketchBlobInLibrary:(sketchBlob: Blob, index: number) => void;
  setAnnotationParsingFilteredNamesInLibrary:(value: string[], index: number) => void;

  currentShapeIndex: number | undefined;
  setCurrentShapeIndex: (value: number | undefined) => void;

  listOfMeasures: MeasureType[];
  annotationColors: Map<string, string>;
  defaultMeasure: MeasureType;
  currentMeasure: string;
  currentColor: string;
  setCurrentColor: (value: string) => void;
  updateCurrentMeasure: (value: string) => void;
  currentPrecision: number;
  setCurrentPrecision: (value: number) => void;

  eraserEnabled: boolean;
  setEraserEnabled: (value: boolean) => void;
  isEditingExistingSketch: boolean;
  editingSketch: ShapeType;
  enableEditing: () => void;
  disableEditing: () => void;

  isAddingText: boolean;
  setIsAddingText: (value: boolean) => void;

  isMapMode: boolean;
  setIsMapModeForIndex: (value: number) => void;
  setIsMapModeValue: (value: boolean) => void;

  // map displayed to the user that contains the sketch image and all storm layers
  mapRef: React.MutableRefObject<mapboxgl.Map | null>;

  // hidden map used to go generate images for the sketch library that only contains the sketch image
  mapOnlySketchRef: React.MutableRefObject<mapboxgl.Map | null>;

  mapContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  mapOnlySketchContainerRef: React.MutableRefObject<HTMLDivElement | null>;

  mapLoaded: boolean;
  setMapLoaded: (value: boolean) => void;

  displayedCanvasRef: React.MutableRefObject<HTMLCanvasElement | null>;

  allBabyNameQueryResults: BabyNamesQueryResults | undefined;
  setAllBabyNameQueryResults: (value: BabyNamesQueryResults | undefined) => void;
  babyNameQueryResults: BabyNamesQueryResults | undefined;
  setBabyNameQueryResults: (value: BabyNamesQueryResults | undefined) => void;

  stormQueryResults: StormTracksQueryResults | undefined;
  setStormQueryResults: (value: StormTracksQueryResults | undefined) => void;

  selectedStormRef: React.MutableRefObject<string | undefined>;
  setSelectedStorm: (value: string) => void;

  displaySketchOnViz: boolean;
  setDisplaySketchOnViz: (value: boolean) => void;

  displayAllLinesOnViz: boolean;
  setDisplayAllLinesOnViz: (value: boolean) => void;

  ///////// storm tracks dendrogram ////////////

  dendrogram: DendrogramNode | undefined;
  setDendrogram: (value: DendrogramNode) => void;
  dendrogramDescendantCounts: DendrogramDescendantCounts | undefined;
  setDendrogramDescendantCounts: (value: DendrogramDescendantCounts) => void;

  // sorted, unique of all the cluster node height/levels
  dendrogramLevelCountsList: number[];
  setDendrogramLevelCountsList: (value: number[]) => void;

  // index of dendrogramLevelCountsList that the cluster height/level filter is set to
  dendrogramLevelIndex: number;
  setDendrogramLevelIndex: (value: number) => void;

  allDendrogramClusters: DendrogramNode[];
  setAllDendrogramClusters: (value: DendrogramNode[]) => void;

  ///////// baby names dendrogram ////////////

  babyNamesDendrogram: DendrogramNode | undefined;
  setBabyNamesDendrogram: (value: DendrogramNode) => void;
  babyNamesDendrogramDescendantCounts: DendrogramDescendantCounts | undefined;
  setBabyNamesDendrogramDescendantCounts: (value: DendrogramDescendantCounts) => void;

  // sorted, unique of all the cluster node height/levels
  babyNamesDendrogramLevelCountsList: number[];
  setBabyNamesDendrogramLevelCountsList: (value: number[]) => void;

  // index of babyNamesDendrogramLevelCountsList that the cluster height/level filter is set to
  babyNamesDendrogramLevelIndex: number;
  setBabyNamesDendrogramLevelIndex: (value: number) => void;

  allBabyNamesDendrogramClusters: DendrogramNode[];
  setAllBabyNamesDendrogramClusters: (value: DendrogramNode[]) => void;

  allStormTracks: StormTracksQueryResults | undefined;
  setAllStormTracks: (value: StormTracksQueryResults) => void;

  signalIdToNameMap: SignalIdToNameMap | undefined;
  setSignalIdToNameMap: (value: SignalIdToNameMap) => void;

  babyNamesSignalIdToNameMap: SignalIdToNameMap | undefined;
  setBabyNamesSignalIdToNameMap: (value: SignalIdToNameMap) => void;

  // sorted, unique of all the errors in the query results
  resultsFilterErrorList: number[];
  setResultsFilterErrorList: (value: number[]) => void;

  // index of resultsFilterErrorList that the results filter max error is set to
  resultsFilterErrorIndex: number;
  setResultsFilterErrorIndex: (value: number) => void;
  modeKey: Mode; 

  getCurrentPenalties : () => number[];
  setCurrentPenalties: (values: number[]) => void;
}

export const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const kEpsilonValues: number[] = [0.01, .015, .02, 0.03, 0.05, 0.075, 0.1, 0.15]; //Array(20).fill(0).map((_, i) => Math.pow(i*0.05,4));
  const kDefaultEpsilonIndex: number = Math.floor((kEpsilonValues.length) / 2); //indexing into kEpsilonValues

  const valueRange = { min: 0, max: 1 }; //{dataTable.GetValueRange(dataMeasure)}

  const [canvasWidth, setCanvasWidth] = useState<number>(1100);
  const [canvasHeight, setCanvasHeight] = useState<number>(625);

  // Magic Numbers, Required for forecast only
  const dateRange = {
    startDate: new Date(2024, 1, 1), //new Date(Math.min(...yearsFromData).toString()),
    currentDate: new Date(2024, 4, 20), //new Date(Math.max(...yearsFromData).toString()),
    endDate: new Date(2024, 5, 2)
  }; //{dataTable.GetDateRange()};

  const [isMapMode, setIsMapMode] = React.useState(false);
  const [forecastingEnabled, setForecastingEnabled] = useState(false);
  const [searchByPercentage, setSearchByPercentage] = useState(true);
  const [epsilonIndex, setEpsilonIndex] = useState(kDefaultEpsilonIndex);
  const [angleError, setAngleError] = useState(10);
  const [lineError, setLineError] = useState(0.3);
  const [shapeFilterLibrary, setShapeFilterLibrary] = useState<ShapeType[]>([]);
  const [shapeSearchResults, setShapeSearchResults] = useState<Map<string, ShapeSearchReturnStruct[]>>(new Map<string, ShapeSearchReturnStruct[]>());
  const [currentShapeIndex, setCurrentShapeIndex] = useState<number | undefined>(undefined);
  const [eraserEnabled, setEraserEnabled] = useState<boolean>(false);
  const [isEditingExistingSketch, setIsEditingExistingSketch] = useState<boolean>(false);
  const [editingSketch, setEditingSketch] = useState<ShapeType>(
    {
      measureShapes: new Map(),
      annotationShapes: new Map(),
      textBoxes: [],
      map: {
        isMapMode: isMapMode,
        mapInfo: {
          coordinates: undefined,
          center: [],
          zoom: 10
        }
      },
      sketchBlob: undefined,
      annotationParsingFilteredNames: []
  });
  const [resultBlob, setResultBlob] = useState<Blob|undefined>();
  const [tableauVizDataTable, setTableauVizDataTable] = useState<DataTable|undefined>();
  const [isAddingText, setIsAddingText] = React.useState(false);
  const [allBabyNameQueryResults, setAllBabyNameQueryResults] = useState<BabyNamesQueryResults | undefined>(undefined);

  const [babyNameQueryResults, setBabyNameQueryResults] = useState<BabyNamesQueryResults | undefined>(undefined);
  const [stormQueryResults, setStormQueryResults] = useState<StormTracksQueryResults | undefined>(undefined);
  const [selectedStorm, setSelectedStorm] = useState<string>();
  const [displaySketchOnViz, setDisplaySketchOnViz] = useState<boolean>(false);
  const [displayAllLinesOnViz, setDisplayAllLinesOnViz] = useState<boolean>(false);

  const [mapLoaded, setMapLoaded] = useState<boolean>(false);

  //////  storm tracks dendrograms ////////
  const [allStormTracks, setAllStormTracks] = useState<StormTracksQueryResults | undefined>(undefined);

  const [dendrogram, setDendrogram] = useState<DendrogramNode | undefined>(undefined);
  const [dendrogramLevelIndex, setDendrogramLevelIndex] = useState<number>(0);

  const [dendrogramDescendantCounts, setDendrogramDescendantCounts] = useState<DendrogramDescendantCounts | undefined>(undefined);
  const [dendrogramLevelCountsList, setDendrogramLevelCountsList] = useState<number[]>([]);

  const [allDendrogramClusters, setAllDendrogramClusters] = useState<DendrogramNode[]>([]);

  const [signalIdToNameMap, setSignalIdToNameMap] = useState<SignalIdToNameMap | undefined>(undefined);

  //////  baby names dendrograms ////////

  const [babyNamesDendrogram, setBabyNamesDendrogram] = useState<DendrogramNode | undefined>(undefined);
  const [babyNamesDendrogramLevelIndex, setBabyNamesDendrogramLevelIndex] = useState<number>(0);

  const [babyNamesDendrogramDescendantCounts, setBabyNamesDendrogramDescendantCounts] = useState<DendrogramDescendantCounts | undefined>(undefined);
  const [babyNamesDendrogramLevelCountsList, setBabyNamesDendrogramLevelCountsList] = useState<number[]>([]);

  const [allBabyNamesDendrogramClusters, setAllBabyNamesDendrogramClusters] = useState<DendrogramNode[]>([]);

  const [babyNamesSignalIdToNameMap, setBabyNamesSignalIdToNameMap] = useState<SignalIdToNameMap | undefined>(undefined);

  const [resultsFilterErrorList, setResultsFilterErrorList] = useState<number[]>([]);
  const [resultsFilterErrorIndex, setResultsFilterErrorIndex] = useState<number>(0);

  const [isLoadingBabyNameResults, setIsLoadingBabyNameResults] = useState(false);
  const [isLoadingStormResults, setIsLoadingStormResults] = useState(false);

  const listOfMeasures: MeasureType[] = [
    {
      measureName: new Map([
        [Mode.Baby_Trends, "Baby Trends"],
        [Mode.Storm_Paths, "Storm Paths"]
      ]),
      color: "118, 0, 192"
    }
  ];

  const annotationColors = new Map([
    ['black', '0,0,0']
  ]);

  const defaultMeasure = listOfMeasures[0];

  const modeKey = isMapMode ? Mode.Storm_Paths : Mode.Baby_Trends;

  const [currentMeasure, setCurrentMeasure] = useState<string>(
    defaultMeasure.measureName.get(modeKey) ?? Mode.Baby_Trends
  );
  const [currentColor, setCurrentColor] = useState<string>(defaultMeasure.color);
  const [currentPrecision, setCurrentPrecision] = useState<number>(70);

  const [mapPenaltyValues, setMapPenaltyValues] = useState<number[]>([50, 40, 50, 0, 0, 5, 50]);
  const [linePenaltyValues, setLinePenaltyValues] = useState<number[]>([1, 1, 20, 5, 0.25, 0.25, 0]);

  const mapRef = useRef<mapboxgl.Map | null>(null);
  const mapOnlySketchRef = useRef<mapboxgl.Map | null>(null);
  const displayedCanvasRef = useRef<HTMLCanvasElement>(null);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapOnlySketchContainerRef = useRef<HTMLDivElement>(null);
  const selectedStormRef = useRef<string | undefined>(selectedStorm);

  useEffect(() => {
    selectedStormRef.current = selectedStorm;
  }, [selectedStorm]);

  useEffect(() => {
    if (isMapMode && mapContainerRef.current && mapOnlySketchContainerRef.current && process.env.MAPBOX_ACCESS_TOKEN) {
      console.log('setting mapRef and mapOnlySketchRef');
      // TO MAKE THE MAP APPEAR YOU MUST
      // ADD YOUR ACCESS TOKEN FROM
      // https://account.mapbox.com
      mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN;

      console.log('initializing map');
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current as HTMLElement,
        style: "mapbox://styles/mapbox/light-v11",
        antialias: true,
        projection: "mercator",
        center: [140, 15],
        zoom: 2,
        bearing: 0,
        preserveDrawingBuffer: true
      });
      mapRef.current.addControl(new mapboxgl.NavigationControl());

      mapRef.current.on('style.load', () => {
        console.log('map loaded');
        setMapLoaded(true)
      });

      mapOnlySketchRef.current = new mapboxgl.Map({
        container: mapOnlySketchContainerRef.current as HTMLElement,
        style: "mapbox://styles/mapbox/light-v11",
        antialias: true,
        projection: "mercator",
        center: [140, 15],
        zoom: 2,
        bearing: 0,
        preserveDrawingBuffer: true,
        interactive: false
      });
    } /*else if (mapRef.current) {
      console.log('appcontext readding container and resizing');
      mapRef.current._container = mapContainerRef.current;
      mapRef.current.resize();
      mapRef.current.triggerRepaint();
    }*/
  }, [isMapMode]);

  useEffect(() => {
    if (shapeFilterLibrary.length > 0) {
      let shape = shapeFilterLibrary[0].measureShapes.get(currentMeasure);
      if (shape) {
        shape.angleError = angleError;
        shapeFilterLibrary[0].measureShapes.set(currentMeasure, shape);
      }
    }
  }, [angleError]);

  useEffect(() => {
    if (shapeFilterLibrary.length > 0 && shapeFilterLibrary[0].measureShapes.has(currentMeasure)) {
      let shape = shapeFilterLibrary[0].measureShapes.get(currentMeasure);
      if (shape) {
        shape.lineError = lineError;
        shapeFilterLibrary[0].measureShapes.set(currentMeasure, shape);
      }
    }
  }, [lineError]);

  useEffect(() => {
    updateColorFromMeasure(currentMeasure);
  }, [currentMeasure]);

  const addShapeFilterToLibrary = (newSketch?: ShapeType): number => {
    console.log('addshapefiltertolibrary');
    if (newSketch) {
      setShapeFilterLibrary(
        [cloneShapeType(newSketch), ...shapeFilterLibrary]);
    } else {
      
      const newMeasure = defaultMeasure.measureName.get(modeKey) ?? Mode.Baby_Trends;
      updateCurrentMeasure(newMeasure);

      if (isMapMode && mapRef.current && mapOnlySketchRef.current) {
        renderGraticules(mapOnlySketchRef.current, selectedStormRef.current).then(() => {
            setTimeout(() => {
              if (!mapOnlySketchRef.current) return;
              mapOnlySketchRef.current.getCanvas().toBlob(blob => {
                if (!mapRef.current) return;
                const mapCenter = mapRef.current.getCenter();
                let newShape: ShapeType = { 
                  annotationShapes: new Map(),
                  measureShapes: new Map(),
                  textBoxes: [],
                  map: {
                    isMapMode: true,
                    mapInfo: {
                      coordinates: calculateOverlayCoordinates(mapRef.current),
                      center: [mapCenter.lng, mapCenter.lat],
                      zoom: mapRef.current.getZoom()
                    },
                  },
                  annotationParsingFilteredNames: []
                };
                if (blob) {
                  newShape.map.mapBlob = blob;
                }
                setShapeFilterLibrary(
                  cloneLibrary([newShape, ...shapeFilterLibrary]));
              });
            }, 1000);
        });
      } else {
        let newShape: ShapeType = {
          annotationShapes: new Map(),
          measureShapes: new Map(),
          textBoxes: [],
          map: {
            isMapMode: false,
            mapInfo: {
              coordinates: undefined,
              center: [],
              zoom: 0
            }
          },
          annotationParsingFilteredNames: []
        };
        setShapeFilterLibrary(
          cloneLibrary([newShape, ...shapeFilterLibrary]));
      }
    }
    return 0;
  }

  const updateCurrentMeasure = (measureName: string): void => {
    setCurrentMeasure(measureName);
    updateColorFromMeasure(measureName);
  }

  const updateColorFromMeasure = (measureName: string): void => {
    const newColor = listOfMeasures.find(
      measure => measure.measureName.get(modeKey) === measureName
    )?.color;

    if (newColor) {
      setCurrentColor(newColor);
    }
  }

  const removeShapeFilterFromLibrary = (shapeFilterIndexToRemove: number): void => {
    if (shapeFilterLibrary.length === 0 || currentShapeIndex === undefined) return;
    setShapeFilterLibrary(shapeFilterLibrary.filter((_, i) => i !== shapeFilterIndexToRemove));
  }

  const updateShapeInLibrary = (
    index: number,
    measure?: { 
      measureName: string,
      shape: ShapeFilterDefinition
    }, 
    annotation?: {
      color: string,
      shape: ShapeFilterDefinition
    }, 
    textBoxes?: TextBox[]
  ): void => {
    if (shapeFilterLibrary.length === 0 || currentShapeIndex === undefined) return;
    if (isEditingExistingSketch) {
      if (editingSketch) {
        const newSketch = cloneShapeType(editingSketch);
        if (measure) {
          newSketch.measureShapes.set(measure.measureName, cloneShapeFilterDefinition(measure.shape));
        }
        if (annotation) {
          newSketch.annotationShapes.set(annotation.color, cloneShapeFilterDefinition(annotation.shape));
        }
        if (textBoxes) {
          newSketch.textBoxes = cloneObjectArray(textBoxes);
        }
        setEditingSketch(newSketch);
      }
    } else {
      const newShapeArray = cloneLibrary(shapeFilterLibrary);
      if (measure) {
        newShapeArray[index].measureShapes.set(measure.measureName, cloneShapeFilterDefinition(measure.shape));
      }
      if (annotation) {
        newShapeArray[index].annotationShapes.set(annotation.color, cloneShapeFilterDefinition(annotation.shape));
      }
      if (textBoxes) {
        newShapeArray[index].textBoxes = cloneObjectArray(textBoxes);
      }
      setShapeFilterLibrary(newShapeArray);
    }
  }

  const updateLibraryForClearButton = (index: number) => {
    if (shapeFilterLibrary.length === 0 || currentShapeIndex === undefined) return; 
    if (isEditingExistingSketch) {
      setEditingSketch(
        cloneShapeType({
          measureShapes: new Map(),
          annotationShapes: new Map(),
          textBoxes: [],
          map: {
            isMapMode: editingSketch.map.isMapMode,
            mapInfo: cloneMapObject(editingSketch.map.mapInfo),
            mapBlob: cloneBlob(editingSketch.map.mapBlob)
          },
          annotationParsingFilteredNames: []
        })
      )
    } else {
      const newShapeArray = cloneLibrary(shapeFilterLibrary);
      const currentSketch = newShapeArray[currentShapeIndex];
      newShapeArray[index] = cloneShapeType({
        annotationShapes: new Map(),
        measureShapes: new Map(),
        textBoxes: [],
        map: {
          isMapMode: currentSketch.map.isMapMode,
          mapInfo: cloneMapObject(currentSketch.map.mapInfo),
          mapBlob: cloneBlob(currentSketch.map.mapBlob)
        },
        annotationParsingFilteredNames: []
      });
      setShapeFilterLibrary(newShapeArray);
    }
  }

  const updateTextBoxesInLibrary = (textBoxes: TextBox[], index: number) => {
    if (shapeFilterLibrary.length === 0 || currentShapeIndex === undefined) return;
    if (isEditingExistingSketch) {
      if (editingSketch) {
        const newSketch = cloneShapeType(editingSketch);
        newSketch.textBoxes = cloneObjectArray(textBoxes);
        setEditingSketch(newSketch);
      }
    } else {
      const newShapeArray = cloneLibrary(shapeFilterLibrary);
      newShapeArray[index].textBoxes = cloneObjectArray(textBoxes);
      setShapeFilterLibrary(newShapeArray);
    }
  }

  // deactivate any text boxes that are marked as active so typing doesn't update the text
  const deactivateAllTextBoxesInLibrary = () => {
    if (shapeFilterLibrary.length === 0 || currentShapeIndex === undefined) return;
    if (isEditingExistingSketch) {
      if (editingSketch) {
        const newSketch = cloneShapeType(editingSketch);
        newSketch.textBoxes = getDisabledTextBoxes(editingSketch.textBoxes);
        setEditingSketch(newSketch);
      }
    } else {
      const newShapeArray = cloneLibrary(shapeFilterLibrary);
      newShapeArray[currentShapeIndex].textBoxes = getDisabledTextBoxes(newShapeArray[currentShapeIndex].textBoxes);
      setShapeFilterLibrary(newShapeArray);
    }
  }

  const updateMapInLibrary = (updatedMap: MapShapeType, index: number) => {
    if (shapeFilterLibrary.length === 0 || currentShapeIndex === undefined) return;
    if (isEditingExistingSketch) {
      if (editingSketch) {
        const newSketch = cloneShapeType(editingSketch);
        newSketch.map.mapInfo = cloneMapObject(updatedMap);
        setEditingSketch(newSketch);
      }
    } else {
      const newShapeArray = cloneLibrary(shapeFilterLibrary);
      newShapeArray[index].map.mapInfo = cloneMapObject(updatedMap);
      setShapeFilterLibrary(newShapeArray);
    }
  }

  const updateSketchBlobInLibrary = (blob: Blob, index: number) => {
    if (shapeFilterLibrary.length === 0 || currentShapeIndex === undefined) return;
    const newShapeArray = cloneLibrary(shapeFilterLibrary);
    newShapeArray[index].sketchBlob = cloneBlob(blob);
    console.log(`updatesketchblobinlibrary ${index}`);
    console.log(newShapeArray);
    setShapeFilterLibrary(newShapeArray);
  }

  const setAnnotationParsingFilteredNamesInLibrary = (filteredNames: string[], index: number) => {
    if (shapeFilterLibrary.length === 0 || currentShapeIndex === undefined) return;
    const newShapeArray = cloneLibrary(shapeFilterLibrary);
    newShapeArray[index].annotationParsingFilteredNames = cloneObjectArray(filteredNames);
    setShapeFilterLibrary(newShapeArray);
  }

  const updateSketchInLibrary = (updatedShape:ShapeType, index: number) => {
    const newShapeArray = cloneLibrary(shapeFilterLibrary);
    newShapeArray[index] = cloneShapeType(updatedShape);
    setShapeFilterLibrary(newShapeArray);
  }
  

  const getDisabledTextBoxes = (textBoxes: TextBox[]): TextBox[] => {
    let newTextBoxes = cloneObjectArray(textBoxes);
    newTextBoxes.forEach((textBox: TextBox) => textBox.isActive = false);
    return newTextBoxes;
  }

  const setIsMapModeForIndex = (index: number) => {
    // update map mode value if needed
    const newMapMode = shapeFilterLibrary[index].map.isMapMode;
    if ((isMapMode && !newMapMode) || (!isMapMode && newMapMode)) {
      console.log(`mapMode was ${isMapMode}, setting to ${newMapMode}`);
      setIsMapModeValue(newMapMode, index);
    } else {
      // reset values
      setCurrentShapeIndex(index);
      setShapeFilterLibrary(cloneLibrary(shapeFilterLibrary));
      updateCurrentMeasure(defaultMeasure.measureName.get(modeKey) ?? Mode.Baby_Trends);

      if (isMapMode) {
        setStormQueryResults(undefined);
        //setSelectedStorm('');
        //setMapLoaded(false);
        setIsLoadingStormResults(true);
      } else {
        setBabyNameQueryResults(undefined);
        setIsLoadingBabyNameResults(true);
      }
    }
  }

  const setIsMapModeValue = (value: boolean, index?: number) => {
    console.log(`setIsMapModeValue ${value}`);
    setIsMapMode(value);

    // reset everything to requery data and rerender
    setCurrentShapeIndex(index);
    setShapeFilterLibrary(cloneLibrary(shapeFilterLibrary));
    updateCurrentMeasure(defaultMeasure.measureName.get(modeKey) ?? Mode.Baby_Trends);
    if (value) {
      setStormQueryResults(undefined);
      setAllStormTracks(undefined);
      setDendrogram(undefined);
      setSignalIdToNameMap(undefined);
      setDendrogramDescendantCounts(undefined);
      setDendrogramLevelCountsList([]);
      setDendrogramLevelIndex(0);
      //setSelectedStorm('');
      //setMapLoaded(false);
    } else {
      setBabyNameQueryResults(undefined);
      setAllBabyNameQueryResults(undefined);
      setBabyNamesDendrogram(undefined);
      setBabyNamesSignalIdToNameMap(undefined);
      setBabyNamesDendrogramDescendantCounts(undefined);
      setBabyNamesDendrogramLevelCountsList([]);
      setBabyNamesDendrogramLevelIndex(0);
    }
  }

  const enableEditing = () => {
    if (shapeFilterLibrary.length === 0 || currentShapeIndex === undefined) return;
    setIsEditingExistingSketch(true);
    const currentShape = shapeFilterLibrary[currentShapeIndex];
    setEditingSketch(cloneShapeType(currentShape));
  }

  const disableEditing = () => {
    setIsEditingExistingSketch(false);
    setEditingSketch(
      {
        measureShapes: new Map(),
        annotationShapes: new Map(),
        textBoxes: [],
        map: {
          isMapMode: isMapMode,
          mapInfo: {
            coordinates: undefined,
            center: [],
            zoom: 0
          }
        },
        annotationParsingFilteredNames: []
      }
    );
  }

  const getCurrentPenalties = () : number[] => {
    if(isMapMode)
    {
      return mapPenaltyValues;
    }
    else
    {
      return linePenaltyValues;
    }
  }

  const setCurrentPenalties = (values: number[]) => {
    if(isMapMode)
    {
      setMapPenaltyValues(values);
    }
    else
    {
      setLinePenaltyValues(values);
    }
  }

  return (
    <AppContext.Provider
      value={{
        forecastingEnabled,
        setForecastingEnabled,
        searchByPercentage,
        setSearchByPercentage,

        epsilons: kEpsilonValues,
        epsilonIndex,
        setEpsilonIndex,

        valueRange,
        dateRange,

        canvasWidth,
        canvasHeight,
        setCanvasWidth,
        setCanvasHeight,

        resultBlob,
        setResultBlob,

        tableauVizDataTable,
        setTableauVizDataTable,

        angleError,
        setAngleError,

        lineError,
        setLineError,

        shapeSearchResults,
        setShapeSearchResults,

        shapeFilterLibrary,
        addShapeFilterToLibrary,
        removeShapeFilterFromLibrary,
        updateShapeInLibrary,
        updateLibraryForClearButton,
        updateTextBoxesInLibrary,
        deactivateAllTextBoxesInLibrary,
        updateMapInLibrary,
        updateSketchBlobInLibrary,
        updateSketchInLibrary,
        setAnnotationParsingFilteredNamesInLibrary,

        currentShapeIndex,
        setCurrentShapeIndex,

        listOfMeasures,
        currentMeasure,
        defaultMeasure,
        updateCurrentMeasure,

        annotationColors,
        currentColor,
        setCurrentColor,

        currentPrecision,
        setCurrentPrecision,

        eraserEnabled,
        setEraserEnabled,

        isEditingExistingSketch,
        editingSketch,
        enableEditing,
        disableEditing,

        isAddingText,
        setIsAddingText,

        isMapMode,
        setIsMapModeForIndex,
        setIsMapModeValue,

        mapRef,
        mapContainerRef,
        mapOnlySketchRef,
        mapOnlySketchContainerRef,

        mapLoaded,
        setMapLoaded,

        displayedCanvasRef,

        allBabyNameQueryResults,
        setAllBabyNameQueryResults,

        babyNameQueryResults,
        setBabyNameQueryResults,

        stormQueryResults,
        setStormQueryResults,

        selectedStormRef,
        setSelectedStorm,

        displaySketchOnViz,
        setDisplaySketchOnViz,

        displayAllLinesOnViz,
        setDisplayAllLinesOnViz,

        dendrogram,
        setDendrogram,
        dendrogramLevelIndex,
        setDendrogramLevelIndex,
        dendrogramDescendantCounts,
        setDendrogramDescendantCounts,
        dendrogramLevelCountsList,
        setDendrogramLevelCountsList,
        allDendrogramClusters,
        setAllDendrogramClusters,


        babyNamesDendrogram,
        setBabyNamesDendrogram,
        babyNamesDendrogramLevelIndex,
        setBabyNamesDendrogramLevelIndex,
        babyNamesDendrogramDescendantCounts,
        setBabyNamesDendrogramDescendantCounts,
        babyNamesDendrogramLevelCountsList,
        setBabyNamesDendrogramLevelCountsList,
        allBabyNamesDendrogramClusters,
        setAllBabyNamesDendrogramClusters,

        allStormTracks,
        setAllStormTracks,

        signalIdToNameMap,
        setSignalIdToNameMap,

        babyNamesSignalIdToNameMap,
        setBabyNamesSignalIdToNameMap,

        resultsFilterErrorList,
        setResultsFilterErrorList,

        resultsFilterErrorIndex,
        setResultsFilterErrorIndex,
        modeKey,

        getCurrentPenalties,
        setCurrentPenalties,

        isLoadingBabyNameResults,
        setIsLoadingBabyNameResults,

        isLoadingStormResults,
        setIsLoadingStormResults

      }}
    >
      {children}
    </AppContext.Provider>
  );
};
