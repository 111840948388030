import React, { useState } from "react";
import LineChart from "./LineChart";

type LineChartWrapperProps = {
    showOverlay: boolean,
    overlayChildren: JSX.Element
};

const sketchBlobLineChartId = 'sketch-blob-line-chart';
const activeLineChartId = 'active-line-chart';

export function fetchSketchBlobFromLineChart(): Promise<{image: HTMLImageElement, height: number, width: number}> {
    return new Promise((resolve, reject) => {
        const svgElement = document.getElementById(sketchBlobLineChartId);
        if (!svgElement) {
            console.error(`${sketchBlobLineChartId} element doesn't exist, unable to generate sketch blob`);
            reject();
            return;
        }
        // Serialize the SVG
        let serializer = new XMLSerializer();
        let svgString = serializer.serializeToString(svgElement);

        // Ensure XML namespace is present
        if (!svgString.includes("xmlns=")) {
            svgString = svgString.replace("<svg", '<svg xmlns="http://www.w3.org/2000/svg"');
        }

        // Convert SVG string to Data URL
        let svgDataUrl = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svgString);

        // Create an Image element
        let img = new Image();
        img.crossOrigin = "anonymous"; // Ensure no CORS issues
        img.src = svgDataUrl;

        // should never be 0
        img.width = parseFloat(svgElement.getAttribute("width") ?? '0');
        img.height = parseFloat(svgElement.getAttribute("height") ?? '0');

        img.onload = function () {
            const width = img.clientWidth > 0 ? img.clientWidth : img.width;
            const height = img.clientHeight > 0 ? img.clientHeight : img.height;
            resolve({image: img, height: height, width: width});
        };
        img.onerror = function (error) {
            console.error("Error loading line chart as image for sketch blob:", error);
            reject();
        };
    })
}

const LineChartWrapper: React.FC<LineChartWrapperProps> = ({showOverlay, overlayChildren}) => {

    // force dupe line chat to be the same height as the actual line chart
    const [height, setHeight] = useState<number>();

    return(
        <>
            {/* main line chart shown to user */}
            <LineChart id={activeLineChartId} showOverlay={showOverlay} overlayChildren={overlayChildren} drawLines={true} updateHeight={setHeight} />
            
            {/* used for generating the sketchblob, not visibile to the user */}
            <div style={{position: 'absolute', left: '-4000px'}}>
                <LineChart id={sketchBlobLineChartId} showOverlay={false} overlayChildren={<></>} drawLines={false} lineChartHeight={height} />
            </div>
        </>
    )
};

export default LineChartWrapper