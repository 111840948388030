import React, { useContext, useEffect, useRef } from "react";
import { DialogFooter } from "./CustomDialog";
import ShapeFilterEditorWrapper from "./ShapeFilterEditorWrapper";
import { activeCanvasId, AppContext, MeasureType } from "./AppContext";
import { SketchRef } from "./Sketch";
import { base64ToUint8Array, getBearing, getUniqueSortedArray, HSVtoRGB, sortMapByNumericalKeyDescending, throwIfNull } from "./utils";

import CustomMap, {
  defaultHighlightedLineColor,
  getLineLayerFromStormName,
  getStormNameFromLayer,
  getLabelLayerFromStormName,
  isStormLayer,
  isStormLabelLayer,
  mapSketchCanvasLayerId,
  baseStormLineColor,
  queriedStormLineColor,
  getClusterLineLayerFromClusterId,
  isStormClusterLayer,
  getClusterIdFromClusterLayer,
  orderLayers,
  getArrowLayerFromStormName,
  arrowImgName,
  arrowImgData,
  getPaintPropertyName,
  isStormArrowLayer,
  isStormLineLayer,
  getClusterArrowLayerFromClusterId,
  noResultsColor,
  overlayColor,
} from "./CustomMap";
import { getAllStormTracks, queryDendrogram, StormTracksQueryResult } from "./ApiServices/DBApiService";
import "./Layout.css";

import "./Layout.css";
import "./InputSliders.css";
import { computeDescendantCounts, getAllClusters, getClustersAtThreshold, parseDendrogramTree } from "./DendrogramUtils";

const defaultClusterWidth = 6;
const maxClusterWidth = 100;
const defaultClusterSaturation = 0.15;
const maxClusterSaturation = 0.32;
const defaultClusterValue = 0.94;
const maxClusterValue = 0.99;
const defaultArrowSize = 0.075;
const defaultDendrogramLevelIndex = 233;

type MapWrapperProps = {
  showOverlay: boolean;
  sketchRef: React.RefObject<SketchRef>;
  handleClickMeasure: (measure: MeasureType) => void;
  handleClickAnnotationColor: (color: string) => void;
  handleClickTextButton: () => void;
  handleClickEraserButton: () => void;
  handleClearButton: () => void;
  handleCancelButton: () => void;
  handleSaveButton: () => void;
  handleSaveAsButton: () => void;
};

const MapWrapper: React.FC<MapWrapperProps> = ({
  showOverlay,
  sketchRef,
  handleClickMeasure,
  handleClickAnnotationColor,
  handleClickTextButton,
  handleClickEraserButton,
  handleClearButton,
  handleCancelButton,
  handleSaveButton,
  handleSaveAsButton,
}) => {
  const [hoveredOverStorm, setHoveredOverStorm] = React.useState<string | undefined>();

  const {
    currentShapeIndex,
    canvasWidth,
    canvasHeight,

    isEditingExistingSketch,
    isAddingText,
    isMapMode,
    mapRef,
    mapContainerRef,

    mapLoaded,

    stormQueryResults,
    setResultBlob,
    selectedStormRef,
    //setSelectedStorm,

    displaySketchOnViz,
    displayAllLinesOnViz,

    dendrogram,
    setDendrogram,
    dendrogramLevelIndex,
    setDendrogramLevelIndex,

    dendrogramDescendantCounts,
    setDendrogramDescendantCounts,

    dendrogramLevelCountsList,
    setDendrogramLevelCountsList,

    allDendrogramClusters,
    setAllDendrogramClusters,

    signalIdToNameMap,
    setSignalIdToNameMap,
    setAllStormTracks,
    allStormTracks,

    resultsFilterErrorList,
    resultsFilterErrorIndex,

    shapeFilterLibrary,

    isLoadingStormResults
  } = throwIfNull(useContext(AppContext));

  const stormQueryResultsRef = useRef(stormQueryResults);

  useEffect(() => {
    stormQueryResultsRef.current = stormQueryResults;
  }, [stormQueryResults]);

  // query dendrogram information
  useEffect(() => {
    if (!isMapMode || !mapLoaded || !mapRef.current) return;
    if (!allStormTracks) {
      getAllStormTracks().then((response) => {
        setSignalIdToNameMap(response.signalIdToNameMap);
        setAllStormTracks(response.stormTracksQueryResults);
      });
    }
    if (!dendrogram) {
      queryDendrogram("storm_tracks_global_mapnorm").then((response: any) => {
        const dendroTree = parseDendrogramTree(response);
        console.log(dendroTree);
        setDendrogram(dendroTree);
        // imperically derived contents
        const allClusters = getAllClusters(dendroTree);
        setAllDendrogramClusters(allClusters);
        const descendantCounts = computeDescendantCounts(dendroTree);
        setDendrogramDescendantCounts(descendantCounts);

        // get list of all dendrogram levels except the very top one
        let dendrogramLevelCounts = getUniqueSortedArray(allClusters.map(cluster => cluster.dist));
        dendrogramLevelCounts.splice(-1);
        setDendrogramLevelCountsList(dendrogramLevelCounts);
        setDendrogramLevelIndex(defaultDendrogramLevelIndex);
      });
    }
  }, [isMapMode, mapLoaded, mapRef.current, signalIdToNameMap, signalIdToNameMap, allStormTracks, dendrogram,  dendrogramDescendantCounts]);

  const isMapModeRef = useRef(isMapMode);
  useEffect(() => {
    isMapModeRef.current = isMapMode;
  }, [isMapMode]);

  /* draw storm lines and cluster lines on the map with intiial styling when map is first opened */
  useEffect(() => {
    if (!isMapMode || !mapLoaded || !mapRef.current || !mapLoaded || !mapRef.current.isStyleLoaded() || !mapContainerRef.current || !dendrogram || !dendrogramDescendantCounts || !allDendrogramClusters || !signalIdToNameMap || !allStormTracks ) return;
  
    // if sources and layers are already added, return;
    if (mapRef.current.getStyle()?.layers.some(layer => isStormClusterLayer(layer.id))) return;
  
    // load arrow head
    if (!mapLoaded || !mapRef.current.hasImage(arrowImgName)) {
      console.log('converting arrow head png and adding to mapbox');
      base64ToUint8Array(arrowImgData, 240, 111, image => {
        if (!mapLoaded || !mapRef.current) return;
        console.log(`adding ${arrowImgName}`);
        mapRef.current.addImage(arrowImgName, {
          width: 240,  // Adjust to image size
          height: 111,
          data: image,
        }, 
        { sdf: true});
      });
    }

    console.log('drawing cluster layers');

    let maxDescendants = 0;
    dendrogramDescendantCounts.forEach((value, key) => maxDescendants = Math.max(maxDescendants, value));

    /** returns a color for a cluster line based on its number of descendants */
    const getClusterLineProperties = (count: number  | undefined): {color: string, width: number, arrowSize: number} => {
      if (count === undefined) {
        return {
          color: baseStormLineColor,
          width: defaultClusterWidth,
          arrowSize: defaultArrowSize
        }
      }
      let saturation = defaultClusterSaturation;
      let value = defaultClusterValue;
      let width = defaultClusterWidth;
      let arrowSize = defaultArrowSize;
      if (count === maxDescendants) {
        // leave arrowSize as default - don't display the arrow for the max size cluster
        saturation = defaultClusterSaturation - 0.05;
        width = maxClusterWidth * 1.15;
        value = maxClusterValue + 0.02;
      } else {
        const maxCountThreshold = 270; // don't use max or it washes everything out
        saturation = maxClusterSaturation - (count / maxCountThreshold) * (maxClusterSaturation - defaultClusterSaturation);
        value = defaultClusterValue + (count / maxCountThreshold) * (maxClusterValue - defaultClusterValue);
        width = ((count / (maxCountThreshold - 1)) * (maxClusterWidth - defaultClusterWidth) + defaultClusterWidth);
        arrowSize = width / defaultClusterWidth * defaultArrowSize * 0.9;
      }
      // baseStormLineColor = hsv(203°, 0.29, 94%)
      return {
        color: HSVtoRGB(203 / 360, saturation, value),
        width: width,
        arrowSize: arrowSize
      }
    }  

    // get a list of descendant counts to clusterIds, sorted in descending order
    let countsToClusterIds: Map<number, number[]> = new Map();
    dendrogramDescendantCounts.forEach((value /* count */, key /* clusterId */) => {
      if (countsToClusterIds.has(value)) {
        countsToClusterIds.set(value, [...countsToClusterIds.get(value) ?? [], key]);
      } else {
        countsToClusterIds.set(value, [key]);
      }
    });
    countsToClusterIds = sortMapByNumericalKeyDescending(countsToClusterIds);

    // draw all cluster layers
    countsToClusterIds.forEach((value, key) => {
      if (!mapLoaded || !mapRef.current) return;
      value.forEach(clusterId => {
        if (!mapLoaded || !mapRef.current) return;
        const cluster = allDendrogramClusters.find(cluster => cluster.id === clusterId);
        if (!cluster) return;
        const stormName = signalIdToNameMap.get(cluster.medoid);
        const newClusterLayerId = getClusterLineLayerFromClusterId(clusterId);
        const descendantCount = dendrogramDescendantCounts.get(clusterId);

        const lineProperties = getClusterLineProperties(descendantCount);
    
        if (stormName && newClusterLayerId && descendantCount !== undefined) {
          const coordinates = throwIfNull(allStormTracks.get(stormName)?.map(storm => [storm.longitude, storm.latitude]));
          mapRef.current.addSource(newClusterLayerId, {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: coordinates,
              },
            },
          });
          mapRef.current.addLayer({
            id: newClusterLayerId,
            type: "line",
            source: newClusterLayerId,
            layout: {
              "line-join": "round",
              "line-cap": "round",
              visibility: 'none',
            },
            paint: {
              "line-color": lineProperties.color,
              "line-width": lineProperties.width
            },
          });

          // Extract arrowheads for the cluster
          const start = coordinates[coordinates.length - 2];
          const end = coordinates[coordinates.length - 1];

          addArrowLayer(mapRef.current, getClusterArrowLayerFromClusterId(clusterId), start, end, lineProperties.color, lineProperties.arrowSize);
        }
      });
    });

    // order cluster layers, ones with a lower descendant count (with wider, lighter lines)
    // should be underneath those with more descendant counts (with thinner, darker lines)
    setTimeout(() => {
      countsToClusterIds.forEach((value, key) => {
        value.forEach(clusterId => {
          if (!mapLoaded || !mapRef.current) return;
          mapRef.current.moveLayer(getClusterLineLayerFromClusterId(clusterId));
          mapRef.current.moveLayer(getClusterArrowLayerFromClusterId(clusterId));
        });
      });
    }, 500);

    // drawing storm lines on top of cluster layers

    // draw all storm lines
    allStormTracks.forEach((stormResult: StormTracksQueryResult[], stormName: string) => {
      if (!mapLoaded || !mapRef.current) return;

      const newStormLayerId = getLineLayerFromStormName(stormName);
      if (mapRef.current.getLayer(newStormLayerId)) {
        return;
      } else {
        const newCoordinates = stormResult.map((value: StormTracksQueryResult) => [value.longitude, value.latitude]);
        const newLabelLayerId = getLabelLayerFromStormName(stormName);

        mapRef.current.addSource(newStormLayerId, {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: newCoordinates,
            },
          },
        });

        // should initially use default storm line color
        mapRef.current.addLayer({
          id: newStormLayerId,
          type: "line",
          source: newStormLayerId,
          layout: {
            "line-join": "bevel",
            "line-cap": "butt",
            visibility: "none",
          },
          paint: {
            "line-color": noResultsColor,
            "line-width": defaultClusterWidth
          },
        });

        // should initially hide all labels
        mapRef.current.addLayer({
          id: newLabelLayerId,
          type: "symbol",
          source: newStormLayerId,
          layout: {
            "symbol-placement": "point",
            "text-font": ["Open Sans Bold"],
            "text-field": stormName.toUpperCase(), // make all storm label text uppercase
            "text-size": 18,
            "text-offset": [1.5, 1],
            "visibility": "none",
          },
          paint: {
            "text-halo-color": "#fff",
            'text-color': defaultHighlightedLineColor,
            "text-halo-width": 2,
          },
        });

        // Extract arrowheads for the line
        const start = newCoordinates[newCoordinates.length - 2];
        const end = newCoordinates[newCoordinates.length - 1];

        addArrowLayer(mapRef.current, getArrowLayerFromStormName(stormName), start, end, defaultHighlightedLineColor);

        //mapRef.current.on("click", newStormLayerId, () => handleStormClick(newStormLayerId));
        //mapRef.current.on("click", newLabelLayerId, () => handleStormClick(newLabelLayerId));

        mapRef.current.on("mouseenter", newStormLayerId, () => handleMouseEnter(newStormLayerId));
        mapRef.current.on("mouseenter", newLabelLayerId, () => handleMouseEnter(newLabelLayerId));

        mapRef.current.on("mouseleave", newStormLayerId, () => handleMouseLeave(newStormLayerId));
        mapRef.current.on("mouseleave", newLabelLayerId, () => handleMouseLeave(newLabelLayerId));
      }
    });

    setTimeout(() => {
      if (!mapLoaded || !mapRef.current) return;
      mapRef.current.getCanvas().toBlob((blob) => {
        if (blob) {
          setResultBlob(blob);
        }
      });
    }, 2000);
  }, [isMapMode, mapLoaded, mapContainerRef.current, allStormTracks, dendrogram, dendrogramDescendantCounts, signalIdToNameMap]);  

  // handle visibility of sketch
  useEffect(() => {
    if (!isMapMode || !mapLoaded || !mapRef.current) return;
    try {
      if (mapRef.current.getLayer(mapSketchCanvasLayerId)) {
        if (displaySketchOnViz) {
          mapRef.current.setLayoutProperty(mapSketchCanvasLayerId, "visibility", "visible");
        } else {
          mapRef.current.setLayoutProperty(mapSketchCanvasLayerId, "visibility", "none");
        }
      }
    } catch (e) {
      // do nothing
      return;
    }
  }, [mapLoaded, displaySketchOnViz]);

  // handle visibility of storm lines
  useEffect(() => {
    try {
      if (!isMapMode || !mapLoaded || !mapRef.current || !mapRef.current.getStyle() || !dendrogram || !dendrogramDescendantCounts || !signalIdToNameMap || !allStormTracks) return;
      if (stormQueryResultsRef.current && stormQueryResultsRef.current.size > 0 && resultsFilterErrorIndex >= resultsFilterErrorList.length) return;

      const visibleLines: string[] = [];

      const annotationFilterNames = (!showOverlay && currentShapeIndex !== undefined && 
          shapeFilterLibrary[currentShapeIndex].annotationParsingFilteredNames.length > 0) ?
          shapeFilterLibrary[currentShapeIndex].annotationParsingFilteredNames : undefined;

      // TODO: remove console
      console.log('annotationFilterNames');
      console.log(annotationFilterNames);
      
      mapRef.current.getStyle()?.layers.forEach((layer) => {
        if (!mapLoaded || !mapRef.current) return;

        // don't modify styles for non-storm line and arrow layers.
        if (!isStormLineLayer(layer.id) && !isStormArrowLayer(layer.id)) return;

        const stormName = getStormNameFromLayer(layer.id);

        // should show layers if there are either no storm query results, or the layer is in the results and under the results filter error
        let shouldShowLayer = !stormQueryResultsRef.current || stormQueryResultsRef.current.size === 0 || 
        (stormQueryResultsRef.current?.has(stormName) && stormIsUnderResultsError(stormName, resultsFilterErrorList[resultsFilterErrorIndex]));

        // if there is a list of names filtered from the Anthropic annotation parsing
        // and the storm name is not in the filrtered list, do not show
        if (annotationFilterNames && !annotationFilterNames.includes(stormName)) {
            shouldShowLayer = false;
        }
        
        if (shouldShowLayer) {
          mapRef.current.setLayoutProperty(layer.id, "visibility", "visible");
          visibleLines.push(stormName);
        } else if (mapRef.current?.getLayoutProperty(layer.id, "visibility") === "visible") {
          mapRef.current.setLayoutProperty(layer.id, "visibility", "none");
        }
      });
      //console.log('made these storms visible');
      //console.log(visibleLines);
    } catch (e) {
      // do nothing
    }
  }, [
    isMapMode, 
    mapLoaded,
    mapRef.current,
    isEditingExistingSketch,
    stormQueryResultsRef.current,
    dendrogram,
    dendrogramDescendantCounts,
    allStormTracks,
    signalIdToNameMap,
    displayAllLinesOnViz,
    resultsFilterErrorList,
    resultsFilterErrorIndex,
    showOverlay,
    shapeFilterLibrary,
    currentShapeIndex
  ]);

  // handle selected storm layers styling
  useEffect(() => {
    if (!isMapMode || !mapLoaded || !mapRef.current || !selectedStormRef.current || selectedStormRef.current.length === 0) return;
    // ensure the selected layer is at the top and is emphasized
    const selectedStormLayerId = getLineLayerFromStormName(selectedStormRef.current);
    const selectedStormLabelLayerId = getLabelLayerFromStormName(selectedStormRef.current);

    emphasizeLayer(selectedStormLayerId);
    emphasizeLayer(selectedStormLabelLayerId);

    mapRef.current.moveLayer(selectedStormLayerId);
    mapRef.current.moveLayer(selectedStormLabelLayerId);
  }, [mapLoaded, selectedStormRef.current]);

  const emphasizeLayer = (layerId: string) => {
    if (!mapLoaded || !mapRef.current || !isStormLayer(layerId)) return;
    mapRef.current.setPaintProperty(layerId, getPaintPropertyName(layerId), defaultHighlightedLineColor);
    // make sure it is visible
    mapRef.current.setLayoutProperty(layerId, "visibility", "visible");
  };

  const stormIsUnderResultsError = (stormName: string, filteredError: number) => {
    const stormLayerError = parseFloat(stormQueryResultsRef.current?.get(stormName)?.at(0)?.error ?? "0");
    return !stormQueryResultsRef.current || (stormQueryResultsRef.current && stormLayerError <= filteredError);
  };

  useEffect(() => {
    if (!showOverlay) return;
    if (!isMapMode || !mapLoaded || !mapRef.current) return;

    mapRef.current.getStyle()?.layers.forEach((layer) => {
      if (!mapLoaded || !mapRef.current || !isStormLayer(layer.id)) return;
      mapRef.current.setPaintProperty(layer.id, getPaintPropertyName(layer.id), overlayColor);
    });
  }, [showOverlay])

  // handle color of storm layers
  useEffect(() => {
    if (showOverlay) return;
    if (!isMapMode || !mapLoaded || !mapRef.current) return;
    if (stormQueryResultsRef.current && resultsFilterErrorIndex >= resultsFilterErrorList.length) return;

    if (!mapLoaded || !mapRef.current.isStyleLoaded()) {
      setTimeout(() => {
        // wait until style is loaded
      }, 400);
    }

    // if still not loaded, return
    if (!mapLoaded || !mapRef.current.isStyleLoaded()) return;
  
    mapRef.current.getStyle()?.layers.forEach((layer) => {
      if (!mapLoaded || !mapRef.current || !isStormLayer(layer.id)) return;

      const selectedLayerExists = selectedStormRef.current && selectedStormRef.current.length > 0;

      const stormName = getStormNameFromLayer(layer.id);
      const isHoveredLayer = hoveredOverStorm && hoveredOverStorm === stormName;
      const isSelectedLayer = selectedLayerExists && selectedStormRef.current === stormName;

      let defaultColor = defaultHighlightedLineColor
      if (shapeFilterLibrary.filter(item => item.map.isMapMode).length === 0 || 
          (stormQueryResultsRef.current?.size === 0 && currentShapeIndex && shapeFilterLibrary[currentShapeIndex].annotationParsingFilteredNames.length === 0)) {
          // all storm lines should be the same color when the overlay is open
          // or when there are no items in the library or when there are no results
          defaultColor = noResultsColor;
      }

      let newColor = defaultColor;
      const stormIsInResults = stormQueryResultsRef.current?.has(stormName);
      const stormIsInFilteredResults = stormIsInResults && stormIsUnderResultsError(stormName, resultsFilterErrorList[resultsFilterErrorIndex]);

      if (isLoadingStormResults) {
        // if it is loading, continue to use the default color
        newColor = defaultColor
      } else if (isHoveredLayer || isSelectedLayer) {
        // hovered and selected layers should be highlighted
        newColor = defaultHighlightedLineColor;
      } else if ((selectedLayerExists || hoveredOverStorm) && stormIsInFilteredResults) {
        // if there is a selected or hovered storm line and storm is in the results and under the results error, use the queried storm color
        newColor = queriedStormLineColor;
      } else if (selectedLayerExists || hoveredOverStorm) {
        // if there is a selected or hovered storm line, all other lines should be base color
        newColor =  baseStormLineColor;
      } else {
        // if there is no selected or hovered storm line, use the default color
        newColor = defaultColor;
      }
      mapRef.current.setPaintProperty(layer.id, getPaintPropertyName(layer.id), newColor);
    });
  }, [isMapMode, mapLoaded, isLoadingStormResults, currentShapeIndex, resultsFilterErrorList, resultsFilterErrorIndex, stormQueryResultsRef.current, hoveredOverStorm, allStormTracks, showOverlay]);

  // handle ordering of hovered and selected layers
  useEffect(() => {
    if (!isMapMode || !mapLoaded || !mapRef.current) return;

    console.log('ordering hovered and selected layers');

    if (selectedStormRef.current && selectedStormRef.current.length > 0) {
      const selectedStormLineLayerName = getLineLayerFromStormName(selectedStormRef.current);
      const selectedStormLabelLayerName = getLabelLayerFromStormName(selectedStormRef.current);
      const selectedStormArrowLayerName = getArrowLayerFromStormName(selectedStormRef.current);
      mapRef.current.moveLayer(selectedStormLineLayerName);
      mapRef.current.moveLayer(selectedStormLabelLayerName);
      mapRef.current.moveLayer(selectedStormArrowLayerName);
    }

    if (hoveredOverStorm && hoveredOverStorm.length > 0) {
      const hoveredOverStormLineLayerName = getLineLayerFromStormName(hoveredOverStorm);
      const hoveredOverStormLabelLayerName = getLabelLayerFromStormName(hoveredOverStorm);
      const hoveredOverStormArrowLayerName = getArrowLayerFromStormName(hoveredOverStorm);
      mapRef.current.moveLayer(hoveredOverStormLineLayerName);
      mapRef.current.moveLayer(hoveredOverStormLabelLayerName);
      mapRef.current.moveLayer(hoveredOverStormArrowLayerName);
    }

    if (displaySketchOnViz) {
      mapRef.current.moveLayer(mapSketchCanvasLayerId);
    }
  }, [mapLoaded, hoveredOverStorm, selectedStormRef.current, displaySketchOnViz]);

  // handle ordering of layers
  useEffect(() => {
    if (!isMapMode || !mapLoaded || !mapRef.current) return;

    orderLayers(mapRef.current, hoveredOverStorm);
  }, [isMapMode, mapLoaded, stormQueryResultsRef.current]);

  /* set visibility on up to 5 storm labels */
  const setStormLabelVisibility = () => {
    if (!mapLoaded || !mapRef.current) return;
    if (!stormQueryResultsRef.current || stormQueryResultsRef.current.size === 0) return;

    let currentLabeledStorms: string[] = [];
    let allVisibleQueriedStorms: string[] = [];
    mapRef.current.getStyle()?.layers.forEach((layer) => {
      if (!isStormLayer(layer.id)) return;
      if (
        isStormLineLayer(layer.id) &&
        mapRef.current?.getLayoutProperty(layer.id, "visibility") === "visible" &&
        mapRef.current?.getPaintProperty(layer.id, "line-color") === defaultHighlightedLineColor
      ) {
        allVisibleQueriedStorms.push(getStormNameFromLayer(layer.id));
      }

      if (isStormLabelLayer(layer.id) && mapRef.current?.getLayoutProperty(layer.id, 'visibility') === 'visible') {
        currentLabeledStorms.push(getStormNameFromLayer(layer.id));
      }
    });

    if (allVisibleQueriedStorms.length === 0) return;

    console.log(currentLabeledStorms);
    console.log(allVisibleQueriedStorms);

    // hide labels for which lines are not visible
    let newLabeledStorms: Set<string> = new Set();
    currentLabeledStorms.forEach(stormName => {
      if (allVisibleQueriedStorms.includes(stormName)) {
        newLabeledStorms.add(stormName);
      } else {
        mapRef.current?.setLayoutProperty(getLabelLayerFromStormName(stormName), 'visibility', 'none');
      }
    });

    // generate random set of labels from the visible storms to show
    if (allVisibleQueriedStorms?.length <= 4) {
      newLabeledStorms = new Set([...allVisibleQueriedStorms]);
    } else {
      while (newLabeledStorms.size < 4) {
        const randomStormIndex = Math.floor(Math.random() * allVisibleQueriedStorms.length - 1);
        newLabeledStorms.add(allVisibleQueriedStorms[randomStormIndex]);
      }
    }

    // add selected storm name to list of visible labels
    if (selectedStormRef.current) {
      newLabeledStorms.add(selectedStormRef.current);
    }

    // set visibility of labels
    mapRef.current.getStyle()?.layers.forEach(layer => {
      if (!isStormLabelLayer(layer.id)) return;
      const stormName = getStormNameFromLayer(layer.id);
      if (newLabeledStorms.has(stormName)) {
        if (mapRef.current?.getLayoutProperty(layer.id, 'visibility') === 'none') {
          mapRef.current?.setLayoutProperty(layer.id, "visibility", "visible");
        }
        mapRef.current?.moveLayer(layer.id);
      } else {
        mapRef.current?.setLayoutProperty(layer.id, "visibility", "none");
      }
    });
  };

  // handle storm label visibility
  useEffect(() => {
    if (!isMapMode ||!mapLoaded || !mapRef.current) return;
    const hoveredOverLabelLayerId = getLabelLayerFromStormName(hoveredOverStorm);
    const selectedStormLabelLayerId = getLabelLayerFromStormName(selectedStormRef.current);

    if ((hoveredOverStorm && hoveredOverStorm.length > 0) || (selectedStormRef.current && selectedStormRef.current.length > 0)) {
      // if there is a hovered over or selected storm, only display the labels for those storms
      mapRef.current.getStyle()?.layers.forEach(layer => {
        if (!mapLoaded || !mapRef.current) return;
        if (!isStormLabelLayer(layer.id)) return;
        if (layer.id === hoveredOverLabelLayerId) {
          mapRef.current?.setLayoutProperty(layer.id, "visibility", "visible");
        } else if (layer.id === selectedStormLabelLayerId && selectedStormRef.current && stormIsUnderResultsError(selectedStormRef.current, resultsFilterErrorList[resultsFilterErrorIndex])) {
          mapRef.current?.setLayoutProperty(layer.id, "visibility", "visible");
        } else {
          mapRef.current?.setLayoutProperty(layer.id, "visibility", "none");
        }
      });
    } else if (!stormQueryResultsRef.current || stormQueryResultsRef.current.size === 0) {
      // hide all storm labels if results are empty
      mapRef.current.getStyle()?.layers.forEach(layer => {
        if (isStormLabelLayer(layer.id) && mapRef.current?.getLayoutProperty(layer.id, 'visibility') === 'visible') {
          mapRef.current?.setLayoutProperty(layer.id, 'visibility', 'none');
        }
      });
    } else {
      setStormLabelVisibility();
    } 
  }, [isMapMode, mapLoaded, resultsFilterErrorList, resultsFilterErrorIndex, stormQueryResultsRef.current, hoveredOverStorm]);

  // when a storm is clicked, set state
  /*const handleStormClick = (layerId: string) => {
    if (!mapLoaded || !mapRef.current) return;

    // don't enable click interactions if there are storm query results and this is not part of the results
    const colorProperty = isStormLabelLayer(layerId) ? "text-color" : "line-color";
    if (stormQueryResultsRef.current && mapRef.current.getPaintProperty(layerId, colorProperty) === baseStormLineColor) return;

    const stormName = getStormNameFromLayer(layerId);
    if (selectedStormRef.current === stormName) {
      setSelectedStorm("");
    } else {
      setSelectedStorm(stormName);
    }
  };*/

  // when a storm is hovered over, update styling so the hovered over storm is emphasized
  // and the other storms are de-emphasized
  const handleMouseEnter = (layerId: string) => {
    if (!mapLoaded || !mapRef.current) return;

    const stormName = getStormNameFromLayer(layerId);

    // don't enable hover interactions if there are storm query results and this is not part of the results
    const colorProperty = isStormLabelLayer(layerId) ? "text-color" : "line-color";
    if (stormQueryResultsRef.current && mapRef.current.getPaintProperty(layerId, colorProperty) === baseStormLineColor) return;

    mapRef.current.getCanvas().style.cursor = "pointer";
    setHoveredOverStorm(stormName);
  };

  // when a storm is no longer being hovered over, update styling so the storm is de-emphasized
  // and all other storms also remain de-emphasized assuming they aren't selected
  const handleMouseLeave = (layerId: string) => {
    if (!mapLoaded || !mapRef.current) return;

    // don't enable hover interactions if there are storm query results and this is not part of the results
    const colorProperty = isStormLabelLayer(layerId) ? "text-color" : "line-color";
    if (stormQueryResultsRef.current && mapRef.current.getPaintProperty(layerId, colorProperty) === baseStormLineColor) return;

    mapRef.current.getCanvas().style.cursor = "";
    setHoveredOverStorm(undefined);
  };

  const addArrowLayer = (
    map: mapboxgl.Map,
    layerId: string,
    start: number[], 
    end: number[], 
    color: string = baseStormLineColor, 
    size: number = defaultArrowSize
  ) => {
    // return if layer already exists
    if (map.getLayer(layerId)) return;

    // Define arrow source
    if (!map.getSource(layerId)) {
      map.addSource(layerId, {
        type: 'geojson',
          data: {
              type: 'FeatureCollection',
              features: [{
                type: 'Feature',
                geometry: { type: 'Point', coordinates: end },
                properties: { bearing: getBearing(start, end) }
            }]
          }
      });
    }

    // Define arrow layer
    map.addLayer({
        id: layerId,
        type: 'symbol',
        source: layerId,
        layout: {
            'icon-image': arrowImgName,
            'icon-size': size,
            'icon-rotate': ['get', 'bearing'],
            'icon-allow-overlap': true,
            "visibility": "none"
        },
        paint: {
          'icon-color': color
        }
    });
  }

  // handle visibility of clusters
  useEffect(() => {
    if (!isMapMode || !mapLoaded || !mapRef.current || !dendrogram || !dendrogramDescendantCounts) return;

    if (!mapLoaded || !mapRef.current.isStyleLoaded()) {
      setTimeout(() => {
        // wait until style is loaded
      }, 200)
    }

    const clusters = getClustersAtThreshold(dendrogram, dendrogramLevelCountsList[dendrogramLevelIndex]);
    //console.log(clusters);
    //console.log(dendrogramDescendantCounts);
    mapRef.current.getStyle()?.layers.forEach(layer => {
      if (!isStormClusterLayer(layer.id)) return;

      // only display clusters that are at the threshold
      const clusterId = getClusterIdFromClusterLayer(layer.id);
      const cluster = clusters.find(cluster => cluster.id === clusterId);
      if (cluster && displayAllLinesOnViz) {
        const count = dendrogramDescendantCounts.get(cluster.id);
        if (count !== undefined) {
          mapRef.current?.setLayoutProperty(layer.id, 'visibility', 'visible');
        }
      } else {
        mapRef.current?.setLayoutProperty(layer.id, 'visibility', 'none');
      }
    });
  }, [isMapMode, dendrogram, dendrogramLevelCountsList, dendrogramLevelIndex, dendrogramDescendantCounts, displayAllLinesOnViz])

  /*const legendItems = (): JSX.Element => {
    if (!mapLoaded || !mapRef.current) return <></>;
    try {
      const items: JSX.Element[] = [];
      mapRef.current.getStyle()?.layers.forEach(layer => {
        if (!mapLoaded || !mapRef.current) return;
        if (isStormLayer(layer.id)) {
          const lineColor = mapRef.current.getPaintProperty(layer.id, 'line-color')?.toString();
          if (lineColor !== baseStormLineColor)
          items.push(
            <div className="legend-item">
              <div className="legend-item-swatch" style={{width: '10px', height: '10px', backgroundColor: lineColor}}>{lineColor}</div>
              <div>{getStormNameFromLayer(layer.id)}</div>
            </div>
          )
        }
      })
      return (<div className="legend-items-wrapper">{items}</div>);
    } catch (e) {
      return <></>;
    }
  }*/

  return (
    <div className="map-main-wrapper">
      <CustomMap
        showOverlay={showOverlay}
        setHoveredOverStorm={setHoveredOverStorm}
        overlayChildren={
          <ShapeFilterEditorWrapper
            sketchRef={sketchRef}
            shapeFilterIndex={currentShapeIndex}
            isInShapeLibrary={false}
            isAddingText={isAddingText}
            canvasWidth={canvasWidth}
            canvasHeight={canvasHeight}
            isActiveCanvas={true}
            canvasId={activeCanvasId}
            includeControls={true}
            handleClickMeasure={handleClickMeasure}
            handleClickAnnotationColor={handleClickAnnotationColor}
            handleClickTextButton={handleClickTextButton}
            handleClickEraserButton={handleClickEraserButton}
            displayMeasureNameLines={true}
          >
            <DialogFooter
              enableSaveAs={isEditingExistingSketch}
              onRequestClear={handleClearButton}
              onRequestCancel={handleCancelButton}
              onRequestSave={handleSaveButton}
              onRequestSaveAs={handleSaveAsButton}
            />
          </ShapeFilterEditorWrapper>
        }
      />
      {/*<div className="map-legend" style={{height: canvasHeight}}>
          <div>Legend</div>
          {legendItems()}
        </div>*/}
    </div>
  );
};

export default MapWrapper;
