import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from './AppContext';
import ShapeFilterEditorWrapper from './ShapeFilterEditorWrapper';
import {ReactComponent as SketchCursor} from '../sketch_cursor.svg';

import "./ShapeLibrary.css";

import { SketchRef } from './Sketch';
import { throwIfNull } from './utils';

type ShapeLibraryProps = {
    setDialogOpen: (value: React.SetStateAction<boolean>) => void;
    activeSketchRef: React.RefObject<SketchRef>;
    queryShapes: (index: number) => void;
}

const ShapeLibrary: React.FC<ShapeLibraryProps> = ({ setDialogOpen, activeSketchRef, queryShapes }) => {
    const { 
        addShapeFilterToLibrary,
        currentShapeIndex,
        removeShapeFilterFromLibrary, 
        setCurrentShapeIndex,
        shapeFilterLibrary,
        enableEditing,
        canvasWidth,
        canvasHeight,
        setStormQueryResults,
        setBabyNameQueryResults,
        //setSelectedStorm,
        isMapMode,
        setDisplayAllLinesOnViz,
        //setDisplaySketchOnViz,
        setIsMapModeForIndex,
        updateCurrentMeasure,
        defaultMeasure,
        modeKey
    } = throwIfNull(useContext(AppContext));

    const [existingShapes, setExistingShapes] = useState<JSX.Element[]>([]); 

    const selectedClassName = "shape-selected";
    const cleanUpAlreadySelectedClasses = () => {
        const shapes = document.getElementsByClassName("shape-item");
        for (var i = 0; i < shapes.length; i++) {
            if (shapes[i].classList.contains(selectedClassName)) {
                shapes[i].classList.remove(selectedClassName);
                break;
            }
        }
    }

    const handleNewShape = () => {
        if (isMapMode) {
            //setSelectedStorm('');
            setStormQueryResults(undefined);
        } else {
            setBabyNameQueryResults(undefined);
        }
        setDisplayAllLinesOnViz(false);
        //setDisplaySketchOnViz(false);

        // clear the canvas every 200 ms for 1200 ms;
        var started = Date.now();
        var interval = setInterval(function(){
            if (Date.now() - started > 1200) {
                clearInterval(interval);
                setDialogOpen(true);
                const newIndex = addShapeFilterToLibrary();
                cleanUpAlreadySelectedClasses();
                document.getElementById(`shape-${newIndex}`)?.classList.add(selectedClassName);
                const measureName = defaultMeasure.measureName.get(modeKey)
                setCurrentShapeIndex(newIndex);
                if (measureName) {
                    console.log(`modeKey=${modeKey} updating current measure to measureName=${measureName}`)
                    updateCurrentMeasure(measureName);
                }
            } else {    
                activeSketchRef.current?.clearCanvas()
            }
        }, 200);
    }

    const handleEditShape = () => {
        enableEditing();
        setDialogOpen(true);
    }

    const handleDeleteShape = () => {
        if (shapeFilterLibrary.length === 0 || currentShapeIndex === undefined) return;
        const beforeLength = shapeFilterLibrary.length;
        const shouldUpdateIndex = currentShapeIndex === beforeLength - 1;
        removeShapeFilterFromLibrary(currentShapeIndex);
        let newShapeIndex = currentShapeIndex;
        if (shouldUpdateIndex) {
            newShapeIndex = beforeLength - 2;
            setCurrentShapeIndex(newShapeIndex);
        }
        queryShapes(newShapeIndex);
        //queryShape(shapeFilterLibrary[newShapeIndex]);
    }

    const handleSelection = (shapeIndex: number) => {
        console.log(`clicked on ${shapeIndex}`);
        cleanUpAlreadySelectedClasses();
        document.getElementById(`shape-${shapeIndex}`)?.classList.add(selectedClassName);
        setIsMapModeForIndex(shapeIndex)

        // wait until map is loaded
        setTimeout(() => {
            queryShapes(shapeIndex);
        }, 500);
    }

    const createShapesLibraryItem = (keyPrefix: string, element: JSX.Element) => {
        return (
            <div className="shape-item-wrapper" key={`${keyPrefix}-wrapper`}>
                {element}
            </div>
        );
    }

    //console.log(shapeFilterLibrary);
    
    useEffect(() => {
        setExistingShapes(shapeFilterLibrary.map((shape, index) => {
            const isSelected = currentShapeIndex === index;
            const mapHeight = shape.map.isMapMode ? document.querySelector('.map-wrapper')?.clientHeight ?? canvasHeight : canvasHeight;
            //console.log(`mapHeight: ${mapHeight}, canvasHeigth: ${canvasHeight}`);
            //console.log(activeSketchRef);
            return createShapesLibraryItem(
                `shape-${index}`,
                <button
                    id={`shape-${index}`}
                    key={`shape-${index}`}
                    className={`shape-item ${isSelected ? " " + selectedClassName : ""}`}
                    onClick={() => handleSelection(index)}>
                    {shape.map.isMapMode && shape.map.mapBlob ?
                        <div style={{position: 'relative', width: 300, height: mapHeight * 300 / canvasWidth}}>
                            <img src={URL.createObjectURL(shape.map.mapBlob)} alt="sketch" style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}/>
                            <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: canvasHeight * 300 / canvasWidth}}>
                                <ShapeFilterEditorWrapper
                                    sketchRef={currentShapeIndex === index ? activeSketchRef : undefined} 
                                    shapeFilterIndex={index}
                                    isInShapeLibrary={true}
                                    canvasWidth={canvasWidth}
                                    canvasHeight={canvasHeight}
                                    displayMeasureNameLines={true}/>
                            </div>
                        </div> :
                        <ShapeFilterEditorWrapper
                            sketchRef={currentShapeIndex === index ? activeSketchRef : undefined}  /* only want to clear clear the library item that is associated with the current shown dialog */
                            shapeFilterIndex={index}
                            isInShapeLibrary={true}
                            canvasWidth={300}
                            canvasHeight={canvasHeight * 300 / canvasWidth}
                            displayMeasureNameLines={true}/>
                    }
                </button>
            )
        }));
    }, [shapeFilterLibrary, currentShapeIndex, isMapMode]);

    return (
        <div className="shape-library">
            <div style={{display: 'flex', flexDirection: 'column', maxHeight: '95%', height: '95%'}}>
                <div className="shape-library-header">
                    <button
                        className="search-button"
                        onClick={handleNewShape}
                    >
                        <div className="sketch-cursor-wrapper">
                            <SketchCursor />
                        </div>
                        Search
                    </button>
                </div>
                <div className="shape-list">
                    <>
                        {existingShapes}
                    </>
                </div>
            </div>
            {shapeFilterLibrary.length > 0 &&
                <div className="shape-library-buttons-wrapper">
                    <button className="shape-button light-button" onClick={handleEditShape}>Edit</button>
                    <button className="shape-button light-button" onClick={handleDeleteShape}>Delete</button>
                </div>
            }
        </div>


    );
};

export default ShapeLibrary;

