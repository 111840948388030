
export function throwIfNull<T>(value: T | null | undefined, message?: string): T {
    if (value === null || value === undefined) {
        throw new Error(message || 'Value is null or undefined');
    }
    return value;
}

export class Point2D
{
    public constructor(x:number, y:number)
    {
        this.x = x;
        this.y = y;
    }

    public x:number;
    public y:number;
};

export class ShapeSearchReturnStruct
{
    public constructor(partitionName:string, score:number, matchedSegmentIds:number[])
    {
        this.partitionName = partitionName;
        this.score = score;
        this.matchedSegmentIds = matchedSegmentIds;
    }

    public partitionName:string;
    public score:number;
    public matchedSegmentIds:number[];
};

export function getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

/** get the dimensions of the sketch HTML Canvas */
export const getCanvasDimensions = (): {width: number, height: number} | undefined => {
    const vizWrapper = document.querySelector('.map-wrapper') ?? document.querySelector('.line-chart-wrapper');
    const hiddenOverlay = document.querySelector('.hidden-overlay');
    if (!vizWrapper || !hiddenOverlay) return undefined;
    const sketchFooter = hiddenOverlay.querySelector('.shape-editor-footer');
    if (!sketchFooter) return undefined;
    return {
        width: vizWrapper.clientWidth,
        height: vizWrapper.clientHeight - sketchFooter.clientHeight,
    };
}

/* Converts HSV color to RGB string, expects 0 <= h, s, v <= 1 */
export const HSVtoRGB = (h: number, s: number, v: number): string => {
    var r, g, b, i, f, p, q, t;
    i = Math.floor(h * 6);
    f = h * 6 - i;
    p = v * (1 - s);
    q = v * (1 - f * s);
    t = v * (1 - (1 - f) * s);
    switch (i % 6) {
        case 0: {
            r = v;
            g = t;
            b = p;
            break;
        }
        case 1: {
            r = q;
            g = v;
            b = p;
            break;
        }
        case 2: {
            r = p;
            g = v;
            b = t;
            break;
        }
        case 3: {
            r = p;
            g = q;
            b = v;
            break;
        }
        case 4: {
            r = t;
            g = p;
            b = v;
            break;
        }
        case 5:
        default: {
            r = v;
            g = p;
            b = q;
            break;
        }
    }
    return `rgb(${Math.round(r * 255)}, ${Math.round(g * 255)}, ${Math.round(b * 255)})`;
}

export function getUniqueSortedArray(arr: any[]): any[] {
    function onlyUnique(value: any, index: number, arr: any[]) {
        return arr.indexOf(value) === index;
    }
    return arr.sort((a, b) => a - b).filter(onlyUnique);
}

export function sortMapByNumericalKeyDescending(map: Map<number,any>) {
   return new Map([...map.entries()].sort((a, b) => b[0] - a[0]));
}

export function getBearing(start: number[], end: number[]) {
    const [lng1, lat1] = start;
    const [lng2, lat2] = end;

    const toRadians = Math.PI / 180;
    const lat1Rad = lat1 * toRadians;
    const lat2Rad = lat2 * toRadians;
    const lng1Rad = lng1 * toRadians;
    const lng2Rad = lng2 * toRadians;

    const deltaLng = lng2Rad - lng1Rad;
    const y = Math.sin(deltaLng) * Math.cos(lat2Rad);
    const x = Math.cos(lat1Rad) * Math.sin(lat2Rad) - Math.sin(lat1Rad) * Math.cos(lat2Rad) * Math.cos(deltaLng);
    const angle = Math.atan2(y, x);

    return (angle * 180 / Math.PI + 360) % 360; // Convert to degrees and normalize
}

export function base64ToUint8Array(base64: string, width: number, height: number, callback: (value:  Uint8Array) => void) {
    const img = new Image();
    img.src = base64;
    img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        // Draw image on canvas
        ctx.drawImage(img, 0, 0, width, height);

        // Get pixel data (RGBA)
        const imageData = ctx.getImageData(0, 0, width, height).data;
        
        // Convert to Uint8Array
        callback(new Uint8Array(imageData));
    };
}


export function generatedPostgreSQLQueryIsValid(response: string, isMapMode: boolean): boolean {
    // TODO: improve postgresql query validation

    // handle maps
    if (isMapMode) {
        return response.includes("SELECT") &&
            (response.includes('storm_tracks_global_mapnorm_normalized_data'));
    }

    // baby names
    return response.includes("SELECT") &&
        (response.includes('babynames_local_123_normalized_data'));
}

/** returns the given map, filtered by items with a key in the filterKeyValues list*/
export function filterMapByKeyValues(map: Map<string, any>, filterKeyValues: string[]) {
    const filteredResults = new Map();
    map.forEach((value, key) => {
        if (filterKeyValues.includes(key)) {
            filteredResults.set(key, value);
        }
    })
    return filteredResults;
}

export const sleep = (ms: number): Promise<void> =>
    new Promise(resolve => setTimeout(resolve, ms));