import React, { useContext } from "react";
import { AppContext, MeasureType, Mode } from "./AppContext";
import { Sketch, SketchRef } from "./Sketch";
import { throwIfNull } from "./utils";

import "./ShapeFilterEditorWrapper.css";

import { ReactComponent as AddTextIcon} from "../TextString_Icon.svg"
import { ReactComponent as EraserIcon } from "../Eraser_Icon.svg";


type ShapeFilterEditorWrapperProps = {
  shapeFilterIndex: number|undefined;
  isInShapeLibrary: boolean;
  isAddingText?: boolean;
  canvasWidth: number;
  canvasHeight: number;
  sketchRef?: React.RefObject<SketchRef>;
  canvasId?: string;
  includeControls?: boolean;
  handleClickMeasure?: (measure: MeasureType) => void;
  handleClickAnnotationColor?: (color: string) => void;
  handleClickTextButton?: () => void;
  handleClickEraserButton?: () => void;
  children?: JSX.Element;
  displayMeasureNameLines: boolean
  isActiveCanvas?: boolean;
};

const ShapeFilterEditorWrapper: React.FC<ShapeFilterEditorWrapperProps> = ({
  shapeFilterIndex,
  isInShapeLibrary,
  isAddingText,
  canvasWidth,
  canvasHeight,
  sketchRef,
  canvasId,
  includeControls,
  handleClickMeasure,
  handleClickAnnotationColor,
  handleClickTextButton,
  children,
  displayMeasureNameLines,
  isActiveCanvas,
  handleClickEraserButton
}) => {
  const {
    listOfMeasures,
    annotationColors,
    currentMeasure,
    currentColor,
    defaultMeasure,
    isMapMode,
    currentPrecision,
    setCurrentPrecision,
    eraserEnabled,
  } = throwIfNull(useContext(AppContext));

  const { modeKey } = useContext(AppContext)!;
  
  let measureButtons: JSX.Element[] = [];
  listOfMeasures.forEach((measure) => {

    const label = measure.measureName.get(modeKey) ?? Mode.Baby_Trends;
    const isDefault = defaultMeasure.measureName.get(modeKey) === label;
    const isSelected = currentMeasure === label && currentColor === measure.color;
    
    measureButtons.push(
      <button
        className="measure-button"
        key={label} 
        onClick={() => handleClickMeasure?.(measure)}
      >
        <span className="measure-button-swatch-holder">
          <span
            className={`measure-button-swatch ${
              isDefault ? "measure-button-swatch-1" : "measure-button-swatch-2"
            } ${isSelected ? "selected" : ""}`}
          ></span>
        </span>
        <div className="measure-button-text-wrapper">
          <span className={`measure-button-text ${isSelected ? "selected" : ""}`}>
            {label === "Customer CSAT" ? "Customer Satisfaction" : label}
          </span>
        </div>
      </button>
    );
  });
  

  const blackAnnotation = annotationColors.get('black');

  return (
    <div className="shape-editor-wrapper">
      {includeControls && <div className="shape-editor-header">
        <div className="shape-editor-options-description">
          Choose a measure color to sketch a shape or an annotation color to
          add notes.
        </div>
      </div>}
      <div
        className="sketch-wrapper"
        style={{ width: "fit-content", height: "fit-content" }}
      >
        <Sketch
          ref={sketchRef}
          shapeFilterIndex={shapeFilterIndex}
          isActiveCanvas={isActiveCanvas ?? false}
          isInShapeLibrary={isInShapeLibrary}
          isAddingText={isAddingText}
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          showGuidelines={!isMapMode}
          canvasId={canvasId}
          isMapMode={isMapMode}
          displayMeasureNameLines={displayMeasureNameLines}
        />
      </div>
      {includeControls &&
        blackAnnotation && 
        handleClickMeasure &&
        handleClickAnnotationColor &&
        handleClickTextButton &&
        handleClickEraserButton && (
          <div className="shape-editor-footer">
            <div className="shape-editor-options">
              <div className="measure-options">
                <div className="measure-colors-description">
                  <b>Measures</b>
                </div>
                <div className="measure-buttons-wrapper">{measureButtons}</div>
              </div>

              <div className="shape-editor-controls">
                  <div className="precision-controls-wrapper">
                    <label htmlFor="precision" className="precision-label">Precision</label>
                    <div className="precision-controls">
                      <input type="range" className="precision-slider" name="precision" min={0} max={100} value={currentPrecision} onChange={(e) => setCurrentPrecision(parseFloat(e.currentTarget.value))}/>
                      <input type="text" className="precision-text" min={0} max={100} value={currentPrecision} onChange={(e) => setCurrentPrecision(parseFloat(e.currentTarget.value))} />
                    </div>
                  </div>
              </div>

              <div className="annotation-options">
                <div className="annotation-colors-description">
                  <b>Annotation</b>
                </div>
                <div className="annotation-colors-wrapper">
                  <button
                    className={`annotation-color annotation-color-black ${
                      currentColor === blackAnnotation ? "selected" : ""
                    }`}
                    onClick={() => handleClickAnnotationColor(blackAnnotation)}
                  ></button>
                </div>
              </div>

              <div className="editor-button-wrapper text-button-wrapper">
                <button
                  className="editor-button"
                  onClick={handleClickTextButton}
                >
                  <div
                    className={`editor-icon-holder ${
                      isAddingText ? "selected" : ""
                    }`}
                  >
                    <AddTextIcon />
                  </div>
                  <div className="editor-button-text-wrapper">
                    <div
                      className={`editor-button-text ${
                        isAddingText ? "selected" : ""
                      }`}
                    >
                      Text
                    </div>
                  </div>
                </button>
              </div>

              <div className="editor-button-wrapper eraser-button-wrapper">
								<button className="eraser-button" onClick={handleClickEraserButton}>
									<div className={`eraser-icon-holder ${eraserEnabled ? 'selected' : ''}`}>
										<EraserIcon />
									</div>
									<div className="eraser-button-text-wrapper">
										<div className={`eraser-button-text ${eraserEnabled ? 'selected' : ''}`}>
											Eraser
										</div>
									</div>
								</button>
							</div>
            </div>
            {children}
          </div>
        )}
    </div>
  );
};

export default ShapeFilterEditorWrapper;
