import React from "react";

import "./Overlay.css";

type OverlayProps = {
    overlayChildren: JSX.Element;
    showOverlay: boolean;
  };
  
const Overlay: React.FC<OverlayProps> = ({
  overlayChildren,
  showOverlay,
}): JSX.Element => {
    if (!showOverlay) {
        return <></>
    }
    
    return (
        <div className={`overlay main-overlay`}>
            <div className="overlay-content-wrapper">{overlayChildren}</div>
        </div>
    )
}

export default Overlay;