import { MapShapeType, ShapeType } from "./AppContext";
import { ShapeFilterDefinition } from "./ShapeFilterDefinition";

export function cloneLibrary(shapeFilterLibrary: ShapeType[]): ShapeType[] {
    return shapeFilterLibrary.map(sketch => cloneShapeType(sketch))
}

export function cloneShapeType(sketch: ShapeType): ShapeType{
  return {
    annotationShapes: cloneShapes(sketch.annotationShapes),
    measureShapes: cloneShapes(sketch.measureShapes),
    textBoxes: cloneObjectArray(sketch.textBoxes),
    map: {
      isMapMode: sketch.map.isMapMode,
      mapInfo: cloneMapObject(sketch.map.mapInfo),
      mapBlob: cloneBlob(sketch.map.mapBlob)
    },
    annotationParsingFilteredNames: cloneObjectArray(sketch.annotationParsingFilteredNames)
  }
}

export function cloneMapObject(map: MapShapeType): MapShapeType {
  return {
    coordinates: cloneObjectArray(map.coordinates),
    center: cloneObjectArray(map.center),
    zoom: map.zoom
  };
}

function mapsAreEqual(a: MapShapeType, b: MapShapeType): boolean {
  return arraysAreEqual(a.coordinates, b.coordinates) && arraysAreEqual(a.center, b.center) && a.zoom === b.zoom;
}

export function cloneBlob(blob: Blob | undefined): Blob | undefined {
  if (!blob) return undefined;
  return new Blob([blob], {type: blob.type});
}

export function cloneShapes(
  map: Map<string, ShapeFilterDefinition>
): Map<string, ShapeFilterDefinition> {
  let clone = new Map<string, ShapeFilterDefinition>();
  map.forEach((shape: ShapeFilterDefinition, key: string) => {
    let clonedShape = cloneShapeFilterDefinition(shape);
    clone.set(key, clonedShape);
  });
  return clone;
}

export function cloneShapeFilterDefinition(
  shape: ShapeFilterDefinition
): ShapeFilterDefinition {
  let clonedShape = new ShapeFilterDefinition(25, 0.35);
  clonedShape._lineSegments = cloneObjectArray(shape._lineSegments);
  return clonedShape;
}

function shapesAreEqual(
  a: Map<string, ShapeFilterDefinition>,
  b: Map<string, ShapeFilterDefinition>,
): boolean {
  if (a.size !== b.size) return false;
  for (const [key, value] of a) {
    if (!b.has(key)) return false;
    if (!arraysAreEqual(value._lineSegments, b.get(key)?._lineSegments)) return false;
  }
return true;
}

export function cloneObjectArray(arr: any[] | undefined) {
  if (!arr || arr.length === 0) return [];
  return JSON.parse(JSON.stringify(arr));
}

function arraysAreEqual(a: any[] | undefined, b: any[] | undefined) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export function itemsAreEqualExceptBlob(a: ShapeType, b: ShapeType): boolean {
  if (!a && !b) return true;
  if ((!a && b) || (!b && a)) return false;
  return shapesAreEqual(a.annotationShapes, b.annotationShapes) &&
    shapesAreEqual(a.measureShapes, b.measureShapes) && 
    mapsAreEqual(a.map.mapInfo, b.map.mapInfo) &&
    arraysAreEqual(a.textBoxes, b.textBoxes);
}
