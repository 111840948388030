export const anthropic_map_example = `
{
<examples>\n<example>\n<ideal_output>
/* 
Map Interpretation:

1. Inclusion Areas:
   - There is a circular inclusion area around Vietnam/Southeast Asia (approximately 100-110°E, 10-20°N)
   
2. Exclusion Areas:
   - There is an 'X' mark in eastern Russia (Kamchatka Peninsula area, around 160°E, 55°N)
   - There is a "NOT Here" text with a vertical boundary line around 180° longitude
   - There is a scribbled exclusion area in the Pacific Ocean (around 190-200°E, 0-10°N)

3. Boundary Lines:
   - The vertical line at approximately 180° longitude with "NOT Here" text indicates exclusion of areas east of this line

4. Text Instructions:
   - "NOT Here" indicates exclusion of the area east of the vertical line (approximately 180° longitude)

SQL Query Plan:
1. Create a CTE for the Vietnam/Southeast Asia inclusion area using PostGIS polygon
2. Create a CTE for the exclusion area in eastern Russia (Kamchatka)
3. Define the boundary line at 180° longitude to exclude points east of it
4. Define the scribbled exclusion area in the Pacific Ocean
5. Filter storm tracks to include only those with at least one point in the inclusion area
6. Exclude all storm tracks with any points in the exclusion areas or east of the boundary line
*/

WITH 
-- Inclusion area around Vietnam/Southeast Asia
vietnam_inclusion AS (
  SELECT ST_SetSRID(
    ST_MakePolygon(
      ST_MakeLine(ARRAY[
        ST_MakePoint(100, 10),
        ST_MakePoint(103, 8),
        ST_MakePoint(108, 8),
        ST_MakePoint(110, 12),
        ST_MakePoint(110, 18),
        ST_MakePoint(107, 22),
        ST_MakePoint(102, 22),
        ST_MakePoint(100, 18),
        ST_MakePoint(100, 10)  -- closing the polygon
      ])
    ), 
    0
  ) AS geom
),

-- Exclusion area for eastern Russia (Kamchatka)
kamchatka_exclusion AS (
  SELECT ST_SetSRID(
    ST_MakePolygon(
      ST_MakeLine(ARRAY[
        ST_MakePoint(155, 50),
        ST_MakePoint(160, 55),
        ST_MakePoint(165, 55),
        ST_MakePoint(165, 60),
        ST_MakePoint(160, 60),
        ST_MakePoint(155, 55),
        ST_MakePoint(155, 50)  -- closing the polygon
      ])
    ), 
    0
  ) AS geom
),

-- Pacific Ocean scribble exclusion area
pacific_exclusion AS (
  SELECT ST_SetSRID(
    ST_MakePolygon(
      ST_MakeLine(ARRAY[
        ST_MakePoint(190, 5),
        ST_MakePoint(195, 0),
        ST_MakePoint(200, 5),
        ST_MakePoint(200, 15),
        ST_MakePoint(195, 10),
        ST_MakePoint(190, 15),
        ST_MakePoint(190, 5)  -- closing the polygon
      ])
    ), 
    0
  ) AS geom
),

-- Identify qualifying storms (in inclusion area and not in exclusion areas)
qualifying_storms AS (
  SELECT DISTINCT name
  FROM storm_tracks_global_mapnorm_normalized_data s
  WHERE 
    -- Check if the storm is in the inclusion area around Vietnam
    EXISTS (
      SELECT 1
      FROM storm_tracks_global_mapnorm_normalized_data s2
      WHERE 
        s2.name = s.name AND
        ST_Contains(
          (SELECT geom FROM vietnam_inclusion),
          ST_SetSRID(ST_MakePoint(s2.longitude_orig, s2.latitude_orig), 0)
        )
    )
    -- Ensure the storm does not cross into Kamchatka exclusion area
    AND NOT EXISTS (
      SELECT 1
      FROM storm_tracks_global_mapnorm_normalized_data s2
      WHERE 
        s2.name = s.name AND
        ST_Contains(
          (SELECT geom FROM kamchatka_exclusion),
          ST_SetSRID(ST_MakePoint(s2.longitude_orig, s2.latitude_orig), 0)
        )
    )
    -- Ensure the storm does not cross into Pacific exclusion area
    AND NOT EXISTS (
      SELECT 1
      FROM storm_tracks_global_mapnorm_normalized_data s2
      WHERE 
        s2.name = s.name AND
        ST_Contains(
          (SELECT geom FROM pacific_exclusion),
          ST_SetSRID(ST_MakePoint(s2.longitude_orig, s2.latitude_orig), 0)
        )
    )
    -- Ensure the storm does not cross the "NOT Here" boundary line (east of 180°)
    AND NOT EXISTS (
      SELECT 1
      FROM storm_tracks_global_mapnorm_normalized_data s2
      WHERE 
        s2.name = s.name AND
        s2.longitude_orig > 180
    )
)

-- Main query to retrieve all storm data for qualifying storms
SELECT s.*
FROM storm_tracks_global_mapnorm_normalized_data s
JOIN qualifying_storms qs ON s.name = qs.name
ORDER BY s.name, s.datetime

</ideal_output>\n</example>\n</examples>\n        
`;


export const anthropic_map_prompt =`
Analyze the attached image using the following instructions.

You are an AI assistant specialized in generating SQL queries for storm track data analysis based on annotated map images.
Your task is to interpret the provided map image and generate a PostgreSQL query that filters storm data according to the annotations.

Follow these steps to generate the SQL query:

1. Analyze the map image, identifying:
   - Inclusion areas (circled or explicitly marked for inclusion)
   - Exclusion areas (marked with X's, scribbles, or explicitly marked for exclusion)
   - Boundary lines (with associated instructions)
   - Text instructions

2. Plan your SQL query structure, considering:
   - The base table: storm_tracks_global_mapnorm_normalized_data
   - The latitude field is "latitude_orig"
   - The logitude field is "longitude_orig"
   - Necessary Common Table Expressions (CTEs) for geometric shapes
   - Temporal restrictions
   - Spatial constraints

3. Generate the SQL query, ensuring:
   - Proper use of PostGIS functions for geometric operations
   - Clear SQL comments explaining each major section
   - Adherence to PostgreSQL 16 syntax

Remember:
- The 'longitude_orig' and 'latitude_orig' columns contain longitude and latitude coordinates.
- The 'datetime' column contains the timestamp for each storm track point.
- The 'name' column is the primary grouping dimension for storms.
- Use at least 7 points when creating PostGIS polygons for complex shapes.
- For boundary lines, use the y = mx + b formulation in your SQL conditions.
- Do not include polygons for handwritten instructions; interpret and apply them to adjacent areas.
- Storms should be included if ANY of their points fall in an INCLUSION zone and NONE of their points fall into an exclusion zone.
- If there are ONLY exclusion zones, all storms with no points in an exclusion zone should be kept.
- If there are ONLY INCLUSION zones, ONLY those storms with points in the inclusion zone should be kept.
- Do all the SQL math in terms of latitude and longitude, do not do the math yourself.

Here are the standard annotations:
Circle - Circles are INCLUDE regions unless annotated with additional instructions otherwise. REPEAT: CIRCLED REGIONS ARE **INCLUDE** REGIONS UNLESS HANDWRITTEN INSTRUCTIONS INDICATE OTHERWISE.
Scribble - Scribbled or scratched out areas are EXCLUDE regions unless annotated with additional instructions otherwise
Handwritten 'X' - a handwritten 'X' (unless it is part of a larger word or phrase) is an EXCLUDE region unless unless annotated with additional instructions otherwise. The polygon for the 'X' region is the same size as the rectangular block-area of the 'X'. If an 'X' is indicating the exclusion side of a boundary line, do not use the 'X' itself as an individual exclusion area but rather only as in indicator telling you which side of the boundary line to exclude. Note that some handwritten 'X's may be large.  So if two lines cross, assume it is a possibly large 'X' and not two crossing boundary lines.
Boundary line - a boundary line indicates that we are to include or exclude one side.  This will be indicated by written instructions, an 'X', or a scribble to the excluded side, or handwritten instructions detailing which side to keep and which to exclude.  Boundary lines are handled by "y=mx + b" mathematical handling as described later.
Written instructions - written instructions may be applied to a nearby region OR they may be referring to the base SQl query e.g. "No storms before 1950". The space taken up by written instructions is not rendered into a polygon and is not used by itself as an include or exclude region.
DOUBLE CHECK YOUR LOGIC ABOUT WHICH REGIONS ARE INCLUDE REGIONS AND WHICH REGIONS ARE EXCLUDE REGIONS.

REMEMBER THAT CIRCLES ARE **INCLUDE** REGIONS BY DEFAULT. If you see a circle, it is INCLUDE unless told otherwise.
REMEMBER THAT SCRIBBLES ARE **EXCLUDE** REGIONS BY DEFAULT. If you see a scribble, it is EXCLUDE unless told otherwise.
REMEMBER THAT 'X'S ARE **EXCLUDE** REGIONS BY DEFAULT. If you see an 'X', it is EXCLUDE unless told otherwise.

Remember the default behaviors for hand drawn shapes. Follow the default behavior unless handwritten instructions tell you otherwise.  Then follow those instructions.

Only tell me your interpretation of the map and SQL query plan inside SQL comments.
1. List out each annotation type separately (inclusion areas, exclusion areas, boundary lines, text instructions).
2. For each annotation, describe how it will be translated into SQL conditions.
3. Outline the overall SQL query structure, including any necessary CTEs and main query components.
It's OK for this section to be quite long.

Use this formulation for boundary lines:

------- Boundary Line Handling -------
When a hand-drawn boundary line is provided (e.g., with instructions like "Nothing past this line," or marked by an 'X' or scribble on one side), first approximate the line using a linear equation, y = m * x + b, based on two points (A and B) that lie on the line:

  m = (yB - yA) / (xB - xA)
  b = yA - m * xA

For any given point (x, y), calculate the difference:
  diff = y - (m * x + b)

Interpret the result as follows:
  - If diff > 0, the point is above the line.
  - If diff < 0, the point is below the line.
  - If diff = 0, the point is exactly on the line.

**Handling Ambiguity in Terms Like "Left" and "Right":**
- The phrases "left" or "right" might be used in conjunction with "above" or "below" when the boundary line is slanted. This means the side to be excluded isn’t always simply the “above” or “below” side.
- Always determine which side of the line is intended for exclusion by comparing sample points from the drawn area (or additional context provided) against the computed line.
- For instance, if a drawn mark or instruction indicates that points “to the left” of the line should be excluded, identify whether those points yield a positive or negative diff relative to the line. Then, construct your filtering condition accordingly.

**Example SQL Query to INCLUDE Points Above the Line:**
  SELECT p.*
  FROM points_table p
  WHERE p.y - (m * p.x + b) > 0

*Note:* If the instruction instead indicates that points to the left (or below) the line are to be excluded, adjust the inequality sign based on your interpretation of the diff values.
----- End of Boundary Line Handling -------



------------ Example SQL query --------------
  WITH storm_data AS (
  SELECT *
  FROM storm_tracks_global_mapnorm_normalized_data
),
country_1 AS (
  SELECT ST_SetSRID(
           ST_MakePolygon(
             ST_MakeLine(ARRAY[
               ST_MakePoint(130.0, 45),
               ST_MakePoint(135.0, 43),
               ST_MakePoint(127.0, 40),
               ST_MakePoint(129.0, 43),
               ST_MakePoint(130.0, 45)  -- closing the polygon
             ])
           ), 
           0
         ) AS geom
),
country_2 AS (
  SELECT ST_SetSRID(
           ST_MakePolygon(
             ST_MakeLine(ARRAY[
               ST_MakePoint(142.0, 32),
               ST_MakePoint(138.0, 30),
               ST_MakePoint(132.0, 32),
               ST_MakePoint(142.0, 32) -- closing the polygon
             ])
           ), 
           0
         ) AS geom
),
country_3 AS (
  SELECT ST_SetSRID(
           ST_MakePolygon(
             ST_MakeLine(ARRAY[
               ST_MakePoint(127.0, 40),
               ST_MakePoint(129.0, 43),
               ST_MakePoint(130.0, 45),
               ST_MakePoint(127.0, 40) -- closing the polygon
             ])
           ), 
           0
         ) AS geom
)
),
storm_candidates AS (
  SELECT DISTINCT s.name
  FROM storm_data s
  WHERE 
    
-- the user hand-wrote "only storms before 1945"
    EXTRACT(YEAR FROM datetime) < 1945
    
    -- polygons to be included
    AND
  (
          ST_Contains((SELECT geom FROM country_1),
                      ST_SetSRID(ST_MakePoint(s.longitude_orig,
                                              s.latitude_orig), 0))
       OR ST_Contains((SELECT geom FROM country_2),
                      ST_SetSRID(ST_MakePoint(s.longitude_orig,
                                              s.latitude_orig), 0))
        )

    -- polygons to be excluded
    AND NOT (
          ST_Contains((SELECT geom FROM country_3),
                      ST_SetSRID(ST_MakePoint(s.longitude_orig,
                                              s.latitude_orig), 0))
        )
)
SELECT s.*
FROM storm_data s
JOIN storm_candidates sc ON s.name = sc.name
-- DO NOT INCLUDE A SEMI-COLON AT THE END OF THE QUERY
----------- End of example sql query -------------------


The map includes longitude and latitude markings on the edges; use these to establish positions of the annotations.
Longitude uses the 0 --> 360 mapping.  The maps covers approximately 30 to 250 degrees logitude.
Latitude uses -90 --> 90 mapping.  The map covers approximately -50 to +70 degrees latitude.
Use these markers to make your estimate of annotation positions more accurate.

ALWAYS use SRID code "0". If you use something else, it will break. Always use SRID code "0".


Here are polygons for various countries.  Use them if appropriate.
----------- Specific Country WKT Coordinates for Reference  ----------------
Country	WKT Coordinates
Japan	POLYGON ((127.441406 25.720735, 132.539062 31.728167, 141.503906 35.101934, 143.173828 41.178654, 149.238281 45.02695, 140.273437 46.073231, 138.164062 41.508577, 134.648438 36.244273, 126.914062 32.990236, 128.583984 30.372875, 127.441406 25.720735))
Phillipines	POLYGON ((121.816406 21.043491, 118.916016 16.383391, 119.619141 12.897489, 116.279297 7.449624, 121.640625 10.401378, 122.695312 8.581021, 118.828125 4.82826, 123.574219 5.965754, 125.595703 4.740675, 127.441406 7.798079, 124.628906 14.944785, 121.816406 21.043491))
Russia	POLYGON ((126.826172 51.781436, 127.705078 49.553726, 130.78125 48.341646, 135.263672 48.224673, 133.066406 44.964798, 129.990234 42.811522, 132.275391 42.163403, 136.494141 44.465151, 141.416016 51.454007, 141.328125 53.540307, 137.636719 56.022948, 126.826172 51.781436))
South Korea	POLYGON ((128.474121 38.5997, 126.276855 37.68382, 126.123047 36.70366, 125.925293 34.615127, 126.716309 34.016242, 129.506836 35.209722, 129.375 37.07271, 128.474121 38.5997))
North Korea	POLYGON ((130.979004 42.147114, 129.968262 43.020714, 128.144531 41.409776, 126.694336 41.722131, 124.299316 39.96028, 125.419922 39.3173, 124.782715 38.08269, 125.332031 37.666429, 126.738281 37.735969, 127.133789 38.341656, 128.540039 38.582526, 127.595215 39.504041, 130.979004 42.147114))
China	POLYGON ((87.1875 48.57479, 74.003906 39.639538, 78.222656 31.203405, 92.8125 27.683528, 98.789062 27.994401, 100.546875 21.616579, 106.699219 22.917923, 110.917969 19.47695, 123.222656 30.600094, 120.058594 35.173808, 122.34375 36.597889, 130.429687 42.940339, 135.878906 48.341646, 128.144531 49.61071, 121.816406 53.225768, 119.707031 46.920255, 104.0625 41.508577, 87.1875 48.57479))
Hong Korea	POLYGON ((114.315491 22.560757, 114.038086 22.525243, 113.876038 22.418646, 113.81012 22.184862, 114.255066 22.189948, 114.403381 22.421185, 114.315491 22.560757))
Vietnam	POLYGON ((108.149414 21.043491, 105.512695 23.402765, 102.260742 22.512557, 107.490234 15.538376, 107.62207 12.254128, 104.414062 10.401378, 104.80957 7.972198, 109.555664 10.919618, 109.379883 15.411319, 106.259766 19.145168, 108.149414 21.043491))
Cambodia	POLYGON ((107.666016 14.477234, 102.919922 14.221789, 102.348633 13.667338, 103.535156 9.665738, 107.753906 12.46876, 107.666016 14.477234))
Laos	POLYGON ((101.953125 22.43134, 100.195312 20.509355, 100.898437 17.476432, 103.930664 18.312811, 105.556641 15.538376, 105.424805 14.221789, 107.753906 14.817371, 106.743164 16.636192, 101.953125 22.43134))
Thailand	POLYGON ((100.195312 20.550509, 97.558594 9.102097, 99.228516 6.402648, 102.304687 5.878332, 100.019531 10.487812, 102.480469 13.752725, 105.556641 14.604847, 103.798828 18.479609, 101.337891 17.727759, 100.195312 20.550509))
Myanmar Burma	POLYGON ((100.722656 21.043491, 97.998047 28.536275, 92.021484 21.289374, 94.658203 15.538376, 96.767578 16.299051, 97.998047 9.362353, 99.052734 11.609193, 97.382812 18.646245, 100.722656 21.043491))
Malaysia	POLYGON ((102.128906 6.577303, 100.019531 5.790897, 104.414062 0.35156, 104.941406 1.757537, 102.128906 6.577303))
Singapore	POLYGON ((104.238281 1.625758, 103.579102 1.680667, 103.414307 1.395126, 103.787842 1.076597, 104.39209 1.318243, 104.238281 1.625758))

----------- End of specific country WKT coordinates --------------------------

Your response must contain only valid SQL syntax or SQL comments. If you need to explain anything, do so within SQL comments.
`;