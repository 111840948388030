import { getUniqueSortedArray, Point2D } from "../utils";


type AutoCompleteJsonType = {
    name: string,
    normalized_date: number,
    count_local_normalized: number,
};

type QueryStormTracksJsonType = 
{
    signal_b_id: number,
    name: string,
    error: string,
    time: Date,
    longitude: number,
    latitude: number
};

type BaseStormTracksJsonType = 
{
    signal_id: number;
    name: string,
    time: Date,
    longitude: number,
    latitude: number
};


export type StormTracksQueryResult = 
{
    signal_id?: number;
    error?: string,
    time: Date,
    longitude: number,
    latitude: number
};

export type StormTracksQueryResults = Map<string, StormTracksQueryResult[]>;

export type SignalIdToNameMap = Map<number, string>;

type BaseBabyNamesJsonType = 
{
    signal_id: number,
    error?: string,
    name: string,
    sex: string,
    datetime: Date,
    count: number
};

export type BabyNamesQueryResult =
{
    signal_id: number,
    error?: string,
    sex: string,
    time: Date,
    count: number
}

export type BabyNamesQueryResults = Map<string, BabyNamesQueryResult[]>;
const PYTHON_URL = 'https://gpt-backend-604bf6e32f3c.herokuapp.com'; // localhost:8000

// TODO: actually use points in query
export const fetchAutocompleteLines = (points: Point2D[][]): Promise<Map<string, Point2D[]>> => {
    return new Promise(async (resolve, reject) => {
        console.log('fetchAutocompleteLines calling /autoCompletePoints');
    
        /*Format:
        [{
                "name": "Aaron",
                "normalized_date": 0.00710679611650485437,
                "count_local_normalized": 0.00613857506693659
            }, ...]
        */

        const response = await fetch('/autoCompletePoints');
        if (response.ok) {
            const json: AutoCompleteJsonType[] = await response.json();
            let result = new Map<string, Point2D[]>();
            json.forEach(row => {
                // skip the first two values 
                const currPoints = result.get(row.name);
                const newPoint = {x: row.normalized_date, y: row.count_local_normalized};
                if (currPoints) {
                    result.set(row.name, [...currPoints, newPoint])
                } else {
                    result.set(row.name, [newPoint]);
                }
            })
            // sort points to ensure that x is always increasing
            const compare = (a: Point2D, b: Point2D) => {
                if ( a.x < b.x ){
                  return -1;
                }
                if ( a.x > b.x ){
                  return 1;
                }
                return 0;
              }
            result.forEach((value: Point2D[]) => value.sort(compare));
            console.log(result);
            resolve(result);
        } else {
            console.error('Failed to set dataurl');
            reject();
        }
    });
} 


export const queryMinMaxStormTracksDateTime = () => {
    return new Promise(async (resolve, reject) => {
        console.log(`queryMinMaxStormTracksDateTime`);

        /*
        Format:
        [{"min":"1949-08-07T08:00:00.000Z","max":"2022-09-09T01:00:00.000Z"}]
        */
        const response = await fetch(`/minMaxStormDateTime`);
        if (response.ok) {
            console.log(response);
            resolve(response)
        } else {
            console.error('failed to get min/max storm tracks datetime');
            reject();
        }
    });
}


export const queryDendrogram = (tablePrefix: string) => {
    return new Promise(async (resolve, reject) => {
        console.log(`queryDendrogram`);

        const response = await fetch(`${PYTHON_URL}/dendrogram?tablePrefix=${tablePrefix}`);
        if (response.ok) {
            console.log(response);
            resolve(await response.json())
        } else {
            console.error('failed to get dendrogram');
            reject();
        }
    });
}

export const queryStormTracks = (selectedPoints: string, precision: number, penalties : number[]): Promise<StormTracksQueryResults> => {
    return new Promise(async (resolve, reject) => {
        /*
        Query response format:
        [{
            db_signal_id: 126,
            name: 'ISA',
            error: 1.942890293094024e-15,
            datetime: 1997-04-19T19:00:00.000Z,
            longitude_orig: 137.9,
            latitude_orig: 15.3
        }, ...]
        */
        const queryUrl = `/queryStormTracks?selectedPoints=${selectedPoints}&precision=${precision}&penalties=${penalties.join(",")}`;
        console.log(`querying storm tracks`);
        console.log(queryUrl);
        const response = await fetch(queryUrl);
        if (response.ok) {
            const json: QueryStormTracksJsonType[] = await response.json();
            let result = new Map<string, StormTracksQueryResult[]>();
            json.forEach(row => {
                // skip the first two values 
                const currPoints = result.get(row.name);
                const resultObj = {
                    error: row.error,
                    time: row.time,
                    longitude: row.longitude,
                    latitude: row.latitude
                } as StormTracksQueryResult;
                if (currPoints) {
                    result.set(row.name, [...currPoints, resultObj])
                } else {
                    result.set(row.name, [resultObj]);
                }
            })
            console.log('/queryStormTracks result');
            console.log(result);
            resolve(result);
        } else {
            console.error('Failed to query storm tracks');
            reject();
        }
    });
} 

export const getAllStormTracks = (): Promise<{signalIdToNameMap: SignalIdToNameMap, stormTracksQueryResults: StormTracksQueryResults}> => {
    return new Promise(async (resolve, reject) => {
        /*
        Query response format:
        [{
            name: 'ISA',
            time: 1997-04-19T19:00:00.000Z,
            longitude: 137.9,
            latitude: 15.3
        }, ...]
        */

        // wait for map to render before querying
        const queryUrl = '/allStormTracks';
        console.log(`fetching all storm tracks`);
        console.log(queryUrl);
        const response = await fetch(queryUrl);
        console.log(response);
        if (response.ok) {
            const json: BaseStormTracksJsonType[] = await response.json();
            let stormTracksQueryResults = new Map<string, StormTracksQueryResult[]>();
            let signalIdToNameMap = new Map<number, string>();
            json.forEach(row => {
                // skip the first two values 
                const currPoints = stormTracksQueryResults.get(row.name);
                const resultObj = {
                    signal_id: row.signal_id,
                    time: row.time,
                    longitude: row.longitude,
                    latitude: row.latitude
                } as StormTracksQueryResult;
                if (currPoints) {
                    stormTracksQueryResults.set(row.name, [...currPoints, resultObj])
                } else {
                    stormTracksQueryResults.set(row.name, [resultObj]);
                }

                // second map so we can recover a name from a signal id.
                signalIdToNameMap.set(row.signal_id, row.name);
            })
            console.log('/allStormTracks result');
            console.log(stormTracksQueryResults);
            resolve({signalIdToNameMap, stormTracksQueryResults});
        } else {
            console.error('Failed to query baby names');
            reject();
        }
    });
} 



export const queryBabyNames = (selectedPoints: string, precision: number, penalties: number[]): Promise<BabyNamesQueryResults> => {
    return new Promise(async (resolve, reject) => {
         /*
        Query response format:
        [{
            name: 'Aaron',
            datetime: 2021-01-01 00:00:00.00000Z,
            count: 4818
        }, ...]
        */

        const queryUrl = `/queryBabyNames?selectedPoints=${selectedPoints}&precision=${precision}&penalties=${penalties.join(",")}`;
        console.log(`querying baby Names`);
        console.log(queryUrl);
        const response = await fetch(queryUrl);
        if (response.ok) {
            const json: BaseBabyNamesJsonType[] = await response.json();
            let result = new Map<string, BabyNamesQueryResult[]>();
            json.forEach(row => {
                // skip the first two values 
                const currPoints = result.get(row.name);
                const resultObj = {
                    error: row.error,
                    sex: row.sex,
                    time: row.datetime,
                    count: row.count
                } as BabyNamesQueryResult;
                if (currPoints) {
                    result.set(row.name, [...currPoints, resultObj])
                } else {
                    result.set(row.name, [resultObj]);
                }
            })
            console.log('/queryBabyNames result');
            console.log(result);
            resolve(result);
        } else {
            console.error('Failed to query baby names');
            reject();
        }
    });
} 

export const getAllBabyNames = (): Promise<{babyNamesSignalIdToNameMap: SignalIdToNameMap, babyNamesQueryResults: BabyNamesQueryResults}> => {
    return new Promise(async (resolve, reject) => {
        /*
        Query response format:
        [{
            name: 'Aaron',
            datetime: 2021-01-01 00:00:00.00000Z,
            count: 4818
        }, ...]
        */
        const queryUrl = '/allBabyNames';
        console.log('fetching all baby names');
        console.log(queryUrl);
        const response = await fetch(queryUrl);
        if (response.ok) {
            const json: BaseBabyNamesJsonType[] = await response.json();
            let babyNamesQueryResults = new Map<string, BabyNamesQueryResult[]>();
            let babyNamesSignalIdToNameMap = new Map<number, string>();
            json.forEach(row => {
                // skip the first two values 
                const currPoints = babyNamesQueryResults.get(row.name);
                const resultObj = {
                    signal_id: row.signal_id,
                    sex: row.sex,
                    time: row.datetime,
                    count: row.count
                } as BabyNamesQueryResult;
                if (currPoints) {
                    babyNamesQueryResults.set(row.name, [...currPoints, resultObj])
                } else {
                    babyNamesQueryResults.set(row.name, [resultObj]);
                }

                // second map so we can recover a name from a signal id.
                babyNamesSignalIdToNameMap.set(row.signal_id, row.name);
            })
            console.log('/allBabyNames result');
            console.log();
            resolve({babyNamesSignalIdToNameMap, babyNamesQueryResults});
        } else {
            console.error('Failed to query storm tracks');
            reject();
        }
    });
} 

export const runQueryAgainstDatabase = (query: string): Promise<string[]> => {
    return new Promise(async (resolve, reject) => {
        const formData = new FormData();
        formData.append('query', query);

        console.log(formData);
        
        fetch('/runQuery', {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(json => {
            console.log(json);
            resolve(getUniqueSortedArray(json.map((row: any) => row.name)));
        })
        .catch(error => {
            console.error(`unable to run sql query generated by anthropic: ${error}`)
            resolve([]);
        })
    });
};