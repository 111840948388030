import React, { useContext, useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import "./LineChart.css";
import { BabyNamesQueryResults, getAllBabyNames, queryDendrogram } from "./ApiServices/DBApiService";
import { filterMapByKeyValues, getCanvasDimensions, getUniqueSortedArray, HSVtoRGB, throwIfNull } from "./utils";
import { AppContext } from "./AppContext";
import Overlay from "./Overlay";
import { computeDescendantCounts, getAllClusters, getClustersAtThreshold, parseDendrogramTree } from "./DendrogramUtils";
import ShapeFilterEditorWrapper from "./ShapeFilterEditorWrapper";
import { hiddenSketchAreaId } from "./CustomMap";
import { DialogFooter } from "./CustomDialog";

type LineChartProps = {
    id: string;
    showOverlay: boolean,
    overlayChildren: JSX.Element,
    drawLines: boolean,
    updateHeight?: (value: number) => void;
    lineChartHeight?: number;
};

export const lineChartMargin = { top: 20, right: 20, bottom: 50, left: 80 };

export const lineChartHeader = 'line-chart-header'

const LineChart: React.FC<LineChartProps> = ({id, showOverlay, overlayChildren, drawLines, updateHeight, lineChartHeight}) => {

    const {
        setCanvasWidth,
        setCanvasHeight,
        allBabyNameQueryResults,
        setAllBabyNameQueryResults,
        babyNamesSignalIdToNameMap,
        setBabyNamesSignalIdToNameMap,
        babyNamesDendrogram,
        setBabyNamesDendrogram,
        babyNamesDendrogramDescendantCounts,
        setBabyNamesDendrogramDescendantCounts,
        setAllBabyNamesDendrogramClusters,
        setBabyNamesDendrogramLevelCountsList,
        babyNamesDendrogramLevelCountsList,
        setBabyNamesDendrogramLevelIndex,
        babyNamesDendrogramLevelIndex,
        babyNameQueryResults,
        canvasWidth,
        canvasHeight,
        resultsFilterErrorList,
        resultsFilterErrorIndex,
        displayAllLinesOnViz,
        shapeFilterLibrary,
        currentShapeIndex
    } = throwIfNull(useContext(AppContext));

    const [height, setHeight] = useState<number | undefined>(lineChartHeight ?? undefined);
    const svgRef = useRef(null);
    const lineChartWrapperRef = useRef(null);
    const headerRef = useRef<HTMLDivElement>(null);

    const MALE = "M";
    const FEMALE = "F";

    const defaultCatColors = { male: "#0070C0", female: "#FD6320" };
    const mutedCatColors = { male: "#acb7bf", female: "#fcd9ca" };
    
    const [catColors, setCatColors] = useState(defaultCatColors);

    const defaultTextColor = "#000";
    const mutedTextColor = "#b8b8b8";
    const [textColor, setTextColor] = useState(defaultTextColor);

    const [annotationFilterNames, setAnnotationFilternames] = useState<string[]|undefined>(undefined)

    const width = canvasWidth;

    const {
        isLoadingBabyNameResults,
      } = throwIfNull(useContext(AppContext));

    useEffect(() => {
        if (showOverlay) {
            setCatColors(mutedCatColors);
            setTextColor(mutedTextColor);
        } else {
            setCatColors(defaultCatColors);
            setTextColor(defaultTextColor);
        }
    }, [showOverlay])

    useEffect(() => {
        // if there is a list of names to filter by from annotation parsing, filter by the list
        const annotationFilterNames = (!showOverlay && currentShapeIndex !== undefined && 
            currentShapeIndex < shapeFilterLibrary.length &&
            shapeFilterLibrary[currentShapeIndex].annotationParsingFilteredNames.length > 0) ?
            shapeFilterLibrary[currentShapeIndex].annotationParsingFilteredNames : undefined;
        setAnnotationFilternames(annotationFilterNames);
    }, [showOverlay, shapeFilterLibrary, currentShapeIndex])


    const updateCanvasDimensions = () => {
        const dimensions = getCanvasDimensions();
        console.log('set canvas dimensions');
        if (dimensions) {
            setCanvasWidth(dimensions.width);
            setCanvasHeight(dimensions.height);
        }
    };

    useEffect(() => {
        if (lineChartWrapperRef.current) {
            updateCanvasDimensions();
            window.addEventListener('resize', updateCanvasDimensions);
        }
    }, [])

    useEffect(() => {
        if (lineChartHeight === undefined && headerRef.current) {
            // height of viz should be the height of the container minus the height of the header
            setHeight(canvasHeight - headerRef.current.getBoundingClientRect().height);
            updateHeight?.(canvasHeight - headerRef.current.getBoundingClientRect().height);
        }
    }, [headerRef.current, canvasHeight]);

    useEffect(() => {
        if (!allBabyNameQueryResults && !babyNamesSignalIdToNameMap) {
            getAllBabyNames().then((response) => {
                setBabyNamesSignalIdToNameMap(response.babyNamesSignalIdToNameMap);
                setAllBabyNameQueryResults(response.babyNamesQueryResults);
            });
        }
        if (!babyNamesDendrogram && !babyNamesDendrogramDescendantCounts) {
          queryDendrogram("babynames_local_123").then((response: any) => {
            const dendroTree = parseDendrogramTree(response);
            console.log(dendroTree);
            setBabyNamesDendrogram(dendroTree);
            // imperically derived contents
            const allClusters = getAllClusters(dendroTree);
            setAllBabyNamesDendrogramClusters(allClusters);
            const descendantCounts = computeDescendantCounts(dendroTree);
            setBabyNamesDendrogramDescendantCounts(descendantCounts);
    
            // get list of all dendrogram levels except the very top one
            let dendrogramLevelCounts = getUniqueSortedArray(allClusters.map(cluster => cluster.dist));
            dendrogramLevelCounts.splice(-1);
            setBabyNamesDendrogramLevelCountsList(dendrogramLevelCounts);
            setBabyNamesDendrogramLevelIndex(dendrogramLevelCounts.length - 5);
          });
        }
    }, [babyNamesSignalIdToNameMap, allBabyNameQueryResults, babyNamesDendrogram,  babyNamesDendrogramDescendantCounts]);

    useEffect(() => {
        if (!babyNamesDendrogram || !babyNamesDendrogramLevelCountsList || !babyNamesSignalIdToNameMap || !allBabyNameQueryResults || !babyNamesDendrogramDescendantCounts) return;
        if (babyNameQueryResults && babyNameQueryResults.size > 0 && resultsFilterErrorIndex >= resultsFilterErrorList.length) return;
        if (!svgRef.current || !height) return;

        const stormIsUnderResultsError = (queryResults: BabyNamesQueryResults, name: string, maxError: number) => {
            return parseFloat(queryResults.get(name)?.at(0)?.error ?? "0") <= maxError;
        };

        const getFilteredResults = (queryResults: BabyNamesQueryResults, maxError: number): BabyNamesQueryResults => {
            return new Map(
                [...queryResults.entries()]
                .filter(([key, _]) => stormIsUnderResultsError(queryResults, key, maxError))
              )
        }

        // baby name clusters to be rendered
        const clusters = getClustersAtThreshold(babyNamesDendrogram, babyNamesDendrogramLevelCountsList[babyNamesDendrogramLevelIndex]);
        
        // baby name medoids of the clusters to be rendered
        const medoidNameToClusterIds = new Map<string, number>();
        clusters.forEach(cluster => medoidNameToClusterIds.set(throwIfNull(babyNamesSignalIdToNameMap.get(cluster.medoid)), cluster.id));

        // Choose dataset: prioritize `babyNameQueryResults` filter by resultsFilterError if available,
        // else use `allBabyNameQueryResults`
        let activeData: BabyNamesQueryResults = 
            (babyNameQueryResults && babyNameQueryResults.size > 0) ? 
                getFilteredResults(babyNameQueryResults, resultsFilterErrorList[resultsFilterErrorIndex]) :
                allBabyNameQueryResults;

        if (annotationFilterNames) {
            console.log('filtering active data bay annotation parsing list');
            console.log(annotationFilterNames);
            activeData = filterMapByKeyValues(activeData, annotationFilterNames);
        }

        if (medoidNameToClusterIds.size === 0) return; // Prevent empty chart

        // Define the data type explicitly
        type DataPoint = { date: Date; value: number; sex: string };

        // Process query results data: Each name has a list of time-based count entries
        const processedActiveData = Array.from(activeData.entries()).map(([name, values]) => ({
            name,
            data: values
                .map((entry: { time: string | Date; count: number; sex: string }) => ({
                    date: entry.time instanceof Date ? entry.time : new Date(entry.time),
                    value: entry.count,
                    sex: entry.sex
                }))
                .sort((a, b) => a.date.getTime() - b.date.getTime()),
        }));

        // Processed cluster data
        const processedClusterData = Array.from(medoidNameToClusterIds.keys()).map(name => {
            const babyNamePoints = throwIfNull(allBabyNameQueryResults.get(name));
            return {
                name,
                data: babyNamePoints
                    .map((entry: { time: string | Date; count: number; sex: string }) => ({
                        date: entry.time instanceof Date ? entry.time : new Date(entry.time),
                        value: entry.count,
                        sex: entry.sex
                    }))
                    .sort((a, b) => a.date.getTime() - b.date.getTime()),
            }
        });

        const svg = d3.select(svgRef.current);
        svg.selectAll('*').remove(); // Clear previous chart

        const xMin = d3.min([...processedActiveData.flatMap(dataset => dataset.data.map(d => d.date)),
            ...processedClusterData.flatMap(dataset => dataset.data.map(d => d.date))])!;

        const xMax = d3.max([...processedActiveData.flatMap(dataset => dataset.data.map(d => d.date)),
            ...processedClusterData.flatMap(dataset => dataset.data.map(d => d.date))])!;

        const yMax = d3.max([...processedActiveData.flatMap(dataset => dataset.data.map(d => d.value)),
            ...processedClusterData.flatMap(dataset => dataset.data.map(d => d.value))]) || 1;

        // Define scales
        const xScale = d3.scaleTime()
            .domain([xMin, xMax])
            .range([lineChartMargin.left, width - lineChartMargin.right]);

        const yScale = d3.scaleLinear()
            .domain([0, yMax])
            .nice()
            .range([(lineChartHeight ? lineChartHeight : height) - lineChartMargin.bottom, lineChartMargin.top]);

        const colorScale = d3.scaleOrdinal<string>()
            .domain([MALE, FEMALE])
            .range([catColors.male, catColors.female]);

        // Append axes
        svg.append('g')
            .attr('transform', `translate(0,${(lineChartHeight ? lineChartHeight : height) - lineChartMargin.bottom})`)
            .call(d3.axisBottom(xScale).tickFormat((domainValue) => {
                if (domainValue instanceof Date) {
                    return d3.timeFormat('%Y')(domainValue);
                }
                return '';
            }))
            .selectAll('text')
            .style('text-anchor', 'middle')
            .style('color', textColor);

        svg.append('g')
            .attr('transform', `translate(${lineChartMargin.left},0)`)
            .call(d3.axisLeft(yScale))
            .style('color', textColor);
        
        // Ensure tick lines are the right color
        svg.selectAll(".tick line")
            .style("stroke", textColor);

        if (!drawLines) return;

        // Define line generator
        const line = d3.line<DataPoint>()
            .x(d => xScale(d.date)!)
            .y(d => yScale(d.value)!)
            .curve(d3.curveMonotoneX);

        // Define the tooltip (outside the SVG)
        const tooltip = d3.select("body")
            .append("div")
            .style("position", "absolute")
            .style("background", "rgba(0, 0, 0, 0.8)")
            .style("color", "#fff")
            .style("padding", "6px 10px")
            .style("border-radius", "5px")
            .style("font-size", "12px")
            .style("pointer-events", "none")
            .style("opacity", 0); // Initially hidden

        const sortedDescendantCountsList = getUniqueSortedArray(Array.from(babyNamesDendrogramDescendantCounts.values()));

        const getStrokeProperties = (count: number): {width: number, color: string} => {
            const maxClusterWidth = 30;
            const defaultClusterWidth = 2;
            const defaultClusterValue = 0.90;
            const maxClusterValue = 0.70;

            // max count threshold for normalizing, use the second highest value
            const maxCountThreshold = sortedDescendantCountsList.at(sortedDescendantCountsList.length - 2);

            let width = ((count / (maxCountThreshold - 1)) * (maxClusterWidth - defaultClusterWidth) + defaultClusterWidth);
            let value = maxClusterValue - (count / maxCountThreshold) * (maxClusterValue - defaultClusterValue);

            // cluster colors: male hsv(205°, 0, value)
            const color = HSVtoRGB(205/360, 0, value)
            return {
                width: width,
                color: color
            };
        }

        // Draw clusters
        if (displayAllLinesOnViz) {
            processedClusterData.forEach(dataset => {
                const descendantCount = throwIfNull(babyNamesDendrogramDescendantCounts.get(throwIfNull(medoidNameToClusterIds.get(dataset.name))));
                const strokeProperties = getStrokeProperties(descendantCount);
                svg.append("path")
                    .datum(dataset.data)
                    .attr("fill", "none")
                    .attr("stroke", strokeProperties.color)
                    .attr("stroke-width", strokeProperties.width)
                    .attr("d", line);
            });
        }

        // // Draw lines with interaction
        processedActiveData.forEach(dataset => {
            svg.append("path")
                .datum(dataset.data)
                .attr("fill", "none")
                .attr("stroke", d => colorScale(d[0].sex) as string)
                .attr("stroke-width", 2)
                .attr("d", line);

            // Create an invisible path for better hover interaction
            svg.append("path")
                .datum(dataset.data)
                .attr("fill", "none")
                .attr("stroke", "transparent") // Invisible line for interaction
                .attr("stroke-width", 10) // Wider area for easier hover
                .attr("d", line)
                .on("mouseover", function () {
                    tooltip.style("opacity", 1); // Show tooltip
                })
                .on("mousemove", function (event) {
                    const [mouseX] = d3.pointer(event);
                    const bisectDate = d3.bisector((d: DataPoint) => d.date).left;
                    const x0 = xScale.invert(mouseX);
                    const index = bisectDate(dataset.data, x0, 1);
                    const selectedData = dataset.data[index - 1];

                    if (selectedData) {
                        tooltip
                            .html(`<strong>${dataset.name}</strong><br>Sex: ${selectedData.sex} <br> Year: ${d3.timeFormat('%Y')(selectedData.date)}<br>Count: ${selectedData.value}`)
                            .style("left", `${event.pageX + 10}px`)
                            .style("top", `${event.pageY - 20}px`);
                    }
                })
                .on("mouseout", function () {
                    tooltip.style("opacity", 0); // Hide tooltip on mouseout
                });
        });
    }, [drawLines, allBabyNameQueryResults, babyNameQueryResults, height, catColors, textColor, resultsFilterErrorList, resultsFilterErrorIndex,
        babyNamesDendrogram, babyNamesDendrogramLevelCountsList, babyNamesDendrogramLevelIndex, displayAllLinesOnViz, annotationFilterNames
    ]);

    return (
        <div ref={lineChartWrapperRef} className="line-chart-wrapper">
            {isLoadingBabyNameResults ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: canvasHeight }}>
                    <div className="spinner-container">
                        <div className="fancy-spinner"></div>
                        <div className="spinner-text">Loading baby name trends...</div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="line-chart" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div ref={headerRef} className={lineChartHeader}>
                            <h2 className="line-chart-title">Baby Name Trends over Time</h2>
                            <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px", color: textColor }}>
                                <span style={{ marginRight: "10px", fontWeight: "bold", position: "relative", top: "-1px" }}>Sex:</span>
                                <div style={{ display: "flex", alignItems: "center", marginRight: "15px" }}>
                                <div style={{ width: "15px", height: "15px", backgroundColor: catColors.male, marginRight: "5px" }}></div>
                                <span>Male</span>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ width: "15px", height: "15px", backgroundColor: catColors.female, marginRight: "5px" }}></div>
                                <span>Female</span>
                                </div>
                            </div>
                        </div>
                        <svg id={id} ref={svgRef} width={canvasWidth} height={lineChartHeight ? lineChartHeight : height}></svg>
                    </div>
                    {/* actual overlay used by the user */}
                    <Overlay
                        showOverlay={showOverlay}
                        overlayChildren={overlayChildren}
                    />

                    {/* for coordinate calculations */}
                    <div className={`overlay hidden-overlay`} style={{ visibility: "hidden" }}>
                        <div className="overlay-content-wrapper">
                            <ShapeFilterEditorWrapper
                                shapeFilterIndex={currentShapeIndex}
                                isInShapeLibrary={false}
                                canvasWidth={canvasWidth}
                                canvasHeight={canvasHeight}
                                canvasId={hiddenSketchAreaId}
                                includeControls={true}
                                handleClickMeasure={() => {}}
                                handleClickAnnotationColor={() => {}}
                                handleClickTextButton={() => {}}
                                handleClickEraserButton={() => {}}
                                displayMeasureNameLines={true}
                            >
                                <DialogFooter
                                enableSaveAs={false}
                                onRequestClear={() => { }}
                                onRequestCancel={() => { }}
                                onRequestSave={() => { }}
                                onRequestSaveAs={() => { }}
                                />
                            </ShapeFilterEditorWrapper>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default LineChart;
