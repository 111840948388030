import { useEffect, useRef, useContext, useImperativeHandle, forwardRef, useState } from "react";
import { AppContext, TextBox } from './AppContext';
import { ShapeFilterDefinition } from './ShapeFilterDefinition';
import { Point2D, throwIfNull } from "./utils";
import { cloneObjectArray, cloneShapeType } from "./CopyUtils";
import { lineChartHeader, lineChartMargin } from "./LineChart";
import { fetchSketchBlobFromLineChart } from "./LineChartWrapper";

const eraserBuffer = 0.075;
const selectionBuffer = 0.05;

// Define the type of methods to expose to the parent via ref
export interface SketchRef {
    clearCanvas: () => void;
    saveSketchAsPng: () => Promise<Blob | null>;
    canvas: HTMLCanvasElement | null;
}

class MouseAndTouchEventParameters {
    canvasElement: HTMLCanvasElement;
    x: number;
    y: number;

    constructor(canvasElement: HTMLCanvasElement, x: number, y: number) {
        this.canvasElement = canvasElement;
        this.x = x;
        this.y = y;
    }

    public ConvertClientToOffsetCoordinates(): MouseAndTouchEventParameters {
        const rect = this.canvasElement.getBoundingClientRect();
    
        this.x -= rect.left + window.scrollX;
        this.y -= rect.top + window.scrollY;
    
        // Flip the y-axis
        this.y = this.canvasElement.height - this.y;
    
        return this;
    }
}

let xStart: number, xEnd: number, yStart: number, yEnd: number;
let ctx: CanvasRenderingContext2D | null = null;
let lineThickness: number = 2;
let currentSketchId = 0;


// LineSegment interface to store normalized coordinates
export interface LineSegment {
    xStart: number; // normalized to canvas width
    yStart: number; // normalized to canvas height
    xEnd: number;   // normalized to canvas width
    yEnd: number;   // normalized to canvas height
    thickness: number;
    precision: number;
    color: string;
    sketchLineId: number;
}

function getDrawingAreaPaddingPercent (isMapMode: boolean | undefined, isInShapeLibrary: boolean, canvasWidth: number, canvasHeight: number) {
    let drawingAreaTopPaddingPercent;
    let drawingAreaBottomPaddingPercent;
    let drawingAreaLeftPaddingPercent;
    let drawingAreaRightPaddingPercent;
    if (isMapMode) {
        // maps use the whole drawing area
        drawingAreaTopPaddingPercent = 0;
        drawingAreaBottomPaddingPercent = 0;
        drawingAreaLeftPaddingPercent = 0;
        drawingAreaRightPaddingPercent = 0;
    } else if (isInShapeLibrary) {
        // add small padding for the shape library
        drawingAreaTopPaddingPercent = 0.15;
        drawingAreaBottomPaddingPercent = 0.15;
        drawingAreaLeftPaddingPercent = 0.15;
        drawingAreaRightPaddingPercent = 0.15;
    } else {
        // for the line chart, also subtract the space from the title/legend header at the top
        const titlePadding = document.querySelector(`.${lineChartHeader}`)?.clientHeight ?? 0;
        const topPadding = lineChartMargin.top + titlePadding;

        drawingAreaTopPaddingPercent = isMapMode ? 0 : topPadding / canvasHeight;
        drawingAreaBottomPaddingPercent = isMapMode ? 0 : lineChartMargin.bottom / canvasHeight;
        drawingAreaLeftPaddingPercent = isMapMode ? 0 : lineChartMargin.left / canvasWidth;
        drawingAreaRightPaddingPercent = isMapMode ? 0 : lineChartMargin.right / canvasWidth;
    }

    const drawingWidth = (1 - drawingAreaRightPaddingPercent - drawingAreaLeftPaddingPercent) * canvasWidth;
    const drawingHeight = (1 - drawingAreaTopPaddingPercent - drawingAreaBottomPaddingPercent) * canvasHeight;

    return {
        drawingAreaTopPaddingPercent,
        drawingAreaBottomPaddingPercent,
        drawingAreaLeftPaddingPercent,
        drawingAreaRightPaddingPercent,
        drawingWidth,
        drawingHeight
    }
}

// Function to normalize a point by the canvas dimensions
export function normalizePoint(isMapMode: boolean | undefined, isInShapeLibrary: boolean, x: number, y: number, canvasWidth: number, canvasHeight: number) {
    const {
        drawingWidth,
        drawingHeight,
        drawingAreaBottomPaddingPercent,
        drawingAreaLeftPaddingPercent,
    } = getDrawingAreaPaddingPercent(isMapMode, isInShapeLibrary, canvasWidth, canvasHeight);
    const leftPadding = drawingAreaLeftPaddingPercent * canvasWidth;
    const bottomPadding = drawingAreaBottomPaddingPercent * canvasHeight;
    return {
        x: (x - leftPadding) / drawingWidth,
        y: (y - bottomPadding) / drawingHeight,
    };
}

// Function to denormalize a point based on the current canvas size
export function denormalizePoint(isMapMode: boolean | undefined, isInShapeLibrary: boolean, x: number, y: number, canvasWidth: number, canvasHeight: number) {
    const {
        drawingWidth,
        drawingHeight,
        drawingAreaBottomPaddingPercent,
        drawingAreaLeftPaddingPercent,
    } = getDrawingAreaPaddingPercent(isMapMode, isInShapeLibrary, canvasWidth, canvasHeight);
    const leftPadding = drawingAreaLeftPaddingPercent * canvasWidth;
    const bottomPadding = drawingAreaBottomPaddingPercent * canvasHeight;
    return {
        x: (x * drawingWidth) + leftPadding,
        y: (y * drawingHeight) + bottomPadding,
    };
}

type SketchProps = {
    shapeFilterIndex: number|undefined;
    isInShapeLibrary: boolean;
    isAddingText?: boolean;
    canvasWidth: number,
    canvasHeight: number,
    showGuidelines: boolean;
    canvasId?: string;
    isMapMode?: boolean;
    displayMeasureNameLines: boolean;
    isActiveCanvas: boolean
}

export const Sketch = forwardRef<SketchRef, SketchProps>(({shapeFilterIndex, isInShapeLibrary, isAddingText, canvasWidth, canvasHeight, showGuidelines, canvasId, isMapMode, displayMeasureNameLines, isActiveCanvas}, ref) => {
    const {
        shapeFilterLibrary,
        updateShapeInLibrary,
        currentColor,
        currentMeasure,
        currentPrecision,
        listOfMeasures,
        eraserEnabled,
        isEditingExistingSketch,
        editingSketch,
        updateTextBoxesInLibrary,
        updateSketchBlobInLibrary,
        setIsAddingText,
    } = throwIfNull(useContext(AppContext));
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const linearizeButtonRef = useRef<HTMLButtonElement | null>(null);
    const shapeFilterLibraryRef = useRef(shapeFilterLibrary);
    const currentColorRef = useRef(currentColor);
    const currentMeasureRef = useRef(currentMeasure);
    const currentPrecisionRef = useRef(currentPrecision);
    const shapeFilterIndexRef = useRef(shapeFilterIndex);
    const updateShapeInLibraryRef = useRef(updateShapeInLibrary);
    const updateTextBoxesInLibraryRef = useRef(updateTextBoxesInLibrary);
    const updateSketchBlobInLibraryRef = useRef(updateSketchBlobInLibrary);
    const eraserEnabledRef = useRef(eraserEnabled);
    const isEditingExistingSketchRef = useRef(isEditingExistingSketch);
    const editingSketchRef = useRef(editingSketch);
    const isAddingTextRef = useRef(isAddingText);
    const shapeFilterItemRef = useRef(
            !isInShapeLibrary && isEditingExistingSketchRef.current && editingSketchRef.current ? 
                editingSketchRef.current : 
                    (shapeFilterIndexRef.current !== undefined) ? shapeFilterLibraryRef.current[shapeFilterIndexRef.current] : 
                        { 
                            annotationShapes: new Map(),
                            measureShapes: new Map(),
                            textBoxes: [],
                            map: {
                                isMapMode: isMapMode,
                                mapInfo: {
                                    coordinates: [],
                                    center: [],
                                    zoom: 2
                                },
                                mapBlob: undefined
                            },
                        }
        );

    const [paint, setPaint] = useState(false);
    const paintRef = useRef<boolean>(paint);
    useEffect(() => {
        paintRef.current = paint;
    }, [paint])


    // Sync the ref with the current shapeFilterLibraryRef.current
    useEffect(() => {
        shapeFilterLibraryRef.current = shapeFilterLibrary;
    }, [shapeFilterLibrary]);

    useEffect(() => {
        shapeFilterIndexRef.current = shapeFilterIndex;
    }, [shapeFilterIndex]);

    // Sync the ref with the current currentColorRef.currentRef.current
    useEffect(() => {
        currentColorRef.current = currentColor;
    }, [currentColor]);

    // Sync the ref with the current currentMeasureRef.currentRef.current
    useEffect(() => {
        currentMeasureRef.current = currentMeasure;
    }, [currentMeasure]);

    // Sync the ref with the current currentPrecisionRef.current
    useEffect(() => {
        currentPrecisionRef.current = currentPrecision;
    }, [currentPrecision]);

    // Sync the ref with the current shapeFilterLibraryRef.current
    useEffect(() => {
        updateShapeInLibraryRef.current = updateShapeInLibrary;
    }, [updateShapeInLibrary]);

    // Sync the ref with the current updateTextBoxesInLibraryRef.currentRef.current
    useEffect(() => {
        updateTextBoxesInLibraryRef.current = updateTextBoxesInLibrary;
    }, [updateTextBoxesInLibrary])

    useEffect(() => {
        updateSketchBlobInLibraryRef.current = updateSketchBlobInLibrary;
    }, [updateSketchBlobInLibrary])

    // Sync the ref with the current eraserEnabledRef.currentRef.current
    useEffect(() => {
        eraserEnabledRef.current = eraserEnabled;
    }, [eraserEnabled]);

    // Sync the ref with the current isEditingExistingSketchRef.currentRef.current
    useEffect(() => {
        isEditingExistingSketchRef.current = isEditingExistingSketch;
    }, [isEditingExistingSketch])

    // Sync the ref with the current editingSketchRef.currentRef.current
    useEffect(() => {
        editingSketchRef.current = cloneShapeType(editingSketch)
    }, [editingSketch]);

    useEffect(() => {
        isAddingTextRef.current = isAddingText;
    }, [isAddingText])

    useEffect(() => {
        if (shapeFilterIndex === undefined) return;
        const isEditingMode = !isInShapeLibrary && isEditingExistingSketch && editingSketch;
        const newShape = isEditingMode ? editingSketch : shapeFilterLibrary[shapeFilterIndex];
        console.log(shapeFilterLibrary);
        console.log(`setting new shape for ${canvasId} at index ${shapeFilterIndex} is blank=${newShape === undefined}`);
        if (newShape) {
            console.log(newShape);
            shapeFilterItemRef.current = cloneShapeType(newShape);
        }
    }, [isEditingExistingSketch, editingSketch, shapeFilterLibrary, shapeFilterIndex, isAddingText])


    /*const [displayPreview, setDisplayPreview] = useState<boolean>(
        !isInShapeLibrary && shapeFilterItemRef.current?.measureShapes.size === 0 && 
        shapeFilterItemRef.current?.annotationShapes.size === 0 && 
        shapeFilterItemRef.current?.textBoxes.length === 0);*/


    // Ref to track added event listeners
    const listenersRef = useRef<any>({
        mousedown: null,
        mouseup: null,
        mousemove: null,
        mouseLeave: null,
        touchstart: null,
        touchmove: null,
        touchend: null,
        keydown: null,
    });

    let fontSize = isInShapeLibrary && !shapeFilterItemRef.current.map.isMapMode ? 6 : 24;

    ///////////// Handle adding Event Listeners and Updating State and the Shape Library /////////////

    useEffect(() => {
        // Handle different events with type-safe methods
        const handleMouseEvent = (e: MouseEvent) => {    
            if (!ctx) return;
    
            const mouseParams = new MouseAndTouchEventParameters(e.currentTarget as HTMLCanvasElement, e.offsetX,e.offsetY);
            switch (e.type) {
                case "mousedown":
                    startDrawing(mouseParams);
                    break;
                case "mousemove":
                    if (paintRef.current) {
                        updateDrawing(new MouseAndTouchEventParameters(e.currentTarget as HTMLCanvasElement,
                                                                       e.offsetX,
                                                                       e.offsetY));
                    }
                    break;
                case "mouseup":
                case "mouseleave":
                    setPaint(false);
                    currentSketchId++; // move on to next sketch.

                    // disabling
                    //drawAutocompleteLines(e.currentTarget as HTMLCanvasElement);

                    break;
            }
        }
    
        // Handle different events with type-safe methods
        const handleTouchEvent = (e: TouchEvent) => {    
            if (!ctx) return;
    
            // Touch events don't have 'offsetX' and 'offsetY' like mouse events, so we have to do the math ourselves (below).
            switch (e.type) {
                case "touchstart":
                    e.preventDefault();
                    startDrawing(new MouseAndTouchEventParameters(e.currentTarget as HTMLCanvasElement,
                                                                    e.touches[0].clientX,
                                                                    e.touches[0].clientY).ConvertClientToOffsetCoordinates());
                    break;
                case "touchmove":
                    if (paintRef.current) {
                        updateDrawing(new MouseAndTouchEventParameters(e.currentTarget as HTMLCanvasElement,
                                                                        e.touches[0].clientX,
                                                                        e.touches[0].clientY).ConvertClientToOffsetCoordinates());
                    }
                    break;
                case "touchend":
                    setPaint(false);
                    currentSketchId++; // move on to next sketch.
                    //drawAutocompleteLines(e.currentTarget as HTMLCanvasElement);
                    break;
            }
        }

        /*
        const drawAutocompleteLines = (canvas: HTMLCanvasElement) => {
            if (!ctx) return;
            const currentShapeFilter:ShapeFilterDefinition = shapeFilterItemRef.current.measureShapes.get(currentMeasureRef.current) ?? new ShapeFilterDefinition(25, 0.35);
            const lineSegments = currentShapeFilter._lineSegments;
            if (lineSegments.length > 0) {
                const points = linearizeSketches(lineSegments);
                fetchAutocompleteLines(points).then(results => {
                    if (!ctx) return;
                    results.forEach((points: Point2D[]) => {
                        if (!ctx) return;
                        drawDashedLine(canvas, points, 0.01);
                    });
                });
            }
        }

        // Function to draw a dashed line for a polyline
        const drawDashedLine = (canvas: HTMLCanvasElement, linePoints: Point2D[], dashLength: number) => {
            if (linePoints.length === 0) return;
            const ctx = canvas.getContext("2d");
            if (!ctx) return;
            const strokeStyle = ctx.strokeStyle;
            const lineWidth = ctx.lineWidth;
            ctx.strokeStyle = getRandomColor();
            ctx.lineWidth = 3;
            ctx.beginPath();
            let drawing = true;
            let currentX = linePoints[0].x;
            let currentY = linePoints[0].y;
            let drawnPoints: number[][] = [];
            // Iterate over the points in the polyline
            for (let i = 1; i < linePoints.length - 1; i++) {
                const x2 = linePoints[i].x;
                const y2 = linePoints[i].y;
                const dx = x2 - currentX;
                const dy = y2 - currentY;
                const distance = Math.sqrt(dx * dx + dy * dy);
                if (distance > dashLength) {
                    // If the distance between points is greater than the dashLength then draw a line or skip a gap
                    if (drawing) {
                        // Denormalize coordinates for actual drawing
                        const startPoint = denormalizePoint(isMapMode, currentX, currentY, canvas.width, canvas.height);
                        const endPoint = denormalizePoint(isMapMode, x2, y2, canvas.width, canvas.height);
                        ctx.moveTo(startPoint.x, startPoint.y);
                        ctx.lineTo(endPoint.x, endPoint.y);
                        ctx.stroke();
                        
                        drawnPoints.push([startPoint.x, startPoint.y]);
                        drawnPoints.push([endPoint.x, endPoint.y]);
                        currentX = x2;
                        currentY = y2;
                    }
                    drawing = !drawing;  // Alternate between drawing and skipping
                }
            }
            // draw the ending dash if needed
            const endX = linePoints[linePoints.length - 1].x;
            const endY = linePoints[linePoints.length - 1].y;
            if (endX !== currentX || endY !== currentY) {
                ctx.moveTo(currentX, currentY);
                ctx.lineTo(endX, endY);
                ctx.stroke();
                drawnPoints.push([currentX, currentY]);
                drawnPoints.push([endX, endY]);
            }
            ctx.strokeStyle = strokeStyle;
            ctx.lineWidth = lineWidth;
        }*/

        // Add a new text box
        const addTextBox = (normalizedX: number, normalizedY: number) => {
            if (shapeFilterIndexRef.current === undefined) return;
            // ensure all other text boxes are inActive
            let textBoxes: TextBox[] = cloneObjectArray(shapeFilterItemRef.current.textBoxes);
            textBoxes.forEach(textBox => textBox.isActive = false);

            const textBox: TextBox = {
                x: normalizedX,
                y: normalizedY,
                text: '',
                isActive: true,
            };
            updateTextBoxesInLibraryRef.current([...textBoxes, textBox],  shapeFilterIndexRef.current)
            setIsAddingText(false);
        }

        // Handle typing - if there is an active text box, update its text
        const handleKeyDownEvent = (e: KeyboardEvent) => {
            if (shapeFilterIndexRef.current === undefined) return;
            console.log(`handleKeyDownEvent ${canvasId}`);
            let newTextBoxes: TextBox[] = cloneObjectArray(shapeFilterItemRef.current.textBoxes);
            const activeIdx = newTextBoxes.findIndex(box => box.isActive);
            if (activeIdx !== -1) {
                console.log(`handlekeydown ${newTextBoxes[activeIdx].text}`);
                const key = e.key;
                if (key === 'Backspace') {
                    newTextBoxes[activeIdx].text = newTextBoxes[activeIdx].text.slice(0,-1);
                    updateTextBoxesInLibraryRef.current(newTextBoxes,  shapeFilterIndexRef.current)
                } else {
                    let addedChar = '';
                    switch(e.key) {
                        case 'Enter':
                            addedChar = '\n';
                            break;
                        case 'Shift':
                            break;
                        default:
                            addedChar = key;
                            break;
                    }
                    newTextBoxes[activeIdx].text = newTextBoxes[activeIdx].text + addedChar;
                    updateTextBoxesInLibraryRef.current(newTextBoxes, shapeFilterIndexRef.current)
                }
            }
        }

        // Start drawing for mouse events, storing normalized coordinates
        const startDrawing = (params: MouseAndTouchEventParameters) => {
            if (shapeFilterIndexRef.current === undefined) return;
            //setDisplayPreview(false);
            const { x, y } = normalizePoint(isMapMode, isInShapeLibrary, params.x, params.y, params.canvasElement.width, params.canvasElement.height);
            
            if (isAddingTextRef.current) {
                addTextBox(x, y);
            } else {
                xStart = x;
                yStart = y;
                xEnd = x;
                yEnd = y;
                setPaint(true);
                if (!eraserEnabledRef.current && !currentColorRef.current) {
                    enableTextBox(params);
                } else if (eraserEnabledRef.current) {
                    // eraser
                    const eraseLinesResult = eraseLineSegments();
                    const textBoxes = filterTextBoxes();
                    if (eraseLinesResult.measureLineSegments || eraseLinesResult.annotationLineSegments || textBoxes) {
                        if (!eraseLinesResult) return;
                        updateShapeInLibraryRef.current(
                            shapeFilterIndexRef.current,
                            eraseLinesResult.measureLineSegments,
                            eraseLinesResult.annotationLineSegments,
                            textBoxes
                        );
                    }
                } else {
                    updateLineSegments("mousedown", params.canvasElement);
                }
            }
        }

        // Update drawing for mouse move events with normalized coordinates
        const updateDrawing = (params: MouseAndTouchEventParameters) => {
            if (shapeFilterIndexRef.current === undefined) return;
            const { x, y } = normalizePoint(isMapMode, isInShapeLibrary, params.x, params.y, params.canvasElement.width, params.canvasElement.height);
            xEnd = x;
            yEnd = y;

            if (ctx) ctx.lineWidth = lineThickness;
            
            if (eraserEnabledRef.current) {
                // eraser
                const eraseLinesResult = eraseLineSegments();
                const textBoxes = filterTextBoxes();
                if (eraseLinesResult.measureLineSegments || eraseLinesResult.annotationLineSegments || textBoxes) {
                    if (!eraseLinesResult) return;
                    updateShapeInLibraryRef.current(
                        shapeFilterIndexRef.current,
                        eraseLinesResult.measureLineSegments,
                        eraseLinesResult.annotationLineSegments,
                        textBoxes
                    );
                }
            } else if (currentColorRef.current){
                // drawing
                updateLineSegments("mousemove", params.canvasElement);
            } else {
                // moving text boxes around
                updateTextBoxes();
            }

            // Update start points for the next segment
            xStart = xEnd;
            yStart = yEnd;
        }

        // Returns true if the line from {x1,y1} to {x2,y2} is within maxDistance from a point
        const lineIsWithinDistanceOfPoint = (maxDistance: number, eraserX: number, eraserY: number, x1: number, y1: number, x2: number, y2: number): boolean => {
            var A = eraserX - x1;
            var B = eraserY - y1;
            var C = x2 - x1;
            var D = y2 - y1;
          
            var dot = A * C + B * D;
            var len_sq = C * C + D * D;
            var param = -1;
            if (len_sq !== 0) //in case of 0 length line
                param = dot / len_sq;
          
            var xx, yy;
          
            if (param < 0) {
              xx = x1;
              yy = y1;
            }
            else if (param > 1) {
              xx = x2;
              yy = y2;
            }
            else {
              xx = x1 + param * C;
              yy = y1 + param * D;
            }
          
            var dx = eraserX - xx;
            var dy = eraserY - yy;
            return Math.sqrt(dx * dx + dy * dy) < maxDistance;
        }

        // Removes erased line segments from the given mapOfSegments 
        const setFilteredLineSegments = (mapOfSegments: Map<string,ShapeFilterDefinition>) => {
            mapOfSegments.forEach((value, key) => {
                const originalLineSegments = value._lineSegments;
                if (originalLineSegments.length === 0) return [];

                value._lineSegments = originalLineSegments.filter(segment => {
                    return !(
                        lineIsWithinDistanceOfPoint(eraserBuffer, xStart, yStart, segment.xStart, segment.yStart, segment.xEnd, segment.yEnd) ||
                        lineIsWithinDistanceOfPoint(eraserBuffer, xEnd, yEnd, segment.xStart, segment.yStart, segment.xEnd, segment.yEnd)
                    );
                });
            });
        }

        // Return a list of line segments with the segments within eraserBuffer distance of the eraser filtered out
        // Returns undefined if there are no changes
        // TODO: support more than one measure and annotation
        const eraseLineSegments = (): {
            measureLineSegments: {
                measureName: string,
                shape: ShapeFilterDefinition
            } | undefined, 
            annotationLineSegments: {
                color: string,
                shape: ShapeFilterDefinition
            } | undefined
        } => {
            if (shapeFilterIndexRef.current === undefined) return {measureLineSegments: undefined, annotationLineSegments: undefined};
            if (!ctx) return {measureLineSegments: undefined, annotationLineSegments: undefined};

            // measures
            let measuresShapeFilters:Map<string,ShapeFilterDefinition> = shapeFilterItemRef.current.measureShapes;
            let oldMeasureLength = 0;
            measuresShapeFilters.forEach(item => oldMeasureLength += item._lineSegments.length);
            setFilteredLineSegments(measuresShapeFilters);
            let newCurrentMeasuresShapeFilter:ShapeFilterDefinition = measuresShapeFilters.values().next().value ?? new ShapeFilterDefinition(25, 0.35);
            const newMeasureLength = newCurrentMeasuresShapeFilter._lineSegments.length;
            const shouldUpdateMeasures = oldMeasureLength - newMeasureLength > 0;
            
            // annotations
            let annotationsShapeFilters:Map<string,ShapeFilterDefinition> = shapeFilterItemRef.current.annotationShapes;
            let oldAnnotationLength = 0;
            annotationsShapeFilters.forEach(item => oldAnnotationLength += item._lineSegments.length);
            setFilteredLineSegments(annotationsShapeFilters);
            let newCurrentAnnotationsShapeFilter:ShapeFilterDefinition = annotationsShapeFilters.values().next().value ?? new ShapeFilterDefinition(25, 0.35);
            const newAnnotationLength = newCurrentAnnotationsShapeFilter._lineSegments.length;
            const shouldUpdateAnnotations = oldAnnotationLength - newAnnotationLength > 0;
            
            const measureName = measuresShapeFilters.keys().next().value;
            const annotationColor = annotationsShapeFilters.keys().next().value;
            return {
                measureLineSegments: shouldUpdateMeasures && measureName ? 
                {
                    measureName: measureName,
                    shape: newCurrentMeasuresShapeFilter
                 } : undefined,
                annotationLineSegments: shouldUpdateAnnotations && annotationColor ? 
                {
                    color: annotationColor,
                    shape: newCurrentAnnotationsShapeFilter
                }: undefined,
            }
        }

        // returns the normalized points for the four corners of the given text box
        const getNormalizedTextBoxPoints = (textBox: TextBox) => {
            if (!ctx) return;
            const drawingPoint = denormalizePoint(isMapMode, isInShapeLibrary, textBox.x, textBox.y, canvasWidth, canvasHeight);
            const textWidth = ctx.measureText(textBox.text).width;
            const textHeight = fontSize * 1.2;

            const topLeft = {x: textBox.x, y: textBox.y}
            const bottomRight = normalizePoint(isMapMode, isInShapeLibrary, drawingPoint.x + textWidth, drawingPoint.y - textHeight, canvasWidth, canvasHeight);
            const topRight = normalizePoint(isMapMode, isInShapeLibrary, drawingPoint.x + textWidth, drawingPoint.y, canvasWidth, canvasHeight);
            const bottomLeft = normalizePoint(isMapMode, isInShapeLibrary, drawingPoint.x, drawingPoint.y - textHeight, canvasWidth, canvasHeight);

            return {topLeft, bottomRight, topRight, bottomLeft};
        }
        
        // Filters text boxes to remove the ones within eraserBuffer distance of the eraser, returns undefined if there are no changes
        const filterTextBoxes = () => {
            if (shapeFilterIndexRef.current === undefined) return;
            const oldTextBoxLength = shapeFilterItemRef.current.textBoxes.length;
            const newTextBoxes = [...shapeFilterItemRef.current.textBoxes].filter(textBox => {
                if (!ctx) return false;
                const {topLeft, bottomRight, topRight, bottomLeft} = {...getNormalizedTextBoxPoints(textBox)};
            
                if (topLeft && bottomRight && topRight && bottomLeft) {
                    // keep text box if its diagonals are outside the eraser buffer distance
                    return !(lineIsWithinDistanceOfPoint(eraserBuffer, xStart, yStart, topLeft.x, topLeft.y, bottomRight.x, bottomRight.y) ||
                    lineIsWithinDistanceOfPoint(eraserBuffer, xStart, yStart, topRight.x, topRight.y, bottomLeft.x, bottomLeft.y));
                }
                return false;
            });
            const newTextBoxLength = newTextBoxes.length;
            return oldTextBoxLength - newTextBoxLength > 0 ? newTextBoxes : undefined;
        }

        // If there is a text box within the MouseDown point, enable it
        const enableTextBox = (params: MouseAndTouchEventParameters) => {
            if (shapeFilterIndexRef.current === undefined) return;
            const newTextBoxes = cloneObjectArray(shapeFilterItemRef.current.textBoxes);
            const clickedTextBoxIdx = newTextBoxes.findIndex((textBox: TextBox) => {
                if (!ctx) return true;
                const {topLeft, bottomRight, topRight, bottomLeft} = {...getNormalizedTextBoxPoints(textBox)};
                const { x, y } = normalizePoint(isMapMode, isInShapeLibrary, params.x, params.y, params.canvasElement.width, params.canvasElement.height);

                // return true if click is within selectio n buffer
                return topLeft && bottomRight && topRight && bottomLeft && 
                    x >= (topLeft.x - selectionBuffer) && x <= (topRight.x + selectionBuffer) && y <= (topLeft.y + selectionBuffer) && y >= (bottomLeft.y - selectionBuffer);
            });
            newTextBoxes.forEach((textBox: TextBox, index: number) => {
                if (index === clickedTextBoxIdx) {
                    textBox.isActive = true;
                } else {
                    textBox.isActive = false;
                }
            })
            updateTextBoxesInLibraryRef.current(newTextBoxes, shapeFilterIndexRef.current);
        }

        // Update text boxes if moving them around on the canvas
        const updateTextBoxes = () => {
            if (shapeFilterIndexRef.current === undefined) return;
            const newTextBoxes = shapeFilterItemRef.current.textBoxes;
            // find the text box that was moved
            const draggedTextBoxIdx = newTextBoxes.findIndex(textBox => {
                if (!ctx) return true;
                const {topLeft, bottomRight, topRight, bottomLeft} = {...getNormalizedTextBoxPoints(textBox)};

                // return true if start point is within selection buffer
                return topLeft && bottomRight && topRight && bottomLeft && 
                    xStart >= (topLeft.x - selectionBuffer) && xStart <= (topRight.x + selectionBuffer) && yStart <= (topLeft.y + selectionBuffer) && yStart >= (bottomLeft.y - selectionBuffer);
            });
            // if a text box was moved, move it
            if (draggedTextBoxIdx !== -1) {
                newTextBoxes.forEach((textBox, index) => {
                    if (!ctx) return;
                    const {topLeft, topRight} = {...getNormalizedTextBoxPoints(textBox)};
                    if (topLeft && topRight && index === draggedTextBoxIdx) {
                        const normalizedTextWidth = topRight.x - topLeft.x;
                        textBox.isActive = true;
                        textBox.x = xEnd - normalizedTextWidth/2;
                        textBox.y = yEnd;
                        return;
                    } else {
                        textBox.isActive = false;
                    }
                })
            }
            updateTextBoxesInLibraryRef.current(newTextBoxes, shapeFilterIndexRef.current);
        }
 
        // Draw function with denormalization to render points on the canvas
        const updateLineSegments = (event: string, canvas: HTMLCanvasElement) => {
            if (shapeFilterIndexRef.current === undefined) return;
            // Add the new segment to the array (stored in normalized format)
            const lineSegment = {
                xStart,
                yStart,
                xEnd,
                yEnd,
                thickness: lineThickness,
                color: currentColorRef.current,
                sketchLineId: currentSketchId,
                precision: currentPrecisionRef.current
            };

            // update library
            let measureFilter = undefined;
            let annotationFilter = undefined;
            if (isDrawingMeasure(currentColorRef.current)) {
                measureFilter = shapeFilterItemRef.current.measureShapes.get(currentMeasureRef.current) ?? new ShapeFilterDefinition(25, 0.35);
                measureFilter._lineSegments = [...measureFilter._lineSegments, lineSegment];
            } else {
                annotationFilter = shapeFilterItemRef.current.annotationShapes.get(currentColorRef.current) ?? new ShapeFilterDefinition(25, 0.35);
                annotationFilter._lineSegments = [...annotationFilter._lineSegments, lineSegment];
            }

            updateShapeInLibraryRef.current(
                shapeFilterIndexRef.current,
                measureFilter ? {measureName: currentMeasureRef.current, shape: measureFilter} : undefined,
                annotationFilter ? {color: currentColorRef.current, shape: annotationFilter} : undefined
            );

            // Update start points for the next segment
            xStart = xEnd;
            yStart = yEnd;
        }

        const isDrawingMeasure = (color: string) => {
            return listOfMeasures.find(measure => measure.color === color);
        }

        const canvas = canvasRef.current;
        
        // Helper function to add an event listener and track it
        const addListener = (eventType: any, handleEvent: any) => {
            const canvas = canvasRef.current as HTMLCanvasElement;

            if (listenersRef.current[eventType]) return; // Prevent duplicate listeners
            listenersRef.current[eventType] = handleEvent;
            canvas.addEventListener(eventType, handleEvent);
        };
    
        const removeListener = (eventType: string) => {
            const canvas = canvasRef.current as HTMLCanvasElement;

            if (listenersRef.current[eventType]) {
                canvas.removeEventListener(eventType, listenersRef.current[eventType]);
                listenersRef.current[eventType] = null;
            }
        };

        if (canvas && shapeFilterItemRef.current !== undefined) {
            // Initialize canvas context
            ctx = canvas.getContext("2d");
            if (ctx) {
                ctx.lineJoin = "round";
                ctx.lineCap = "round";
            } else {
                console.error("Canvas context is not available.");
            }

            if (isActiveCanvas && shapeFilterIndexRef.current !== undefined && shapeFilterLibraryRef.current.length > 0 && !listenersRef.current.mousedown) {
                console.log(`adding event listeners for ${canvasId}`)
                // Bind each event separately to avoid TypeScript type issues
                addListener("mousedown", handleMouseEvent);
                addListener("mousemove", handleMouseEvent);
                addListener("mouseup", handleMouseEvent);
                addListener("mouseleave", handleMouseEvent);
                addListener("touchstart", handleTouchEvent);
                addListener("touchmove", handleTouchEvent);
                addListener("touchend", handleTouchEvent);
                addListener("keydown", handleKeyDownEvent);
            }
        } else {
            if (isActiveCanvas) {
                console.log(`removing event listeners from ${canvasId}`)
                // Bind each event separately to avoid TypeScript type issues
                removeListener("mousedown");
                removeListener("mousemove");
                removeListener("mouseup");
                removeListener("mouseleave");
                removeListener("touchstart");
                removeListener("touchmove");
                removeListener("touchend");
                removeListener("keydown");
            }
        }


    }, [shapeFilterLibraryRef.current, 
        shapeFilterIndexRef.current, 
        currentColorRef.current, 
        currentMeasureRef.current, 
        eraserEnabledRef.current, 
        isAddingTextRef.current, 
        shapeFilterItemRef.current, 
        updateShapeInLibraryRef.current,
        updateTextBoxesInLibraryRef.current,
        paintRef.current
    ]);






    ///////////////////     Handle Drawing //////////////////////

    
    // Draw a circle with center {x,y} and the given radius and color
    const drawCircle = (ctx: any, x: number, y: number, radius: number, color: string, shadow?: {color: string, offsetX: number, offsetY: number, blurRadius: number}) => {
        if (shadow) {
            ctx.shadowOffsetX = shadow.offsetX; // Horizontal offset of the shadow (positive moves right)
            ctx.shadowOffsetY = shadow.offsetY; // Vertical offset of the shadow (positive moves down)
            ctx.shadowBlur = shadow.blurRadius;    // Blur radius of the shadow
            ctx.shadowColor = shadow.color; // Color of the shadow
        }

        ctx.beginPath();
        ctx.arc(x, y, radius, 0, Math.PI * 2);
        ctx.fillStyle = color;
        ctx.fill();
    };


    const getLineThickness = (isInShapeLibrary: boolean, precision: number) => {
        if (!isInShapeLibrary || shapeFilterItemRef.current.map.isMapMode) {
            return 5 + 0.01 * (100 - precision) * (100 - precision);
        }
        return 2 + 0.005 * (100 - precision) * (100 - precision);
    }

    const getLineOpacity = (precision: number) => {
        return Math.min(1, 0.15 + precision/100 * precision/100);
    }

    // Draw the given line segments
    const drawLine = (isMapMode: boolean = false, isInShapeLibrary: boolean, ctx: any, points: Point2D[], precision: number, color: string) => {
        if (points.length === 0) return;
        // Denormalize coordinates for actual drawing
        //const startPoint = denormalizePoint(isMapMode, line.xStart, line.yStart, canvasWidth, canvasHeight);
        //const drawnPoint = denormalizePoint(isMapMode, line.xEnd, line.yEnd, canvasWidth, canvasHeight);

        ctx.lineWidth = getLineThickness(isInShapeLibrary, precision);
        const opacity = getLineOpacity(precision);

        const strokeStyle = ctx.strokeStyle;
        ctx.strokeStyle = `rgba(${color},${opacity})`;

        const denormalizedPoints = points.map(point => denormalizePoint(isMapMode, isInShapeLibrary, point.x, point.y, canvasWidth, canvasHeight));

        if (denormalizedPoints.length === 2) {
            // If only two points, just draw a straight line
            ctx.beginPath();
            ctx.moveTo(denormalizedPoints[0].x, denormalizedPoints[0].y);
            ctx.lineTo(denormalizedPoints[1].x, denormalizedPoints[1].y);
            ctx.stroke();
        } else {
            ctx.beginPath();
            ctx.moveTo(denormalizedPoints[0].x, denormalizedPoints[0].y);
            // for three or more points use quadratic curve
            for (var i = 1; i < denormalizedPoints.length - 2; i++) {
                var xc = (denormalizedPoints[i].x + denormalizedPoints[i + 1].x) / 2;
                var yc = (denormalizedPoints[i].y + denormalizedPoints[i + 1].y) / 2;
                ctx.quadraticCurveTo(denormalizedPoints[i].x, denormalizedPoints[i].y, xc, yc);
            }
            
            // curve through the last two points
            ctx.quadraticCurveTo(
                denormalizedPoints[i].x,
                denormalizedPoints[i].y,
                denormalizedPoints[i + 1].x,
                denormalizedPoints[i + 1].y
            );
            ctx.stroke();
        }

        /* 
        // draw points
        denormalizedPoints.forEach(p => {
            ctx.beginPath();
            ctx.arc(p.x, p.y, 2, 0, Math.PI * 2);
            ctx.fillStyle = 'red';
            ctx.fill();
        })*/

        // reset styling
        ctx.strokeStyle = strokeStyle;
    };

    const drawArrowhead = (ctx: any, p1: Point2D, p2: Point2D, color: string, isMapMode: boolean = false, isInShapeLibrary: boolean, precision: number) => {
        let arrowSize = 1.2 * getLineThickness(isInShapeLibrary, precision)

        // Calculate the angle of the line from p1 to p2
        const angle = Math.atan2(p2.y - p1.y, p2.x - p1.x);
    
        // Calculate the points for the arrowhead
        const arrowAngle = Math.PI / 3; // 30 degrees for the arrowhead
        const arrowLength = arrowSize;
    
        // Points for the arrowhead
        const arrowHead1 = {
            x: p2.x - arrowLength * Math.cos(angle - arrowAngle),
            y: p2.y - arrowLength * Math.sin(angle - arrowAngle)
        };
        const arrowHead2 = {
            x: p2.x - arrowLength *  Math.cos(angle + arrowAngle),
            y: p2.y - arrowLength * Math.sin(angle + arrowAngle)
        };
        const arrowTip = {
            x: p2.x + arrowLength * Math.cos(angle),
            y: p2.y + arrowLength * Math.sin(angle)
        }

        const drawTriangle = () => {
            ctx.beginPath();
            ctx.moveTo(arrowTip.x, arrowTip.y); // Starting point of the triangle (tip of the arrow)
            ctx.lineTo(arrowHead1.x, arrowHead1.y); // First point of the triangle
            ctx.lineTo(arrowHead2.x, arrowHead2.y); // Second point of the triangle
            ctx.closePath(); // Close the path to form a triangle
            ctx.fill(); // Fill the triangle with the current fill color
        }
        
        // create gradient for the triangle
        const fillStyle = ctx.fillStyle;
        const opacity = getLineOpacity(precision); // Opacity should be a little darker than the line
        ctx.fillStyle = `rgba(${color},${opacity})`;

        // erase the part of the line that overlaps with the triangle
        const globalCompositeOperation = ctx.globalCompositeOperation;
        ctx.globalCompositeOperation = "destination-out"; // set blend mode
        for (let i = 0; i < 5; i++) {
            drawTriangle();
        }

        // draw actual triangle
        ctx.globalCompositeOperation = globalCompositeOperation;
        drawTriangle();
        

        // reset styling
        ctx.fillSTyle = fillStyle;
    }

    // add text
    const writeText = (ctx: any, textBox: TextBox) => {
        const drawingPoint = denormalizePoint(isMapMode, isInShapeLibrary, textBox.x, textBox.y, canvasWidth, canvasHeight);
        ctx.font = `bold ${fontSize}px Arial`;
        ctx.textAlign = 'left';
        ctx.textBaseline = 'top';
        ctx.fillStyle = 'black';  // a color name or by using rgb/rgba/hex values
        ctx.fillText(textBox.text, drawingPoint.x, -1 * drawingPoint.y); // text and position, y position is negative due to scaling
            
        // add box around the active text box in the dialog
        if (textBox.isActive) {
            const textWidth = ctx.measureText(textBox.text).width;
            const textHeight = fontSize * 1.2;
            
            if (!isInShapeLibrary) {
                ctx.strokeStyle = 'blue';
                ctx.lineWidth = 2;
                ctx.strokeRect(drawingPoint.x, -1 * drawingPoint.y, textWidth, textHeight);
            }
        }
    }

    useEffect(() =>  {
        const draw = () => {
            const canvas = canvasRef.current as HTMLCanvasElement;
            if (canvas && shapeFilterItemRef.current) {
                //console.log(shapeFilterItemRef.current);
                const ctx = canvas.getContext("2d");
                if (!ctx) {
                    console.error("Canvas context is not available.");
                    return;
                }

                // ensure canvas is the right height
                canvas.width = canvasWidth;
                canvas.height = canvasHeight;

                // clear canvas to start from scratch
                ctx.clearRect(0, 0, canvasWidth, canvasHeight);

                if (isMapMode) {
                    ctx.shadowColor = "rgb(255,255,255)";
                    ctx.shadowBlur = 0;
                    ctx.shadowOffsetX = 0;
                    ctx.shadowOffsetY = 0;
                }

                // draw four circles at the corners of the active area.
                /*if (!isInShapeLibrary && ctx.canvas.width > 500) {
                    console.log(getDrawingAreaPaddingPercent(isMapMode, isInShapeLibrary, canvasWidth, canvasHeight));
                    const {
                        drawingAreaTopPaddingPercent,
                        drawingAreaBottomPaddingPercent,
                        drawingAreaLeftPaddingPercent,
                        drawingAreaRightPaddingPercent
                    }  = getDrawingAreaPaddingPercent(isMapMode, isInShapeLibrary, canvasWidth, canvasHeight);

                    const offsetTop = canvasHeight * drawingAreaTopPaddingPercent;
                    const offsetBottom = canvasHeight * drawingAreaBottomPaddingPercent;
                    const offsetLeft = canvasWidth * drawingAreaLeftPaddingPercent;
                    const offsetRight = canvasWidth * drawingAreaRightPaddingPercent;


                    const circleRadius = 2;
                    const circleColor = "rgb(56, 56, 56)";
                    // Top-left corner
                    drawCircle(ctx, offsetLeft, canvas.height - offsetTop, circleRadius, "red");
                    // Top-right corner
                    drawCircle(ctx, canvas.width - offsetRight, canvas.height - offsetTop, circleRadius, "orange");
                    // Bottom-left corner
                    drawCircle(ctx, offsetLeft, offsetBottom, circleRadius, "purple");
                    // Bottom-right corner
                    drawCircle(ctx, canvas.width - offsetRight, offsetBottom, circleRadius, "green");
                }*/

                // draw an example of a sketch. Once the user starts drawing this will disappear
                /*if (showGuidelines && displayPreview && !isMapMode) {
                    //precision is always consistent for preview
                    simplifyLineSegments(sampleMeasureSegments, 0.005).forEach(lineSegments => {
                        lineSegments.forEach(lineSegment => {
                            if (lineSegments.length === 0) return;
                            let points: Point2D[] = lineSegments.map(lineSegment => {return {x: lineSegment.xStart, y: lineSegment.yStart}});
                            const lastSegment = lineSegments[lineSegments.length - 1];
                            points.push({x: lastSegment.xEnd, y: lastSegment.yEnd});
                            drawLine(isMapMode, isInShapeLibrary, ctx, points, lineSegments[0].precision, lineSegment.color);
                        });
                    });
                    simplifyLineSegments(sampleAnnotationSegments, 0.001).forEach(lineSegments => {
                        lineSegments.forEach(lineSegment => {
                            if (lineSegments.length === 0) return;
                            let points: Point2D[] = lineSegments.map(lineSegment => {return {x: lineSegment.xStart, y: lineSegment.yStart}});
                            const lastSegment = lineSegments[lineSegments.length - 1];
                            points.push({x: lastSegment.xEnd, y: lastSegment.yEnd});
                            drawLine(isMapMode, isInShapeLibrary, ctx, points, lineSegments[0].precision, lineSegment.color);
                        });
                    });
                    
                    // add slightly opaque layer on top so lines aren't so dark
                    const fillStyle = ctx.fillStyle;
                    ctx.fillStyle = "rgba(255,255,255,0.85)";
                    ctx.fillRect(0,0,canvasWidth,canvasHeight);
                    ctx.fillStyle = fillStyle;
                }
                
                // draw guiding dots in the background of the sketching area
                if (showGuidelines && !isInShapeLibrary) {
                    const distance = 50;
                    const circleColor = "rgba(0, 0, 0, 0.1)";
                    for (let w = 25; w < canvasWidth; w += distance ){
                        for (let h = 15 + distance; h < canvasHeight - distance; h += distance) {
                            drawCircle(ctx, w, h, 2, circleColor);
                        }
                    }
                };*/

                //console.log(`currentPrecisionRef.current: ${currentPrecisionRef.current}`);
                //console.log(shapeFilterItemRef.current);

                if (displayMeasureNameLines) {
                    // draw measure lines, and arrowheads in front of each segment line if in the overlay/dialog 
                    shapeFilterItemRef.current.measureShapes.forEach(shape => {
                        const lineSegments = shape.GetLineSegments();
                        const linearizationSegments = simplifyLineSegments(lineSegments, 0.005);
                        //console.log(linearizationSegments);
                        linearizationSegments.forEach(lineSegments => {
                            if (lineSegments.length === 0) return;
                            let points: Point2D[] = lineSegments.map(lineSegment => {return {x: lineSegment.xStart, y: lineSegment.yStart}});
                            const lastSegment = lineSegments[lineSegments.length - 1];
                            points.push({x: lastSegment.xEnd, y: lastSegment.yEnd});
                            drawLine(isMapMode, isInShapeLibrary, ctx, points, lineSegments[0].precision, lineSegments[0].color);
                        });
                        linearizationSegments.forEach(lineSegments => {
                            if (lineSegments.length >= 1) {
                                const lastSegment = lineSegments[lineSegments.length - 1];
                                if (lastSegment) {
                                    const p1Denormalized = denormalizePoint(isMapMode, isInShapeLibrary, lastSegment.xStart, lastSegment.yStart, canvas.width, canvas.height); 
                                    const p2Denormalized = denormalizePoint(isMapMode, isInShapeLibrary, lastSegment.xEnd, lastSegment.yEnd, canvas.width, canvas.height); 
                                    drawArrowhead(ctx, p1Denormalized, p2Denormalized, lastSegment.color, isMapMode, isInShapeLibrary, lastSegment.precision);
                                }
                            }
                        })
                    });
                }

                // draw annotations
                shapeFilterItemRef.current.annotationShapes.forEach(shape => {
                    shape.GetLineSegments().forEach((lineSegment: any) => {
                        const points = [{x: lineSegment.xStart, y: lineSegment.yStart}, {x: lineSegment.xEnd, y: lineSegment.yEnd}];
                        // precision is always 100 for annotations
                        drawLine(isMapMode, isInShapeLibrary, ctx, points, 100, lineSegment.color);
                    });
                });


                // draw textboxes
                let textBoxes: TextBox[] = shapeFilterItemRef.current.textBoxes;
                console.log(textBoxes);
                // scale to make sure text is in the correct direction
                ctx.scale(1, -1);
                textBoxes.forEach(textBox => {
                    console.log(`${canvasId} - ${textBox.text}`);
                    writeText(ctx, textBox)
                });
                // restore scale
                ctx.scale(1,-1);


                // draw circle around eraser if enabled
                if (eraserEnabledRef.current && paintRef.current && !isInShapeLibrary) {
                    console.log('drawing eraser circle');
                    const drawingWidth = getDrawingAreaPaddingPercent(isMapMode, isInShapeLibrary, canvasWidth, canvasHeight).drawingWidth;
                    const drawnPoint = denormalizePoint(isMapMode, isInShapeLibrary, xEnd, yEnd, canvasWidth, canvasHeight);
                    drawCircle(ctx, drawnPoint.x, drawnPoint.y, eraserBuffer / 3 * drawingWidth, "white", { color: 'white', offsetX: 0, offsetY: 0, blurRadius: eraserBuffer / 3 * drawingWidth});
                }

                // scale down if in shape library
                if (isInShapeLibrary) {
                    var cw=canvas.width;
                    var ch=canvas.height;
                    const tempCanvas = document.createElement("canvas");
                    tempCanvas.width=cw;
                    tempCanvas.height=ch;
                    const tempCtx = tempCanvas.getContext("2d");
                    tempCtx?.drawImage(canvas,0,0);
                    const scaleRatio = 300 / canvasWidth;
                    canvas.width = 300;
                    canvas.height *= scaleRatio;
                    ctx.drawImage(tempCanvas, 0, 0, cw, ch, 0, 0, 300, ch * scaleRatio);
                }
            }
        }

        draw();
    }, [shapeFilterLibraryRef.current,
        shapeFilterIndexRef.current,
        isEditingExistingSketchRef.current,
        editingSketchRef.current,
        //displayPreview,
        shapeFilterItemRef.current,
        paintRef.current
    ]);
        

    useEffect(() => {
        if (linearizeButtonRef) {
            linearizeButtonRef.current?.addEventListener("click", handleLinearizeButtonClick)
        }
    }, [])
    
    // Add event listener for the Linearize button
    const handleLinearizeButtonClick = () => {
        console.log("Linearize button clicked."); // Debug statement to confirm button click
        let measureShapes = shapeFilterItemRef.current.measureShapes;
        measureShapes.forEach((shape: ShapeFilterDefinition) => {
            const linearizationPoints = linearizeSketches(shape._lineSegments);
            console.log(linearizationPoints);
            drawLinearization(linearizationPoints);
        })
    }

    const drawLinearization = (segments: Point2D[][]) => {
        const canvas = canvasRef.current as HTMLCanvasElement;
        if (canvas) {
            const ctx = canvas.getContext("2d");
            if (!ctx) {
                console.error("Canvas context is not available.");
                return;
            }

            segments.forEach(segmentPoints => {
                const denormalizedPoints = segmentPoints.map(point => {
                    return denormalizePoint(isMapMode, isInShapeLibrary, point.x, point.y, canvas.width, canvas.height);
                });

                // Draw simplified line overlay
                ctx!.lineWidth = 10; 
                ctx!.strokeStyle = "rgba(173, 216, 230, 0.7)"; 

                ctx!.beginPath();
                ctx!.moveTo(denormalizedPoints[0].x, denormalizedPoints[0].y);
                for (let i = 1; i < denormalizedPoints.length; i++) {
                    ctx!.lineTo(denormalizedPoints[i].x, denormalizedPoints[i].y);
                }
                ctx!.stroke();
            })

            // Restore the original sketch color
            ctx.strokeStyle = currentColorRef.current ?? "#000000";
        }
    }

    // Function to save the sketch as a PNG
    const saveSketchAsPng = async (): Promise<Blob | null> => {
        return new Promise((resolve, reject) => {
            console.log('saveSketchAsPng');
            const canvas = canvasRef.current;
            if (canvas) {
                const ctx = canvas.getContext("2d");
                if (ctx) {
                    // Create a temporary canvas to avoid affecting the original canvas
                    const tempCanvas = document.createElement("canvas");
                    const tempCtx = tempCanvas.getContext("2d");

                    // Set the dimensions of the temporary canvas
                    tempCanvas.width = canvasWidth;
                    tempCanvas.height = canvasHeight;

                    tempCtx?.save(); // Save the current context state

                    // attempt to add map background for maps, or axis for lilne charts
                    if (tempCtx) {
                        try {
                            if (isMapMode && shapeFilterItemRef.current?.map.mapBlob) {
                                const mapHeight = document.querySelector('.map-wrapper')?.clientHeight ?? canvasHeight;
                                console.log(`resizeHeight: ${canvasWidth}, ${mapHeight}`);
                                createImageBitmap(shapeFilterItemRef.current.map.mapBlob, {resizeHeight: mapHeight, resizeWidth: canvasWidth}).then(imageBitmap=>{
                                    tempCtx.globalCompositeOperation = 'source-over';
                                    tempCtx.drawImage(imageBitmap, 0, 0, canvasWidth, mapHeight);
                                    // wait for map to render before adding sketch
                                    setTimeout(() => {
                                        tempCtx?.scale(1,-1);
                                        tempCtx?.drawImage(canvas, 0,0, canvasWidth, -1 * canvasHeight);
                                        tempCtx?.restore(); // restore scale before generating png 
                                        setTimeout(() => {
                                            return addSketchAndGeneratePng(tempCtx, tempCanvas, canvas, resolve);
                                        }, 500);
                                    }, 500);
                                })
                            } else {
                                fetchSketchBlobFromLineChart().then(response => {
                                    console.log(`${response.width}, ${response.height}`);
                                    tempCtx.drawImage(response.image, 0, canvasHeight - response.height, response.width, response.height);

                                    // wait for axis to render before adding sketch
                                    setTimeout(() => {
                                        tempCtx?.scale(1,-1);
                                        tempCtx?.drawImage(canvas, 0, 0, canvasWidth, -1 * canvasHeight);
                                        tempCtx?.restore(); // restore scale before generating png
                                        setTimeout(() => {
                                            return addSketchAndGeneratePng(tempCtx, tempCanvas, canvas, resolve);
                                        }, 500);
                                    }, 500);
                                    //})
                                })
                            }
                        } catch (e) {
                            console.log(`failed to add map or axis to sketch blob: ${e}`);
                        }
                    } else {
                        // default to just the sketch
                        console.log("doesn't meet conditions to add map or axis background to sketch blob");
                        return addSketchAndGeneratePng(tempCtx, tempCanvas, canvas, resolve);
                    }
                } else {
                    console.error("Failed to get canvas context.");
                    resolve(null);
                    return null;
                }
            } else {
                console.error("Canvas element not found.");
                resolve(null);
                return null;
            }
        });
    };

    const addSketchAndGeneratePng = (tempCtx: any, tempCanvas: HTMLCanvasElement, canvas: HTMLCanvasElement, parentResolve: any): Promise<Blob | null> => {
        // Generate a PNG Blob from the temporary canvas
        return new Promise<Blob | null>((childResolve, childReject) => {
            tempCanvas.toBlob(
                (blob) => {
                    if (blob) {
                        // Trigger download
                        //const link = document.createElement("a");
                        //link.href = URL.createObjectURL(blob);
                        //link.download = "sketch_afteraddingbackground.png";
                        //link.click();

                        updateSketchBlobInLibraryRef.current(blob, throwIfNull(shapeFilterIndexRef.current));
                        console.log(blob);
                        parentResolve(blob);
                        childResolve(blob);
                    } else {
                        console.error("Failed to create image blob.");
                        parentResolve(null);
                        childResolve(null);
                    }
                },
                "image/png"
            );
        });
    }

    // Function to clear the canvas
    const clearCanvas = () => {
        if (!ctx) {
            console.error("Canvas context is not available.");
            return;
        }

        // Clear the canvas
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    }

    // Use useImperativeHandle to expose clearCanvas to the parent component
    useImperativeHandle(ref, () => ({
        clearCanvas,
        saveSketchAsPng,
        canvas: canvasRef.current
    }));

    return (
        <div className="sketch-canvas">
            <canvas ref={canvasRef} tabIndex={-1} className="canvas" id={canvasId ? canvasId : `canvas-${shapeFilterIndexRef.current}`} width={canvasWidth} height={canvasHeight}></canvas>
            <div id="sketch-controls">
            {/*<button onClick={saveSketchAsPng}>Save as PNG</button>*/}
            </div>
        </div>
    );
});

function douglasPeucker(points: Point2D[], tolerance: number): Point2D[] {
    if (points.length < 3) return points;

    const start = points[0];
    const end = points[points.length - 1];
    let maxDistance = 0;
    let index = 0;

    for (let i = 1; i < points.length - 1; i++) {
        const distance = perpendicularDistance(points[i], start, end);
        if (distance > maxDistance) {
            index = i;
            maxDistance = distance;
        }
    }

    if (maxDistance > tolerance) {
        const left = douglasPeucker(points.slice(0, index + 1), tolerance);
        const right = douglasPeucker(points.slice(index), tolerance);
        return [...left.slice(0, -1), ...right];
    } else {
        return [start, end];
    }
}

function perpendicularDistance(point: { x: number; y: number }, lineStart: { x: number; y: number }, lineEnd: { x: number; y: number }) {
    const num = Math.abs(
        (lineEnd.y - lineStart.y) * point.x -
        (lineEnd.x - lineStart.x) * point.y +
        lineEnd.x * lineStart.y -
        lineEnd.y * lineStart.x
    );
    const den = Math.sqrt(
        (lineEnd.y - lineStart.y) ** 2 +
        (lineEnd.x - lineStart.x) ** 2
    );
    return num / den;
}


function simplifyLineSegments(lineSegments: LineSegment[], epsilon:number): LineSegment[][] {    
    const lineSegmentsBySketchLineId = new Map<number, LineSegment[]>();

    // Group line segments by sketchLineId
    for (const segment of lineSegments) {
        const key = segment.sketchLineId;
        if (!lineSegmentsBySketchLineId.has(key)) {
            lineSegmentsBySketchLineId.set(key, []);
        }
        lineSegmentsBySketchLineId.get(key)!.push(segment);
    }

    const simplifiedLineSegments: LineSegment[][] = [];

    // Process each group
    lineSegmentsBySketchLineId.forEach((segments: LineSegment[], sketchLineId: number) => {
        const points: Point2D[] = [];

        // Build the polyline from line segments
        for (const segment of segments) {
            const startPoint: Point2D = { x: segment.xStart, y: segment.yStart };
            const endPoint: Point2D = { x: segment.xEnd, y: segment.yEnd };

            // Avoid duplicate consecutive points
            if (
                points.length === 0 ||
                points[points.length - 1].x !== startPoint.x ||
                points[points.length - 1].y !== startPoint.y
            ) {
                points.push(startPoint);
            }

            if (startPoint.x !== endPoint.x || startPoint.y !== endPoint.y) {
                points.push(endPoint);
            }
        }

        // Apply Douglas-Peucker algorithm to simplify points
        const simplifiedPoints = douglasPeucker(points, epsilon);

        // Reconstruct simplified line segments
        const simplifiedSegments: LineSegment[] = [];
        for (let i = 0; i < simplifiedPoints.length - 1; i++) {
            const start = simplifiedPoints[i];
            const end = simplifiedPoints[i + 1];
            const originalSegment = segments[0]; // Assumes uniform properties

            simplifiedSegments.push({
                xStart: start.x,
                yStart: start.y,
                xEnd: end.x,
                yEnd: end.y,
                thickness: originalSegment.thickness,
                color: originalSegment.color,
                sketchLineId: sketchLineId,
                precision: originalSegment.precision
            });
        }

        simplifiedLineSegments.push(simplifiedSegments);
    }
    );

    return simplifiedLineSegments;
}

function convertLineSegmentsToPoints(lineSegments: LineSegment[][]): Point2D[][] {
    const pointsArray: Point2D[][] = [];

    for (const segmentGroup of lineSegments) {
        const points: Point2D[] = [];

        // Add the start point of the first segment in the group
        if (segmentGroup.length > 0) {
            const firstSegment = segmentGroup[0];
            points.push({ x: firstSegment.xStart, y: firstSegment.yStart });
        }

        // Add the end points of each segment in the group
        for (const segment of segmentGroup) {
            points.push({ x: segment.xEnd, y: segment.yEnd });
        }

        pointsArray.push(points);
    }

    return pointsArray;
}


export const linearizeSketches = (lineSegments: LineSegment[], epsilon = 0.04): Point2D[][] => {
    const peuckerizedSegments:Point2D[][] = convertLineSegmentsToPoints(simplifyLineSegments(lineSegments, epsilon));
    return peuckerizedSegments;
}

export default Sketch;