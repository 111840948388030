import React, { useContext, useEffect, useRef } from "react";
import { DialogFooter } from "./CustomDialog";
import ShapeFilterEditorWrapper from "./ShapeFilterEditorWrapper";
import { activeCanvasId, AppContext, MeasureType, Mode, ShapeType } from "./AppContext";
import ShapeLibrary from "./ShapeLibrary";
//import Chat from "./Chat";
import CustomTabs from "./CustomTabs";
import { linearizeSketches, SketchRef } from "./Sketch";
import { generatedPostgreSQLQueryIsValid, getCanvasDimensions, getUniqueSortedArray, Point2D, sleep, throwIfNull } from "./utils";

import { ShapeFilterDefinition } from "./ShapeFilterDefinition";
import { hiddenSketchAreaId, loadMapSourceAndLayerForSketch} from "./CustomMap";
import {  queryStormTracks, queryBabyNames, runQueryAgainstDatabase, StormTracksQueryResults, BabyNamesQueryResults } from "./ApiServices/DBApiService";

import "./Layout.css";
import "./InputSliders.css"
import MapWrapper from "./MapWrapper";
import sketchQLLogo from "./sketchQL_logo.png";
import { analyzeAnnotationsWithAnthropic } from "./ApiServices/GPTApiService";
//import { sample_annotation_response_maps, sample_annotation_response_babynames } from "./SampleAnnotationResponse";
import LineChartWrapper from "./LineChartWrapper";
import AdvancedMenu from "./AdvancedMenu";



const Layout: React.FC = () => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [currentTab, setCurrentTab] = React.useState(0);

  const sketchRef = useRef<SketchRef>(null);
  const sketchCanvasForBlobRef = useRef<SketchRef>(null);
  const context = useContext(AppContext);
  const { modeKey } = useContext(AppContext)!;

  const {
    currentShapeIndex,
    canvasWidth,
    canvasHeight,
    addShapeFilterToLibrary,
    updateLibraryForClearButton,
    updateSketchInLibrary,
    removeShapeFilterFromLibrary,
    shapeFilterLibrary,
    currentMeasure,
    currentColor,
    setCurrentColor,
    setCurrentShapeIndex,
    updateCurrentMeasure,
    setEraserEnabled,
    isEditingExistingSketch,
    editingSketch,
    disableEditing,
    isAddingText,
    setIsAddingText,
    deactivateAllTextBoxesInLibrary,
    setAnnotationParsingFilteredNamesInLibrary,
    
    isMapMode,
    setIsMapModeValue,
    mapRef,
    mapOnlySketchRef,

    //setSelectedStorm,

    displayedCanvasRef,
    setBabyNameQueryResults,
    babyNameQueryResults,
    stormQueryResults,
    setStormQueryResults,
    displaySketchOnViz,
    //setDisplaySketchOnViz,
    setDisplayAllLinesOnViz,
    displayAllLinesOnViz,

    dendrogramLevelIndex,
    setDendrogramLevelIndex,
    dendrogramLevelCountsList,

    babyNamesDendrogramLevelIndex,
    setBabyNamesDendrogramLevelIndex,
    babyNamesDendrogramLevelCountsList,

    resultsFilterErrorIndex,
    setResultsFilterErrorIndex,

    resultsFilterErrorList,
    setResultsFilterErrorList,

    setResultBlob,

    getCurrentPenalties,

    eraserEnabled,

    setIsLoadingBabyNameResults,
    isLoadingBabyNameResults,
    setIsLoadingStormResults,
    isLoadingStormResults
  } = throwIfNull(context);

  const stormQueryResultsRef = useRef(stormQueryResults);
  useEffect(() => {
    stormQueryResultsRef.current = stormQueryResults;
  }, [stormQueryResults])

  const isMapModeRef = useRef(isMapMode);
  useEffect(() => {
    isMapModeRef.current = isMapMode;
  }, [isMapMode])

  const handleClearButton = () => {
    if (shapeFilterLibrary.length === 0 || currentShapeIndex === undefined) return;
    sketchRef.current?.clearCanvas();
    if (isMapMode) {
      //setSelectedStorm('');
      setStormQueryResults(undefined);
    } else {
      setBabyNameQueryResults(undefined);
    }
    setDisplayAllLinesOnViz(false);
    //setDisplaySketchOnViz(false);
    updateLibraryForClearButton(currentShapeIndex);
  };

  const handleCancelButton = () => {
    if (shapeFilterLibrary.length === 0 || currentShapeIndex === undefined) return;
    if (isEditingExistingSketch) {
      disableEditing();
    } else {
      removeShapeFilterFromLibrary(currentShapeIndex);
    }
    setEraserEnabled(false);
    setIsAddingText(false);
    setDialogOpen(false);
  };

  const handleClickMeasure = (measure: MeasureType) => {
    if (shapeFilterLibrary.length === 0 || currentShapeIndex === undefined) return;
    const label = measure.measureName.get(modeKey) ?? Mode.Baby_Trends;

    setEraserEnabled(false);
    setIsAddingText(false);
    deactivateAllTextBoxesInLibrary();
    if (currentMeasure === label && currentColor === measure.color) {
      // if measure is already enabled, disable it
      setCurrentColor("");
    } else {
      // if measure is not enabled, enable it
      setCurrentColor(measure.color);
      updateCurrentMeasure(label);
    }
  };

  const handleClickAnnotationColor = (color: string) => {
    if (shapeFilterLibrary.length === 0 || currentShapeIndex === undefined) return;
    setEraserEnabled(false);
    setIsAddingText(false);
    deactivateAllTextBoxesInLibrary();
    if (currentColor === color) {
      // if annotation color is already enabled, disable it
      setCurrentColor("");
    } else {
      setCurrentColor(color);
    }
  };

  const handleClickEraserButton = () => {
    setCurrentColor("");
    deactivateAllTextBoxesInLibrary();
	  setIsAddingText(false);
    setEraserEnabled(!eraserEnabled);
  };

  const handleClickTextButton = () => {
    if (shapeFilterLibrary.length === 0 || currentShapeIndex === undefined) return;
    if (isAddingText) {
      // if already enabled, disable it
      setIsAddingText(false);
    } else {
      deactivateAllTextBoxesInLibrary();
      setCurrentColor("");
      setEraserEnabled(false);
      setIsAddingText(true);
    }
  }

  const handleSaveButton = async () => {
    if (shapeFilterLibrary.length === 0 || currentShapeIndex === undefined) return;
    setDialogOpen(false);
    // start the spinners
    if (isMapModeRef.current) {
      setIsLoadingStormResults(true);
    } else {
      setIsLoadingBabyNameResults(true);
    }
    if (isEditingExistingSketch) {
      updateSketchInLibrary(editingSketch, currentShapeIndex);
      const sketchBlob = await sketchCanvasForBlobRef.current?.saveSketchAsPng();
      handleShapeQueries(currentShapeIndex, editingSketch, sketchBlob);
      await sleep(2000);
      disableEditing();
    } else {
      const sketchBlob = await sketchCanvasForBlobRef.current?.saveSketchAsPng();
      handleShapeQueries(currentShapeIndex, shapeFilterLibrary[currentShapeIndex], sketchBlob);
    }
  };

  const handleSaveAsButton = async() => {
    if (shapeFilterLibrary.length === 0 || currentShapeIndex === undefined) return;
    setDialogOpen(false);
    // start the spinners
    if (isMapModeRef.current) {
      setIsLoadingStormResults(true);
    } else {
      setIsLoadingBabyNameResults(true);
    }
    if (isEditingExistingSketch) {
      const sketchBlob = await sketchCanvasForBlobRef.current?.saveSketchAsPng();
      const newIndex = addShapeFilterToLibrary(editingSketch);
      setCurrentShapeIndex(newIndex);
      disableEditing();
      handleShapeQueries(newIndex, editingSketch, sketchBlob);
    } else {
      const sketchBlob = await sketchCanvasForBlobRef.current?.saveSketchAsPng();
      handleShapeQueries(currentShapeIndex, shapeFilterLibrary[currentShapeIndex], sketchBlob);
    }
  };

  // handle querying sketch
  const handleShapeQueries = async (currentIndex: number, shapes: ShapeType, sketchBlob: Blob | null | undefined) => {
    console.log('handleShapeQueries');

    //
    // Set up our promises
    // We will collect these as we go, then resolve them all in the correct order at the bottom.
    /*
    Assumption 1: The viable scenarios are Anthropic alone, Map Alone, Anthropic + Map, Line Alone, Anthropic + Line
    Assumption 2: Map and Line will never run together
    Assumption 3: Anthropic can run in parallel with the other 2 but it's results must be handled before the results of the other two
    
    The basic design pattern is like this:
    1. Three default promise variables are defined up front, all defaulted to Promise.reject().
    2. Three promise handler variables are defined up front, all defaulted to void no-op.

    3. At various stages in the code, we (potentially) kick off the Anthropic, Map, and/or Line async calls.
    4. We collect the async return values into the promise variables defined above. Now we have a handle to them and they are running (in parallel), but they have not been handled yet.
    5. At each promise-collection point, we define a handler for that promise.  We do it here because the local variables have to be in the closure of the lambda function.

    6. At the end, we call Promise.allSettled() with the three promises.  This gives us a single promise which will return with the results of all three async calls.
    
    7. We handle that one aggregate Promise with a single 'then()' call which handles the results in the correct order, respecting that some of the asyc calls may
    have been rejects.

    Note for people watching in the debug console.
    When we overwrite the default-reject promises with the 'live' promises, the default-reject promise object still exists in memory
    and has already failed because it is coded to reject immediately.  However, nobody is watching that object anymore because the pointer has been pointed to the
    live promise sent back by the async functions.  As a result, the JS engine feels obliged to tell us that there is an orphaned rejected promise object staggering around failing.
    So if you are using, say, annotation, when you (correctly) assign the anthropicPromise to the async return value, the original anthropicPromise object will
    become abandoned and the JS engine will print that failure message out to the console.
    So, in a nutshell: if you see failure message for exactly the promises you think should *not* fail, it's probably ok.
    The console will print out the promise.allSettled status at the end and you can check your correctness there.
    */
    

    // Note: this is the promise for the async SQL call that *runs* the anthropic-generated query, not the anthropic annotation-parsing query itself.
    // This is necessary because the annotation-parsing query and the sql execution of the generated query must be run in sequence, you can't parallelize them.
    let anthropicSqlResultsPromise: Promise<string[]> = Promise.reject<string[]>(
      "Anthropic SQL Results: Default rejection error message"
    );
    let anthropicSqlResultsPromiseHandler = (filteredNames:string[]) => {};

    let mapPromise: Promise<StormTracksQueryResults> = Promise.reject<StormTracksQueryResults>(
      "Maps: Default rejection error message"
    );
    let mapsPromiseHandler = (results:StormTracksQueryResults) => {};

    let linesPromise: Promise<BabyNamesQueryResults> = Promise.reject<BabyNamesQueryResults>(
      "Lines: Default rejection error message"
    );
    let linesPromiseHandler = (results:BabyNamesQueryResults) => {};

    // start the spinners
    if (isMapModeRef.current) {
      setIsLoadingStormResults(true);
    } else {
      setIsLoadingBabyNameResults(true);
    }

    // we use this to skip those and cut right to the promise handling at the end if there is no arrow query.
    let executeMapAndLineQueries:boolean = true;

    // only run against db if there are annotations or text
    let annotationPointsCount = 0;
    shapes.annotationShapes.forEach(shape => annotationPointsCount += shape._lineSegments.length);

    let textBoxCharCount = 0;
    shapes.textBoxes.forEach(textBox => textBoxCharCount += textBox.text.length);
    if (sketchBlob &&  ((shapes.annotationShapes.size > 0 && annotationPointsCount > 0) || textBoxCharCount > 0)) {
      //
      // ******* ANTHROPIC *******
      // grab the anthropic sql results promise for handling later
      console.log("Setting up anthropic promise.");
      anthropicSqlResultsPromise = analyzeAnnotationsWithAnthropic(sketchBlob, isMapModeRef.current)
        .then((generatedAnnotationQuery: string) => {
          if (generatedPostgreSQLQueryIsValid(generatedAnnotationQuery, isMapModeRef.current)) {
            return runQueryAgainstDatabase(generatedAnnotationQuery);
          }
          else {
            // return the rejected promise as something clearly went wrong.
            return Promise.reject<string[]>("Anthropic sql was invalid");
          }
        }
        )
        .catch((reason: any) => {
          return Promise.reject<string[]>("Anthropic annotation parsing failed.");
        });

      anthropicSqlResultsPromiseHandler = (filteredNames: string[]) => {
        console.log('filterednames');
        console.log(filteredNames)
        setAnnotationParsingFilteredNamesInLibrary(filteredNames, currentIndex);
      };
      //
      //
      //
    }

    if (shapes.measureShapes.size === 0) {
      if (isMapModeRef.current) {
        setStormQueryResults(new Map());
      } else {
        setBabyNameQueryResults(new Map());
      }
      executeMapAndLineQueries = false;
    }

    if (executeMapAndLineQueries) {
      // Shapes.measureShapes in a vector, but right now we only handle one.  Put this in a foreach if you really want that functionality.
      const shapeFilterDefinition: ShapeFilterDefinition = throwIfNull(shapes.measureShapes.values().next().value);
      const segments = shapeFilterDefinition.GetLineSegments();
      const linearizedSegments = linearizeSketches(segments);
      console.log('linearizedSegments');
      console.log(linearizedSegments);
      if (linearizedSegments.length === 0) {
        console.log('empty linearizedSegments list');
        if (isMapModeRef.current) {
          setStormQueryResults(new Map());
        } else {
          setBabyNameQueryResults(new Map());
        }
        executeMapAndLineQueries = false;
      }

      if (executeMapAndLineQueries) {
        if (isMapModeRef.current && mapRef.current && mapOnlySketchRef.current && displayedCanvasRef.current && shapes.map.mapInfo.coordinates?.length === 4) {
          // handle querying maps
          loadMapSourceAndLayerForSketch(mapRef.current, displayedCanvasRef.current, shapes.map.mapInfo.coordinates, setResultBlob, displaySketchOnViz);
          loadMapSourceAndLayerForSketch(mapOnlySketchRef.current, displayedCanvasRef.current, shapes.map.mapInfo.coordinates, setResultBlob, displaySketchOnViz);

          // reset selected storm
          //setSelectedStorm('');

          // ensure layers are loaded before querying
          await sleep(200);

          // if a map, query the database and display results
          const selectedPointsQueryString = createQueryString(linearizedSegments);

          //
          //
          // ******* MAP PROMISE *******
          console.log("Setting up map promise.");
          mapPromise = queryStormTracks(selectedPointsQueryString, segments.at(0)?.precision ?? 80, getCurrentPenalties());
          mapsPromiseHandler = (results: StormTracksQueryResults) => {
            setStormQueryResults(results);

            // get list of all errors
            let errorList = getUniqueSortedArray(Array.from(results.values()).map(v => v[0].error));
            setResultsFilterErrorList(errorList);
            setResultsFilterErrorIndex(Math.floor(errorList.length / 3));
          }
          //
          //
          //

        } else {
          // handle querying line charts
          let linearizedSegmentsString = "";
          linearizedSegments.forEach(segment => linearizedSegmentsString += segment.map(value => "{" + value.x + "," + value.y + "}").join(','));

          //
          //
          // ******* LINES PROMISE *******
          console.log("Setting up lines promise.");
          linesPromise = queryBabyNames(linearizedSegmentsString, segments.at(0)?.precision ?? 80, getCurrentPenalties());
          linesPromiseHandler = (results: BabyNamesQueryResults) => {
            setBabyNameQueryResults(results);

            // get list of all errors
            let errorList = getUniqueSortedArray(Array.from(results.values()).map(v => v[0].error));
            setResultsFilterErrorList(errorList);
            setResultsFilterErrorIndex(Math.floor(errorList.length / 3));
          }
          //
          //
          //
        }
      }
    }

    //
    //
    // ******* PROMISE HANDLING *******
    // We will wait synchronously for this.
    // Interally, there are various parallel things happening, but we will wait for them all to complete.
    console.log("Handling promises in order.");
    await Promise.allSettled([anthropicSqlResultsPromise, mapPromise, linesPromise]).then((results) => {

      // Anthropic
      if (results[0].status === "fulfilled") {
        anthropicSqlResultsPromiseHandler(results[0].value);
      }
      console.log("Anthropic query status: " + results[0].status);

      // Maps
      if (results[1].status === "fulfilled") {
        mapsPromiseHandler(results[1].value);
      }
      console.log("Maps query status: " + results[1].status);

      // Lines
      if (results[2].status === "fulfilled") {
        linesPromiseHandler(results[2].value);
      }
      console.log("Lines query status: " + results[2].status);

      // hide the spinners
      setIsLoadingStormResults(false);
      setIsLoadingBabyNameResults(false);

    });
    //
    //
    //
  };

  const createQueryString = (linearizedSegments: Point2D[][]) => {
    // TODO; add support for multiple segments

    // convert sketch linearized segments to map cooordinates
    const coordinates = convertSketchToCoordinates(linearizedSegments[0]);
    console.log('coordinates');
    console.log(coordinates);

    // normalize coordinates
    const normalizedCoordinates = normalizeCoordinates(coordinates);
    console.log('normalizedCoordinates');
    console.log(normalizedCoordinates);

    const baseValue = 0.642;
    const dt = 0.005;

    // TODO: replace with actual normalized time
    // format: {time1,lat1,long1}, {time2,lat2,long2}, ...
    let newPattern = "{" + (baseValue) + "," + normalizedCoordinates[0].y + "," + normalizedCoordinates[0].x + "}";
    for (let i = 1; i < normalizedCoordinates.length; i++) {
      newPattern += ", {" + (baseValue + ((dt / (normalizedCoordinates.length - 1)) * i)) + "," + normalizedCoordinates[i].y + "," + normalizedCoordinates[i].x + "}";
    }
    return newPattern;
  }

  const convertSketchToCoordinates = (linearizeSketches: Point2D[]): mapboxgl.LngLat[] => {
    const overlay = document.querySelector("#" + hiddenSketchAreaId);
    const mapContainer = document.querySelector('#map');
    if (!mapRef.current || !overlay || !mapContainer) {
      console.error('unable to convert sketch to coordinates');
      return [];
    }

    // Get the bounds of the overlay in pixels relative to the map container

    // Get the bounding rectangle of the map container and the overlay
    const mapContainerRect = mapContainer.getBoundingClientRect();
    const overlayRect = overlay.getBoundingClientRect();
    // Calculate the position of the overlay relative to the map container
    const overlayPosition = {
      top: overlayRect.top - mapContainerRect.top,
      left: overlayRect.left - mapContainerRect.left,
      bottom: overlayRect.bottom - mapContainerRect.top,
      right: overlayRect.right - mapContainerRect.left
    };

    return linearizeSketches.map(point => {
      const map = throwIfNull(mapRef.current);
      const positionX = (overlayPosition.right - overlayPosition.left) * point.x + overlayPosition.left;
      const positionY = (overlayPosition.top - overlayPosition.bottom) * point.y + overlayPosition.bottom
      return map.unproject([positionX, positionY]);
    });
  }

  const normalizeCoordinates = (coordinates: mapboxgl.LngLat[]): Point2D[] => 
  {
      let minX = 0, maxX = 360;
      let minY = -90, maxY = 90;

      coordinates.forEach(coordinate =>
      {
          maxX = Math.max(maxX, coordinate.lng);
          maxY = Math.max(maxY, coordinate.lat);
      });

      return coordinates.map(coordinate => {
          // Normalize x and y to be in the range [0, 1]
          let normalizedX = (coordinate.lng - minX) / (maxX - minX);
          let normalizedY = (coordinate.lat - minY) / (maxY - minY);

          return new Point2D(normalizedX, normalizedY);
      });
  }

  return (
    <div className="container">
      <div className="header">
      <div>
        <img src={sketchQLLogo} alt="SketchQL Logo" className="logo" />
      </div>
        {
          !dialogOpen && 
          currentShapeIndex !== undefined && 
          shapeFilterLibrary.filter(item => item.map.isMapMode === isMapModeRef.current).length > 0 &&
          shapeFilterLibrary[currentShapeIndex].annotationParsingFilteredNames.length === 0 && 
          (isMapMode ? stormQueryResults?.size === 0 : babyNameQueryResults?.size === 0) && 
          (isMapMode ? !isLoadingStormResults : !isLoadingBabyNameResults) &&
          <div className="no-results-found-wrapper"><div className="no-results-found">No Results Found</div></div>
        }
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="mapmode" style={{ marginRight: "10px" }}>
              Pick a dataset:
              <select
                name="mapmode"
                value={isMapMode ? "map" : "non-map"}
                onChange={(e) => setIsMapModeValue(e.target.value === "map")}
                style={{ marginLeft: "10px", color: "inherit", fontSize: "inherit" }}
              >
                <option value="non-map">Baby trends</option>
                <option value="map">Storm paths</option>
              </select>
            </label>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '10px' }}>
            {/*shapeFilterLibrary.length > 0 && <label htmlFor="displaySketchOnViz" style={{marginRight: '20px'}}>
              Display Sketch on Viz
              <input checked={displaySketchOnViz} name="displaySketchOnViz" type="checkbox" onChange={(e) => setDisplaySketchOnViz(e.currentTarget.checked)} />
            </label>*/}
            {!dialogOpen && isMapMode && stormQueryResults && stormQueryResults.size > 0 && 
              <label htmlFor="storm-query-results-filter">
                Filter Results:
                <input type="range" className="results-filter-slider" name="storm-query-results-filter" min={0} max={resultsFilterErrorList.length } value={resultsFilterErrorIndex} onChange={(e) => setResultsFilterErrorIndex(parseFloat(e.currentTarget.value))} />
            </label>
            }
            {!dialogOpen && !isMapMode && babyNameQueryResults && babyNameQueryResults.size > 0 && 
              <label htmlFor="baby-name-results-filter">
                Filter Results:
                <input type="range" className="results-filter-slider" name="baby-name-results-filter" min={0} max={resultsFilterErrorList.length - 1} value={resultsFilterErrorIndex} onChange={(e) => setResultsFilterErrorIndex(parseFloat(e.currentTarget.value))} />
            </label>
            }
          </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{display: 'flex', flexDirection: 'column', marginRight: '20px'}}>
                {shapeFilterLibrary.length > 0 && <label htmlFor="displayAllLinesOnViz">
                  Display Clusters of Data
                  <input checked={displayAllLinesOnViz} name="displayAllLinesOnViz" type="checkbox" onChange={(e) => setDisplayAllLinesOnViz(e.currentTarget.checked)} />
                </label> }
                {isMapMode && displayAllLinesOnViz && 
                  <div className="dendrogram-slider-wrapper">
                    <label htmlFor="dendrogram">
                      Clusters LOD:
                      <input type="range" className="dendrogram-slider" name="dendrogram" min={0} max={dendrogramLevelCountsList.length - 1} value={dendrogramLevelIndex} onChange={(e) => setDendrogramLevelIndex(parseFloat(e.currentTarget.value))} />
                    </label>
                  </div>
                }
                {!isMapMode && displayAllLinesOnViz && 
                  <div className="dendrogram-slider-wrapper">
                    <label htmlFor="baby-names-dendrogram">
                      Level of Detail:
                      <input type="range" className="dendrogram-slider" name="baby-names-dendrogram" min={0} max={babyNamesDendrogramLevelCountsList.length - 1} value={babyNamesDendrogramLevelIndex} onChange={(e) => setBabyNamesDendrogramLevelIndex(parseFloat(e.currentTarget.value))} />
                    </label>
                  </div>
                }
              </div>
            </div>
        </div>
      </div>
      <div className="main-content">
        {/* duplicate canvas used to generate the sketch blob */}
        <div className="sketch-blob-canvas-wrapper" style={{display: 'none'}}>
          <ShapeFilterEditorWrapper
            sketchRef={sketchCanvasForBlobRef}
            shapeFilterIndex={currentShapeIndex}
            isInShapeLibrary={false}
            isAddingText={false}
            canvasWidth={getCanvasDimensions()?.width ?? canvasWidth}
            canvasHeight={getCanvasDimensions()?.height ?? canvasHeight}
            canvasId="sketch-blob-canvas-id"
            displayMeasureNameLines={false}
          />
        </div>
        {isMapMode ?
          <MapWrapper
            showOverlay={dialogOpen}
            sketchRef={sketchRef}
            handleClickMeasure={handleClickMeasure}
            handleClickAnnotationColor={handleClickAnnotationColor}
            handleClickTextButton={handleClickTextButton}
            handleClickEraserButton={handleClickEraserButton}
            handleClearButton={handleClearButton}
            handleCancelButton={handleCancelButton}
            handleSaveButton={handleSaveButton}
            handleSaveAsButton={handleSaveAsButton}
          /> :
          <LineChartWrapper
            showOverlay={dialogOpen}
            overlayChildren={
              <div className="shape-editor-wrapper">
                <ShapeFilterEditorWrapper
                    sketchRef={sketchRef}
                    shapeFilterIndex={currentShapeIndex}
                    isInShapeLibrary={false}
                    isAddingText={isAddingText}
                    canvasWidth={canvasWidth}
                    canvasHeight={canvasHeight}
                    isActiveCanvas={true}
                    canvasId={activeCanvasId}
                    includeControls={true}
                    handleClickMeasure={handleClickMeasure}
                    handleClickAnnotationColor={handleClickAnnotationColor}
                    handleClickTextButton={handleClickTextButton}
                    handleClickEraserButton={handleClickEraserButton}
                    displayMeasureNameLines={true}
                >
                <DialogFooter 
                    enableSaveAs={isEditingExistingSketch}
                    onRequestClear={handleClearButton}
                    onRequestCancel={handleCancelButton}
                    onRequestSave={handleSaveButton} 
                    onRequestSaveAs={handleSaveAsButton}
                />
                </ShapeFilterEditorWrapper>
              </div>
            }
          />
        }
        <div className="sidebar">
          <CustomTabs
            onClick={(tabNumber: number) => setCurrentTab(tabNumber)}
            currentTab={currentTab}
          />
          {currentTab === 0 && 
            <ShapeLibrary 
              setDialogOpen={setDialogOpen} 
              activeSketchRef={sketchRef}
              queryShapes={(index: number) => handleShapeQueries(index, shapeFilterLibrary[index], shapeFilterLibrary[index].sketchBlob)}
            />
          }
          {currentTab === 1 && <AdvancedMenu />}
        </div>
      </div>
    </div>
  );
};

export default Layout;
