import { DataTable } from '@tableau/embedding-api';
import OpenAI from 'openai';
import { Blob } from 'openai/_shims';
import { StormTracksQueryResults } from './DBApiService';
import { anthropic_map_prompt, anthropic_map_example } from '../AnthropicMapPrompt';
import { anthropic_baby_names_prompt } from '../AnthropicBabyNamesPrompt';
import { throwIfNull } from '../utils';
//import { sample_annotation_response_babynames, sample_annotation_response_maps } from '../SampleAnnotationResponse';

/**
 * Analyze the image generated by the Sketch component with the provided user message.
 * @param {Object} userMessage - An object containing the `prompt` and `sketchRef`.
 * @param {string} userMessage.prompt - The prompt to analyze.
 * @param {React.RefObject<SketchRef>} userMessage.sketchRef - The reference to the Sketch component.
 */
export const analyzeOnlySketchWithPrompt = async (
    userPrompt: string,
    sketchBlob: Blob,
    attributes: any,
    isMapMode: boolean,
): Promise<OpenAI.Chat.ChatCompletion> => {
    let prompt = userPrompt;
    if (isMapMode) {
        prompt += ". The colors represent the colors in these attributes: " + JSON.stringify(attributes) + " Ignore other colors. " + 
         "Assume the X axis is longitude and the Y axis is latitude. " + 
         "This sketch is a query that describes a hurricane storm path, in the direction of the arrow. ";
    } else {
        prompt += ". The Y axis represents these attributes: " + JSON.stringify(attributes) + " The X axis represents time. Ignore other colors. "; 
    } 

    const formData = new FormData();
    formData.append('sketch', sketchBlob, 'sketch.png'); // sketch blob
    formData.append('prompt', prompt);

    console.log(formData);

    const response = await fetch('/process-sketch-only', {
        method: 'POST',
        body: formData
    });
    return response.json();
};


function extractSQL(text: string): string {
    const regex = /```sql\s*([\s\S]*?)\s*```/;
    const match = text.match(regex);
    return match ? match[1].trim() : text.trim();
}

/**
 * Analyze the annotations using Anthropic
 * @param {string} sketchBlob- The sketch image to analyze.
 * @param {boolean} isMapMode - Whether this is a map or line chart image
 */
export const analyzeAnnotationsWithAnthropic = async (
    sketchBlob: Blob,
    isMapMode: boolean,
): Promise<string> => {
    return new Promise((resolve, reject) => {
        /*setTimeout(() => {
            resolve(isMapMode ? sample_annotation_response_maps : sample_annotation_response_babynames);
        }, 20000);*/
        console.log('analyzeAnnotationsWithAnthropic - sending request to anthropic');
        let prompt = "";
        let example = "";
        if (isMapMode) {
            prompt = anthropic_map_prompt;
            example = anthropic_map_example;
        } else {
            prompt = anthropic_baby_names_prompt;
        }

        const formData = new FormData();
        formData.append('sketch', sketchBlob, 'sketch.png'); // sketch blob
        formData.append('prompt', prompt);
        formData.append('example', example);

        console.log(formData);

        fetch('/process-sketch-only', {
            method: 'POST',
            body: formData
        }).then(response => response.json())
        .then((response: OpenAI.Chat.ChatCompletion) => {
            if (response.choices.length > 0 && response.choices[0].message.content) {

                // parse the sql out of the response
                const sql : string | null = extractSQL(response.choices[0].message.content);
                if(sql == null)
                {
                    console.error('unable to parse /process-sketch-and-response response');
                    console.log(response);
                    reject();
                }

                resolve(throwIfNull(sql));
            } else {
                console.error('unable to parse /process-sketch-and-response response');
                console.log(response);
                reject();
            }
        })
        .catch((error) => {
            console.error('Error analyzing image:', error);
        });
    });
};

/**
 * Analyze the image generated by the Sketch component along with the query results, with the provided user message.
 * @param {Object} userMessage - An object containing the `prompt` and `sketchRef`.
 * @param {string} userMessage.prompt - The prompt to analyze.
 * @param {React.RefObject<SketchRef>} userMessage.sketchRef - The reference to the Sketch component.
 * @returns {Promise<OpenAI.Chat.ChatCompletion>} The API response.
 */
export const analyzeSketchAndResponseWithPrompt = async (
    userPrompt: string,
    sketchBlob: Blob,
    resultBlob: Blob,
    attributes: any,
    responseData: {
        isMapMode: boolean,
        data: DataTable | StormTracksQueryResults | {},
    }
): Promise<OpenAI.Chat.ChatCompletion> => {
    let prompt = userPrompt;
    if (responseData.isMapMode) {
        prompt += ". The sketch is the first image. The colors represent the colors in these attributes: " + JSON.stringify(attributes) + " Ignore other colors. " + 
        "Assume the X axis is longitude and the Y axis is latitude. " + 
        "This sketch is a query that describes a hurricane storm path, in the direction of the arrow. " + 
        "It was used to query storm data using the map data described in the attributes, which resulted in the second image and depicts these storms and corresponding errors: " + JSON.stringify(responseData.data) +
        "The error indicates how close of a match the storm is to the sketch, with a lower error indicating a closer match. " + 
        "When describing the sketch, please do not use the words `error` or reference the image order as part of that response. Please use the storm names in the results.";
    } else {
        prompt += ". The sketch is the first image. Ignore the second image. The Y axis represents these attributes: " + JSON.stringify(attributes) + " The X axis represents time. Ignore other colors. " + 
        "This sketch was used to query Tableau which resulted in this Tableau Viz DataTable: " + JSON.stringify(responseData.data) + 
        "When describing the sketch, please do not encapsulate the DataTable as part of that response. The DataTable should only be used to describe the results from the sketch." + 
        "When describing the DataTable results, refer to it as 'Tableau Visualization'";
    } 

    const formData = new FormData();
    formData.append('sketch', sketchBlob, 'sketch.png'); // sketch blob
    formData.append('result', resultBlob, 'result.png'); // viz image blob
    formData.append('prompt', prompt);

    console.log(formData);

    const response = await fetch('/process-sketch-and-response', {
        method: 'POST',
        body: formData
    });

    return response.json();
};