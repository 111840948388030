import React from "react";
import "./CustomDialog.css"
//import { ReactComponent as ArrowDown } from "../arrow triangle down.svg"

type CustomDialogProps = {
  title?: string;
  open: boolean;
  enableSaveAs: boolean;
  onRequestClear: () => void;
  onRequestCancel: () => void;
  onRequestSave: () => void;
  onRequestSaveAs: () => void;
  children: React.ReactNode;
};

const CustomDialog: React.FC<CustomDialogProps> = ({
  title,
  open,
  enableSaveAs,
  onRequestClear,
  onRequestCancel,
  onRequestSave,
  onRequestSaveAs,
  children,
}) => {
  return (
    <>
      {open &&
        <div
          className="dialog-floater"
        >
          <dialog className="dialog-wrapper"
            onClose={onRequestClear}>
            {title && <div className="dialog-title">{title}</div>}
            <div className="dialog-content-wrapper">
              {children}
            </div>
          </dialog>
        </div>
      }
    </>
  );
};

export const DialogFooter: React.FC<Pick<CustomDialogProps, 'enableSaveAs' | 'onRequestClear' | 'onRequestCancel' | 'onRequestSave' | 'onRequestSaveAs'>> = ({
  enableSaveAs,
  onRequestClear,
  onRequestCancel,
  onRequestSave,
  onRequestSaveAs,
}) => {
  //const [showSaveOptions, setShowSaveOptions] = useState<boolean>(false);

  return <div className="dialog-footer">
    <div className="dialog-footer-right">
      <button
        className="light-button"
        onClick={onRequestCancel}
      >
        Cancel
      </button>

      <button
        className="light-button"
        onClick={onRequestClear}
      >
        Clear
      </button>
      <button className="query-text-button dark-button"
        onClick={onRequestSave}
      >
        Query
      </button>
      {/*<div className="query-dropdown-wrapper">
        <button className="dark-button query-button-svg-wrapper" onClick={() => setShowSaveOptions(!showSaveOptions)}>
          <ArrowDown />
        </button>
        <div className={`query-button-options-list ${showSaveOptions ? '' : 'hidden'}`}>
          <ul>
            <li><button className="save-options-button" onClick={onRequestSaveAs}>Save As and Query</button></li>
            <li><button className="save-options-button" onClick={onRequestSave}>Save and Query</button></li>
          </ul>
        </div>
      </div>*/}
    </div>
  </div>
};

export default CustomDialog;

